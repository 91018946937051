import React from 'react'
import Select from "../../../../../components/Select";
import UserStore from "../../../../../user/user-store";
import SalesStore from "../../../../sales/sales-store";
import {Label} from "react-bulma-components/lib/components/form";
import T from '../../../../../components/i18n'
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bulma-components";
import AppStore from "../../../../../app-store";
import AddMakePayment from "../../../terminal/modal/add-make-payment";
import InfoWrapper from "../../../../../components/info-wrapper";

const listWithoutIdsAndIgnoreId = (list, ids, ignoreId) => {
	return list.reduce((acc, cur) => {
		if(!ids.includes(cur.id) || ignoreId === cur.id) {
			acc.push(cur);
		}
		return acc
	}, [])
}

const BindingChannelPayment = ({value, setValue}) => {
	const makePaymentList = UserStore.getMakePaymentList()
	const channelList = SalesStore.getChannelsList()

	const handleAddItem = () => {
		const newId = crypto.randomUUID();
		setValue(prev => ({
			...prev,
			[newId]: { channel: null, payment: null }
		}));
	};

	const handleRemoveItem = (id) => {
		setValue(prev => {
			const updated = { ...prev };
			delete updated[id];
			return updated;
		});
	};

	const handleChangeSelect = ({value, name}, id) => {
			setValue(prev => ({
				...prev,
				[id]: { ...prev[id], [name]: value }
			}))
	}

	const valuesList = Object.values(value)
	const activeIds = valuesList.reduce((acc, cur) => {
		if(cur.payment?.value) {
			acc.activePaymentIds.push(cur.payment.value)
		}
		if(cur.channel?.value) {
			acc.activeChannelIds.push(cur.channel.value)
		}

		return acc
	}, {
		activePaymentIds: [],
		activeChannelIds: []
	})

	return (
		<div style={{marginBottom: 10, gap: 5}} className="display-flex-column">
			<div className="display-flex-row-gap" style={{alignItems: "center", marginBottom: 10}}>
				<InfoWrapper info='make-to-payment-priority-tip'>
					<Label size='large' style={{margin: 0}}>{T('sales-channel-to-payment')}</Label>
				</InfoWrapper>
				<Button className='button-link-icon' style={{color: "#46c37b"}} onClick={handleAddItem}>
					<FontAwesomeIcon icon='plus-circle'/>
				</Button>
			</div>
				{Object.keys(value).map((item) => (
					<div className='display-flex-row' key={item}>
						<Select
							name='channel'
							onChange={(res) => handleChangeSelect(res, item)}
							list={listWithoutIdsAndIgnoreId(channelList, activeIds.activeChannelIds, value[item].channel?.value)}
							styleWrapper={{width: '100%'}}
							disabledAutoSelect
							placeholder="sale-channel"
							value={value[item].channel}

						/>
						<Select
							name='payment'
							onChange={(res) => handleChangeSelect(res, item)}
							list={makePaymentList}
							styleWrapper={{width: '100%'}}
							disabledAutoSelect
							placeholder="make-the-payment"
							value={value[item].payment}
							addItem={(name) => AppStore.openModal(<AddMakePayment name={name}/>)}
							addItemModal={() => AppStore.openModal(<AddMakePayment/>)}
							isCreate
						/>
						{(Object.keys(value).length > 1 || (Object.keys(value).length === 1 && value[item].payment?.value && value[item].channel?.value)) && <Button className='button-link-icon'
										 onClick={() => handleRemoveItem(item)}
										 style={{color: "#f14668"}}>
							<FontAwesomeIcon icon='minus-circle'/>
						</Button>}
					</div>
				))}
		</div>
	);
};

export default BindingChannelPayment;