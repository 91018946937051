import React, {useEffect, useState} from 'react'
import {Box, Button, Heading, Icon} from "react-bulma-components";
import T from	'../../../components/i18n'
import {
	Control,
	Input,
	Label
} from "react-bulma-components/lib/components/form";
import NumberInput from "../../../components/number-input/number-input";
import UserStore from "../../../user/user-store";
import SubmitButton from "../../../components/submit-button";
import ErrorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";
import UserActions from "../../../user/user-actions";
import {errorHandler} from "../../../tools/error-handler";
const list = [
	{
		label: 'expired-date',
		values: [
			{
				label: 'expires-exp',
				type: 'number',
				tip: '_days',
				name: 'expires-exp',
			}
		]
	}
]

const AdditionalSettings = ({onClose}) => {
	const globalAdditionalSetting = UserStore.getGlobalSetting('globalAdditionalSettings') || {};
	const [values, setValues] = useState({
		"expires-exp": globalAdditionalSetting['expires-exp'] || 3,
	})

	const {handleAutoRemoveError,setErrorMessage,ErrorMessageBlock,errorMessage} = ErrorMessageControlHook()
	const [waiting, setWaiting] = useState(false)

	const handleChangeInput = ({target: {name, value}}) => {
		setValues({...values, [name]: value})
	}

	const handleSubmit = () => {
		if(!values['expires-exp']) {
			handleAutoRemoveError('not all fields required')
		} else {
			setWaiting(true)
			UserStore.setGlobalSetting(values, 'globalAdditionalSettings')
		}
	}

	useEffect(() => {
		const updateSetting = UserActions.updateSettings.completed.listen(() => {
			onClose()
		})
		const updateSettingFail = UserActions.updateSettings.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})
		return () => {
			updateSettingFail()
			updateSetting()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading>{T('additional-settings-global-account')}</Heading>
				{list.map(listItem => (
					<div key={listItem.label}>
						<Label size='large'>{T(listItem.label)}</Label>
						{listItem.values.map(valueItem => (
							<div key={`${valueItem.label}-item`}>
								<Label>{T(valueItem.label)}</Label>
								<Control iconRight={!!valueItem.tip}>
									{valueItem.type === 'number' ? (
										<NumberInput name={valueItem.name} value={values[valueItem.name]} onChange={handleChangeInput}/>
									) : (
										<Input name={valueItem.name}/>
									)}
									{valueItem.tip && <Icon align='right'>
										<span>
											{T(valueItem.tip)}
										</span>
									</Icon>}
								</Control>
							</div>
						))}
					</div>
				))}
				<div className='display-flex-row margin-top-10' style={{justifyContent: 'flex-end'}}>
					<Button onClick={onClose} size='large'>{T('close')}</Button>
					<SubmitButton text='btn-save' color='success' submit={handleSubmit} fullwidth={false} waiting={waiting}/>
				</div>
			</Box>
		</>
	);
};

export default AdditionalSettings;