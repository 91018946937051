import React, { useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import UserStore from "../../user/user-store";
import T from '../i18n'
import SubmitButton from "../submit-button";
import AppStore from "../../app-store";
import {createMobileAppLink} from "../../tools/create-mobile-app-link";
const DocumentModal = ({printDocument, format, heading, onClose}) => {
	const [waiting, setWaiting] = useState(false)
	const handlePrint = () => {
		printDocument.head.doc = format;
		if(AppStore.isMobileApp()) {
			printDocument.render_to_get = true
		}
		setWaiting(true)
		fetch('/bprint/report', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				"X-Auth-Token": encodeURIComponent(UserStore.getToken())
			},
			body: JSON.stringify(printDocument)
		}).then(async res => {
			if(AppStore.isMobileApp()) {
				return res.json()
			}
			return {
			filename: `product-report.${format}`,
			blob: await res.blob()
		}})
			.then(resObj => {
				if(AppStore.isMobileApp()) {
					createMobileAppLink(`/bprint/report?key=${resObj.key}&token=${encodeURIComponent(UserStore.getToken())}`)
				} else {
					const newBlob = new Blob([resObj.blob], { type: 'application/pdf' });
					const objUrl = window.URL.createObjectURL(newBlob);
					let link = window.document.createElement('a');
					link.href = objUrl;
					if(format === 'pdf' && !AppStore.isMobileApp()) {
						link.target = '_blank';
					} else {
						link.download = resObj.filename;
					}

					link.click();
					setTimeout(() => { window.URL.revokeObjectURL(objUrl); }, 250);
				}

				onClose()
		})
	}

	return (
		<Box>
			<Heading>{heading ? T(heading) : T('documents')}</Heading>
			<div className='display-flex-row' style={{justifyContent: 'flex-end'}}>
				<SubmitButton submit={handlePrint} waiting={waiting} color='info' text='download-title' fullwidth={false}/>
				<Button size='large' onClick={onClose}>{T('close')}</Button>
			</div>
		</Box>
	);
};

export default DocumentModal;