import React, { useState } from "react";
import RadioButtonsListControl from "../../../../components/radio-buttons-list/radio-button-list-control";
import SelectComponent from "../../../../tools/select-component";
import ProductsStore from "../../../products/products-store";
import {
  Control,
  Field,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import s from "./styles.module.scss";
import { Box, Button, Heading } from "react-bulma-components";
import { getFilteredListWithoutActive } from "./utils";
import ErrorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import SelectFixPercentage from "../../../../components/select-fix-percentage";
import SalesStore from "../../../sales/sales-store";
import AppStore from "../../../../app-store";
import AddChannelModal from "../../add-channel-modal";

const AddAccrualSales = ({
  defaultRadio,
  deleteGeneral,
  activeBrand,
  activeCategory,
  activeItem,
  disabledRadio,
  onClose,
  onSubmit,
}) => {
  let DATA_RADIO = [
    {
      title: "general",
      value: "general",
    },
    {
      title: "product-category",
      value: "category",
    },
    {
      title: "product-brand",
      value: "brand",
    },
    {
      title: "channel",
      value: 'channel',
    }
  ];

  const {
    ErrorMessageBlock,
    handleAutoRemoveError,
    errorMessage,
    setErrorMessage,
  } = ErrorMessageControlHook();
  const [radioValue, setRadioValue] = useState(
    (deleteGeneral && "category") || defaultRadio || "general"
  );
  const defaultSelectValue = {
    category: false,
    brand: false,
    channel: false,
  };
  const defaultInputValue = {
    category: "",
    brand: "",
    general: "",
    channel: "",
  };

  let defaultType = '%'

  if (defaultRadio && defaultRadio !== "general" && activeItem) {
    defaultSelectValue[defaultRadio] = activeItem.data;
  }

  if (defaultRadio && activeItem) {
    defaultInputValue[defaultRadio] = activeItem.value;
  }

  if(activeItem) {
    defaultType = activeItem.type
  }

  const [selectValue, setSelectValue] = useState(defaultSelectValue);

  if (deleteGeneral) {
    DATA_RADIO = DATA_RADIO.filter((item) => item.value !== "general");
  }

  const [inputValue, setInputValue] = useState(defaultInputValue);
  const [type, setType] = useState(defaultType)

  const handleChange = ({ value }) => {
    setRadioValue(value);
    setSelectValue({
      category: false,
      brand: false,
      channel: false,
    });
    setType('%')
  };

  const handleChangeType = ({target: {value}}) => {
    setType(value)
  }

  const handleChangeSelect = (selectedValue, action) => {
    if (!selectedValue) {
      setSelectValue((prev) => ({
        ...prev,
        [action.name]: false,
      }));
    } else {
      setSelectValue((prev) => ({
        ...prev,
        [action.name]: selectedValue.filter((item) => item?.value !== 0),
      }));
    }
  };

  const handleChangeInput = ({ target: { value, name } }) => {
    setInputValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
    if (radioValue === "general" && inputValue.general) {
      onSubmit && onSubmit(radioValue, {
        value: inputValue.general,
        type,
      });
      onClose && onClose();
    } else if (
      radioValue !== "general" &&
      inputValue[radioValue] &&
      selectValue[radioValue]
    ) {
      onSubmit &&
        onSubmit(radioValue, {
          data: selectValue[radioValue],
          value: inputValue[radioValue],
          type
        });
      onClose && onClose();
    } else {
      handleAutoRemoveError("not all fields required");
    }
  };

  const brandList = getFilteredListWithoutActive(
    ProductsStore.getBrandsList(),
    activeBrand,
    activeItem
  );

  const categoryList = getFilteredListWithoutActive(
    ProductsStore.getFormattingCategoryList(),
    activeCategory,
    activeItem
  );

  const channelList = getFilteredListWithoutActive(
    SalesStore.getChannelsList(),
    activeCategory,
    activeItem
  );

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <div className={s.wrapper}>
        <Heading className="margin-bottom-0">{T('add-accrual-of-sales')}</Heading>
        <RadioButtonsListControl
          list={DATA_RADIO}
          valueRadio={radioValue}
          name="accrual"
          onChange={handleChange}
          disabledRadio={disabledRadio}
        />
        {radioValue === "category" && !!categoryList.length && (
          <div className={s.wrapper_select_input}>
            <div className={s.wrapper_field}>
              <SelectComponent
                className="full-size-select"
                name="category"
                label="product-category"
                placeholder="select-category"
                onChange={handleChangeSelect}
                value={selectValue.category}
                isMulti
                list={() => categoryList}
              />
            </div>
            <Field className={s.wrapper_field}>
              <Label>{T('accrual-of-sales')}</Label>
              <Control className={s.wrapper_input}>
                <Input
                  style={{ height: "38px" }}
                  className="disabled-arrow-input"
                  type="number"
                  name="category"
                  value={inputValue.category}
                  onChange={handleChangeInput}
                />
                <SelectFixPercentage style={{width:52, borderRadius: '10px'}} value={type} onChange={handleChangeType}/>
              </Control>
            </Field>
          </div>
        )}
        {radioValue === "category" && !categoryList.length && (
          <Box>{T('available-categories-accrual')}</Box>
        )}
        {radioValue === "brand" && !!brandList.length && (
          <div className={s.wrapper_select_input}>
            <div className={s.wrapper_field}>
              <SelectComponent
                className="full-size-select"
                name="brand"
                label="product-brand"
                placeholder="select-brand"
                onChange={handleChangeSelect}
                value={selectValue.brand}
                isMulti
                list={() => brandList}
              />
            </div>
            <Field className={s.wrapper_field}>
              <Label>{T('accrual-of-sales')}</Label>
              <Control className={s.wrapper_input}>
                <Input
                  style={{ height: "38px" }}
                  className="disabled-arrow-input"
                  type="number"
                  name="brand"
                  value={inputValue.brand}
                  onChange={handleChangeInput}
                />
                <SelectFixPercentage style={{width:52, borderRadius: '10px'}} value={type} onChange={handleChangeType}/>
              </Control>
            </Field>
          </div>
        )}
        {radioValue === "brand" && !brandList.length && (
          <Box>{T('available-brands-accrual')}</Box>
        )}

        {radioValue === "general" && (
          <Field className={s.wrapper_field}>
            <Label>{T('accrual-of-sales')}</Label>
            <Control className={s.wrapper_input}>
              <Input
                style={{ height: "38px" }}
                className="disabled-arrow-input"
                type="number"
                name="general"
                value={inputValue.general}
                onChange={handleChangeInput}
              />
              <SelectFixPercentage style={{width:52, borderRadius: '10px'}} value={type} onChange={handleChangeType}/>
            </Control>
          </Field>
        )}
        {radioValue === "channel" && !!channelList.length && (
          <div className={s.wrapper_select_input}>
            <div className={s.wrapper_field}>
              <SelectComponent
                className="full-size-select"
                name="channel"
                label="channel"
                placeholder="sale-channel"
                onChange={handleChangeSelect}
                value={selectValue.channel}
                addItem={({name}) => AppStore.openModal(<AddChannelModal name={name} />)}
                addItemModal={() => AppStore.openModal(<AddChannelModal/>)}
                isMulti
                creatable={true}
                list={() => channelList}
              />
            </div>
            <Field className={s.wrapper_field}>
              <Label>{T('accrual-of-sales')}</Label>
              <Control className={s.wrapper_input}>
                <Input
                  style={{ height: "38px" }}
                  className="disabled-arrow-input"
                  type="number"
                  name="channel"
                  value={inputValue.channel}
                  onChange={handleChangeInput}
                />
                <SelectFixPercentage style={{width:52, borderRadius: '10px'}} value={type} onChange={handleChangeType}/>
              </Control>
            </Field>
          </div>
        )}
        {radioValue === "channel" && !channelList.length && (
          <Box>{T('available-channels-accrual')}</Box>
        )}
        <div
          className="display-flex-row"
          style={{ justifyContent: "flex-end" }}
        >
          <Button color="danger" onClick={onClose}>
            {T("undo")}
          </Button>
          <Button color="success" onClick={handleSubmit}>
            {T("add")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddAccrualSales;
