import React, {useState} from 'react'
import IntegrationControlHook from "../common/integration-control.hook";
import IntegrationListenerHook from "../common/integration-listener.hook";
import {handleDeleteIntegration, isMinValue, parseObj} from "../utils";
import WarehouseStore from "../../../../warehouse/warehouse-store";
import UserActions from "../../../../../user/user-actions";
import ErrorMessageBlock
	from "../../../../../components/error-message-block/error-message-block";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../../components/i18n";
import InputsGroupField from "../common/inputs-group-field";
import SelectComponent from "../../../../../tools/select-component";
import WarehouseActions from "../../../../warehouse/warehouse-actions";
import ActionsModal from "../common/actions-modal";
import {confirmDelete} from "../../../../../tools/confirm-delete";
import ModalItsOK from "../../../../../components/modal-its-ok";
import IntegrationCheckboxGroup from "../common/integration-checkbox-group";
import SalesStore from "../../../../sales/sales-store";
import AppStore from "../../../../../app-store";
import AddChannelModal from "../../../add-channel-modal";
import ChannelBlock
	from "../../../../../components/channel-block/channel-block";
import InfoWrapper from "../../../../../components/info-wrapper";
import AdditionalButtonDownload
	from "../../components/additional-button-download";
import VirtualWarehouse from "../../components/virtual-warehouse";
import SyncIntegration from "../common/sync-integration";
import Select from "../../../../../components/Select";
import UserStore from "../../../../../user/user-store";

const DATA_INPUT = [
	{
		name: "api_key",
		placeholder: "login-placeholer"
	},
	{
		name: "api_pass",
		placeholder: "password-placeholder"
	},
]

const Rozetka = ({data, wooID ,onClose}) => {
	const {api_key, api_pass, ident, id, is_active} = data

	let identParse = typeof ident === 'string' ? parseObj(ident) : ident

	const channel = SalesStore.foundChannelsByIntegrationId(id)

	const {
		handleAutoRemoveError,
		setErrorMessage,
		errorMessage,
		inputValues,
		handleChangeInputValues,
		checkboxValue,
		handleChangeCheckbox,
		selectValue,
		handleChangeSelect
	} = IntegrationControlHook({
		inputDefaultValue: {
			api_key: api_key || null,
			api_pass: api_pass || null,
			url: identParse?.url || ''
		},
		checkboxDefaultValue: {
			update_stock: identParse?.update_stock || false,
			update_price: identParse?.update_price || false,
			update_orders: identParse?.update_orders || false,
			is_description: identParse?.is_description || false,
			is_price: identParse?.is_price || false,
			dont_orders: identParse?.dont_orders || false,
			vmid: identParse?.vmid || false,
			disable_reserv: identParse?.disable_reserv || false,
		},
		selectDefaultValue: {
			mid: {value: identParse?.mid || 0},
			channel_id: channel ? {value: channel || 0} : false,
			vmids: identParse?.vmids?.length ? identParse.vmids.map(item => ({value: item, label: WarehouseStore.getWarehouseName(item)})) : null,
			// sync_rules: identParse?.sync_rules ? identParse.sync_rules : ['s','n']
		}
	})
	const [custom_price, setCustom_price] = useState(identParse?.custom_price ? {value: identParse.custom_price} : {value: 0})
	const [waiting, setWaiting] = useState(false)
	const {submitOK, additionalMessage, newData} = IntegrationListenerHook({handleError: handleAutoRemoveError, setWaiting})

	const handleError = () => {
		let errorMessage;

		if(isMinValue(inputValues, ['api_key'], 2)?.api_key < 2 && !inputValues?.api_key){
			errorMessage = 'wrong-key'
		}

		// if(!selectValue.sync_rules) {
		// 	errorMessage = 'principle-sync-error'
		// }

		if(errorMessage) {
			handleAutoRemoveError(errorMessage)
			return false
		}

		return  true
	}

	const handleSubmit = ({deactivate}) => {
		const identData = {
			url: inputValues.url,
			mid: selectValue.mid.value || WarehouseStore.getDefaultWarehouse(),
			is_load: 0,
			update_stock: !!checkboxValue.update_stock,
			update_price: !!checkboxValue.update_price,
			update_orders: !!checkboxValue.update_orders,
			is_description: !!checkboxValue.is_description,
			is_price: !!checkboxValue.is_price,
			dont_orders: !!checkboxValue.dont_orders,
			channel_id: selectValue.channel_id?.value ? selectValue.channel_id.value : false,
			vmid: checkboxValue?.vmid || false,
			disable_reserv: checkboxValue?.disable_reserv || false,
			// sync_rules: selectValue.sync_rules
		}

		if(custom_price.value) {
			identData.custom_price = custom_price.value
		} else {
			identData.custom_price = null
		}

		if(checkboxValue.vmid) {
			identData.vmid = true;
			if(selectValue?.vmids?.length) {
				identData.vmids = selectValue.vmids.map(item => item.value)
			} else {
				handleAutoRemoveError('fill-in-the-virtual-lineups')
				return
			}
		} else {
			identData.vmids = null
		}

		const data = {
			"integration_id": wooID,
			"api_key": inputValues.api_key,
			"api_pass": inputValues?.api_pass,
			"ident": JSON.stringify(identData),
			is_active: 1
		}

		if(id) data.id = id
		if(deactivate) data.is_active = 0

		if(handleError()) {
			setWaiting(true)
			UserActions.updateIntegrations(data)
		}
	}

	const isHaveChannel = SalesStore.foundChannelsByIntegrationId(data?.id)

	const handleChangeChannelModal = () => {
		const getChannelById = SalesStore.getChannelById(isHaveChannel)
		AppStore.openModal(<AddChannelModal {...getChannelById}/>)
	}

	const handleChangeSelectMid = (value, meta) => {
		if(checkboxValue.vmid) {
			const vmidsSelect = selectValue?.vmids?.filter(item => item.value !== value.value)
			if(vmidsSelect?.length) {
				handleChangeSelect(vmidsSelect, {name: 'vmids'})
			} else {
				handleChangeSelect(null, {name: 'vmids'})
			}
		}

		handleChangeSelect(value, meta)
	}
  const getCustomPrices = UserStore.getCustomPriceForList();
	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			{!submitOK && <Box>

				<div className='display-flex-row-gap align-start'>
					<Heading size={3}>
						{T('setting-integration', [T(`integration-${wooID}`)])}
					</Heading>
					{isHaveChannel && <ChannelBlock channel_id={isHaveChannel}
																					onClick={handleChangeChannelModal}/>}
				</div>
				<InputsGroupField inputValues={inputValues} dataInput={DATA_INPUT}
													onChange={handleChangeInputValues}/>
				<SelectComponent
					name="mid"
					onChange={handleChangeSelectMid}
					value={selectValue.mid}
					readOnly={!!id}
					list={WarehouseStore.getWarehouseList}
					load={WarehouseActions.load}
					creatable={false}
				/>
				<VirtualWarehouse withoutMidId={selectValue.mid.value} value={selectValue.vmids} onChange={handleChangeSelect} vmidValue={checkboxValue.vmid} onChangeCheckbox={handleChangeCheckbox}/>
				<SelectComponent
					name="channel_id"
					label={<InfoWrapper info='not-select-channel-integration-tip'>{T('sale-channel')}</InfoWrapper>}
					placeholder='channel'
					onChange={handleChangeSelect}
					value={selectValue.channel_id}
					list={() => SalesStore.getChannelListWithoutIntegration(channel)}
					addItem={({name}) => AppStore.openModal(<AddChannelModal name={name} />)}
					addItemModal={() => AppStore.openModal(<AddChannelModal/>)}
					creatable
				/>
				{getCustomPrices && <Select
						empty="basic-price-label"
						label='what-price-to-upload'
						name='custom_price'
						list={getCustomPrices}
						value={custom_price}
						onChange={({value}) => setCustom_price(value)}
						styleWrapper={{marginBottom: '0.75rem'}}
				/>}
				<SyncIntegration data={selectValue.sync_rules} onChange={handleChangeSelect}/>
				<IntegrationCheckboxGroup onChange={handleChangeCheckbox}
																	values={checkboxValue}/>
				<ActionsModal
					onSubmit={handleSubmit}
					onDelete={() => confirmDelete(() => handleDeleteIntegration(id))}
					onClose={onClose}
					isEdit={!!id}
					isActive={is_active}
					waiting={waiting}/>
			</Box>}
			{submitOK && <ModalItsOK onClose={onClose} title={submitOK} additionalMessage={additionalMessage} children={!data.id && <span onClick={onClose}><AdditionalButtonDownload data={newData}/></span>}/>}
		</>
	);
};

export default Rozetka;
