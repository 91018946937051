import yajax from "yajax";
// import axios from 'axios';

// import moment from "moment";
import { createStore } from "tools/reflux-tools";
import UserStore from "user/user-store";
import Actions from "app-actions";
import T from "components/i18n";
import {confirmDelete} from "./tools/confirm-delete";

const CONTACT_DATA = {
  RU: {
    hugeprofit: {
      phone: "38 (073) 584-73-73",
      messanger: "",
      telegram: "https://t.me/hugeprofit1",
      telegram_page: "https://t.me/huge_profit",
      address: "Украина. Харьков. ул.Вологодская 23а. 61033",
      // company: 'ООО "ХьючПрофит", код ЕГРПОУ 44330291',
      company: 'ФОП Піддубний О.Ю., код ЄДРПОУ 3195405297',
    },
    navkolo: {
      phone: "0 800 331 891",
      messanger: "",
      address: "",
      company: "",
    },
    modeler: {
      phone: "",
      telegram: "https://t.me/GoodProfitCrmSupportBot",
      telegram_page: "https://t.me/gp_crm",
      viber: "",
      facebook: "",
      messanger: "",
      address: "ИНН 616612404282",
      company: "ИП Шевкунов Александр Сергеевич",
    },
  },
  EN: {
    hugeprofit: {
      phone: "38 (073) 584-73-73",
      messanger: "",
      telegram: "https://t.me/hugeprofit1",
      telegram_page: "https://t.me/huge_profit",
      address: "Ukraine. Kharkiv. Vologodska Street 23а. 61033",
      // company: 'LLC "HugeProfit", legal entity identifier 44330291',
      company: 'FOP Piddubnyi O.Y., legal entity identifier 3195405297',
    },
    navkolo: {
      phone: "0 800 331 891",
      messanger: "",
      address: "",
      company: "",
    },
    modeler: {
      phone: "",
      telegram: "https://t.me/GoodProfitCrmSupportBot",
      telegram_page: "https://t.me/gp_crm",
      viber: "",
      facebook: "",
      messanger: "",
      address: "919 NORTH MARKET STREET. SUITE 950. WILMINGTON, DE 19801, US",
      company: "SHEVKUNOV LLC",
    },
  },
  UA: {
    hugeprofit: {
      phone: "38 (073) 584-73-73",
      telegram: "https://t.me/hugeprofit1",
      telegram_page: "https://t.me/huge_profit",
      viber: "viber://chat?number=+380735847373",
      facebook: "https://www.facebook.com/H-Profit-100740618389312",
      messanger: "+380735847373",
      address: "Україна. Харків. вул.Вологодська 23а. 61033",
      // company: 'ТОВ "ХьючПрофіт", код ЄДРПОУ 44330291',
      company: 'ФОП Піддубний О.Ю., код ЄДРПОУ 3195405297',
    },
    modeler: {
      phone: "",
      telegram: "https://t.me/GoodProfitCrmSupportBot",
      telegram_page: "https://t.me/gp_crm",
      viber: "",
      facebook: "",
      messanger: "",
      address: "919 NORTH MARKET STREET. SUITE 950. WILMINGTON, DE 19801, US",
      company: "SHEVKUNOV LLC",
    },
    navkolo: {
      phone: "0 800 331 891",
      messanger: "",
      address: "",
      company: "",
    },
  },
};

const WHITELABLE = process.env.REACT_APP_WHITELABLE;

const WHITELABLES_MODULES = {
  navkolo: {
    subscribe: false,
    "video-help": false,
    cleaner: false,
    ga: false,
    tagmanager: "GTM-NP9G9M3",
    "facebook-app": "521643960519280",
    domain: "https://crm.navkolo.one",
    contacts: true,
    cashier: false,
    production: true,
    "sidebar-link": false,
    partners: false,
    "cashier-panel": false
  },
  hugeprofit: {
    tagmanager: "GTM-TGSCLW6",
    "facebook-app": "173151945416207",
    domain: "https://h-profit.com",
    "video-help": true,
    "sidebar-link": true,
    contacts: true,
    ga: "G-236BYLD9EN",
    subscribe: true,
    production: true,
    cleaner: true,
    cashier: true,
    partners: true,
    "cashier-panel": true,
  },
  modeler: {
    tagmanager: "GTM-KXMGZNLM",
    "facebook-app": false,
    ga: "G-W80F03SV8C",
    domain: "https://gp-crm.com",
    "video-help": true,
    "sidebar-link": true,
    contacts: true,
    subscribe: true,
    production: true,
    cleaner: true,
    terminal: false,
    cashier: true,
    partners: true,
    "cashier-panel": true,
  },
};

const profitHTTP = 'https://h-profit.com'

const LINKS = {
  navkolo: {
    blog: "https://my.navkolo.one/terms",
    terms: "https://my.navkolo.one/terms",
    docs: "#",
    faq: "#",
    prom: "/art/prom.html",
    checkbox: "/art/checkbox.html",
    horoshop: "/art/horoshop.html",
    np: "/art/np.html",
    rozetka: "/art/rozetka.html",
    woocommerce: "/art/woocommerce.html",
    opencart: "/art/opencart.html",
    turbosms: "/art/turbosms.html",
    ukrposhta: "/art/ukrposhta.html",
    telegram: "/art/telegram.html",
    viber: "/art/viber.html",
    production: "/art/production.html",
    olx: '/art/olx.html',
    binotel: '/art/binotel.html',
    ringostat: '/art/ringostat.html',
    instagram: '/art/instagram.html',
    api: "https://app.swaggerhub.com/apis-docs/HINFO/BaseApi/1.0.0#/default",
    loyalcards: "/blog/yak-provesty-integracziyu-z-loyal-cards/",
    terminal: "/art/terminal.html",
  },
  modeler: {
    blog: "/ru/terms",
    terms: "/ru/terms",
    docs: "#",
    faq: "#",
    prom: "/art/prom.html",
    checkbox: "/art/checkbox.html",
    horoshop: "/art/horoshop.html",
    np: "/art/np.html",
    rozetka: "/art/rozetka.html",
    woocommerce: "/art/woocommerce.html",
    opencart: "/art/opencart.html",
    turbosms: "/art/turbosms.html",
    ukrposhta: "/art/ukrposhta.html",
    telegram: "/art/telegram.html",
    viber: "/art/viber.html",
    production: "/art/production.html",
    api: "https://app.swaggerhub.com/apis-docs/HINFO/BaseApi/1.0.0#/default",
    loyalcards: "/blog/yak-provesty-integracziyu-z-loyal-cards/",
    terminal: "#",
  },
  hugeprofit: {
    blog: `${profitHTTP}/blog`,
    terms: "https://h-profit.com/ru/agreement",
    faq: `${profitHTTP}/blog/faq-chasto-zadavaemye-voprosy-po-hugeprofit/`,
    docs: `${profitHTTP}/blog/dokumentacziya/`,
    np: `${profitHTTP}/blog/yak-pidklyuchiti-integracziyu-z-novoyu-poshtoyu/`,
    prom: `${profitHTTP}/blog/yak-provesti-integracziyu-z-prom-ua-v-servisi-hugeprofit/`,
    checkbox: `${profitHTTP}/blog/integracziya-z-checkbox/`,
    'vchasno-kassa': `${profitHTTP}/blog/prro-fiskalni-cheky-integracziya-z-vchasno-kasa/`,
    horoshop:
      `${profitHTTP}/blog/yak-provesti-integracziyu-z-horoshop-u-servisi-hugeprofit/`,
    rozetka: `${profitHTTP}/blog/kak-provesti-integracziyu-s-rozetka-v-servise-hugeprofit/`,
    woocommerce: `${profitHTTP}/blog/yak-provesti-integracziyu-z-woocommerce-v-servisi/`,
    opencart: `${profitHTTP}/blog/yak-provesti-integracziyu-z-opencart/`,
    turbosms: `${profitHTTP}/blog/integracziya-z-turbosms/`,
    telegram: `${profitHTTP}/blog/yak-nalashtuvati-integracziyu-z-telegram/`,
    ukrposhta: `${profitHTTP}/blog/yak-provesti-integracziyu-z-ukrposhtoyu/`,
    loyalcards: `${profitHTTP}/blog/yak-provesty-integracziyu-z-loyal-cards/`,
    viber: `${profitHTTP}/blog/yak-nalashtuvaty-integracziyu-z-vajber-botom/`,
    api: "http://api-doc.h-profit.com/en/",
    production: `${profitHTTP}/blog/virobnicztvo/`,
    terminal: `${profitHTTP}/blog/yak-nastroyity-integracziyu-z-pos-terminalom/`,
    partners: `${profitHTTP}/blog/otrymujte-vygody-z-nashoyu-partnerskoyu-programoyu-dlya-korystuvachiv-hugeprofit/`,
    binotel: `${profitHTTP}/blog/integracziya-z-binotel/`,
    instagram: `${profitHTTP}/blog/yak-pidklyuchyty-integracziyu-z-instagram/`,
    olx: `${profitHTTP}/blog/integracziya-z-olx/`,
    ringostat: `${profitHTTP}/blog/yak-provesty-integracziyu-z-ringostat/`,
    shopexpress: `${profitHTTP}/blog/yak-provesty-integracziyu-z-shop-express/`
  },
};

const WHITELABLES_NAMES = {
  navkolo: "Navkolo Онлайн-торгівля",
  hugeprofit: "HugeProfit",
  modeler: "GoodProfitCRM",
};

const AppStore = createStore({
  listenables: Actions,
  state: {
    title: null,
    desc: null,
    init: false,
    isMobileApp: false,
    countModal: 0,
    currentVersion: {},
    openModal: false,
    openInsideModal: false,
    dataModal: {},
    childModal: null,
    notificationClose: false,
    isAppPresent: false,
  },

  get(field) {
    return this.state[field];
  },

  set(state) {
    this.setState(state);
  },

  getAppName() {
    return WHITELABLES_NAMES[WHITELABLE];
  },

  getWlName() {
    return WHITELABLE;
  },

  isForeign() {
    return UserStore.isForeign();
  },

  getDocsLink(name) {
    return LINKS[WHITELABLE][name] || "#";
  },

  formatingLink(link) {
    return WHITELABLES_MODULES[WHITELABLE]["domain"] + link;
  },

  getAgreementLink() {
    let links = {
      navkolo: "https://my.navkolo.one/terms",
      hugeprofit: "https://h-profit.com/ru/agreement",
      modeler: "/ru/agreement",
    };

    return links[WHITELABLE];
  },

  onSet(state) {
    this.set(state);
  },

  checkVersion(first) {
    yajax.get("/version.json?t=" + Date.now()).then(
      (version) => {
        if (first) {
          this.setState({
            currentVersion: version,
          });
        } else {
          if (version.web !== this.state.currentVersion.web) {
            window.location.reload(true);
          }
        }

        setTimeout(this.checkVersion, 1000 * 60 * 10);
      },
      () => {
        console.log("FAILED GET VERSIOON");
        setTimeout(this.checkVersion, 1000 * 60 * 10);
      }
    );
  },

  getContactData(name) {
    let country = UserStore.get("country");
    if (Object.keys(CONTACT_DATA).indexOf(country) === -1) {
      country = "UA";
    }
    return CONTACT_DATA[country][WHITELABLE][name];
  },

  getContactDataByLang(name) {
    let lang = UserStore.getLang().toUpperCase();
    if (Object.keys(CONTACT_DATA).indexOf(lang) === -1) {
      lang = "UA";
    }
    return CONTACT_DATA[lang][WHITELABLE][name];
  },

  setIsMobileApp(data) {
    // let mobile = UserStore.isMobileApp();
    let mobile =
      UserStore.isMobileApp() ||
      window.location.pathname.indexOf("/app/") > -1 ||
      window.location.pathname.indexOf("/home") > -1;
    if (data) {
      mobile = data;
    }

    UserStore.setIsMobileApp(mobile);
    this.set({ isMobileApp: mobile, isAppPresent: mobile });
  },

  isMobileApp() {
    return UserStore?.isMobileApp();
  },

  getLink(link) {
    if (link.indexOf("/") === 0) {
      link = link.substring(1);
    }

    if (link[0] === "#") {
      return link;
    }

    if (this.isMobileApp()) {
      link = "app/" + link;
    }

    return "/" + UserStore.get("lang") + "/" + link;
  },

  getTitle() {
    return (
      this.get("title") || T("default-title", { name: AppStore.getAppName() })
    );
  },

  getDesc() {
    return this.get("desc");
  },

  openModal(child) {
    let modalName = child.type.displayName || child.type.name;
    const modalKey = child.props.modal || ''
    let now_child = this.state.childModal;

    if (now_child) {
      now_child[`${modalName}-${modalKey}`] = child;
    } else {
      now_child = {
        [`${modalName}-${modalKey}`]: child,
      };
    }
    UserStore.postMessage("hide-barcode");

    this.setState({
      countModal: this.state.countModal++,
      openInsideModal: true,
      childModal: now_child,
    });
  },

  checkChild(name) {
    let child = this.state.childModal;

    if(!child) {
      UserStore.postMessage("show-barcode");
    }

    if (name && child) {
      if (typeof child[name] !== "undefined") {
        delete child[name];
      }
    } else {
      child = null;
    }

    return child
  },

  closeModal(name) {
    const childState = this.state.childModal;

    const result = () => {
      const child = this.checkChild(name)
      this.setState({
        countModal: child === null ? 0 : this.state.countModal--,
        childModal: child,
        openInsideModal: child !== null,
      })
    }

    if(this.get('notificationClose')&& Object.keys(childState).length === 1) {
      confirmDelete(result,'confirm-edit-modal-title', false, 'confirm-label', 'cancel-label');
    } else {
      result()
    }
  },

  closeAllModal() {
    this.setState({
      dataModal: {},
      childModal: null,
      countModal: 0,
      openInsideModal: false,
      openModal: false,
    })
  },

  isEnableForWhiteLable(_module) {
    let isEnable = true;

    if (WHITELABLE in WHITELABLES_MODULES && _module in WHITELABLES_MODULES[WHITELABLE]) {
      isEnable = WHITELABLES_MODULES[WHITELABLE][_module];
    }

    return isEnable;
  },

  getImagesPath(img) {
    return "/img/" + WHITELABLE + "/" + img;
  },

  onInit() {
    // ExpensesActions.loadAccounts();
    // WarehouseActions.load();
    // ProductsActions.load();
    // NotificationActions.load();
    // UserActions.getCurrentStateTarif();
    // SalesActions.loadReports();
  },

  saveLocalStorageAndMobile({key, value}) {

    window.localStorage.setItem(key, value)

    if(this.isMobileApp()) {
      if (['traffic_source', 'news'].includes(key)) return;
      const storageData = {};
      Object.keys(window.localStorage).forEach(key => {
          try {
              storageData[key] = JSON.parse(window.localStorage[key]);
          } catch (e) {
              storageData[key] = window.localStorage[key];
          }
      });

      window.parent.postMessage('store-save::' + JSON.stringify(storageData), '*')

      // window.parent.postMessage('store-save::' + JSON.stringify({...window.localStorage}), '*')
    }
  }
});

export default AppStore;
