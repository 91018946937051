import React, {useCallback, useEffect, useRef, useState} from 'react'
import Select from "./index";
import SelectProductStore from "../select-products/select-product-store";
import ProductsStore from "../../modules/products/products-store";
import SelectComponent from "../../tools/select-component";
import WarehouseStore from "../../modules/warehouse/warehouse-store";
import WarehouseActions from "../../modules/warehouse/warehouse-actions";
import {
	Control,
	Field,
	Radio
} from "react-bulma-components/lib/components/form";
import T from "../i18n";
import ProductsActions from "../../modules/products/products-actions";
import {connectToStores} from "../../tools/reflux-tools";
import AppStore from "../../app-store";
import SelectWarehouseProduct
	from "../select-warehouse-product/select-warehouse-product";
import AddProductsModal
	from "../../modules/products/modal/add-product/add-products-modal";
import Actions from "../../modules/products/products-actions";
import usePaginationHook from "../../tools/pagination-hook";

const CategoryFilter = ({value, onChange}) => {
	return (
		<SelectComponent
			name="category"
			empty={"all-categories"}
			styleWrapper={{minWidth: '135px', flex: 1, margin: 0}}
			onChange={onChange}
			autoSelect={false}
			value={value}
			list={ProductsStore.getFormattingCategoryList}
			load={ProductsActions.loadCategory}
			creatable={false}
		/>
	);
}

const BrandFilter = ({value, onChange}) => {
	let list = ProductsStore.getBrandsList();
	if (list.length <= 1) return null;
	return (
			<SelectComponent
				styleWrapper={{minWidth: '135px', flex: 1, margin: 0}}
				name="brand"
				empty="all-brands"
				autoSelect={false}
				onChange={onChange}
				list={ProductsStore.getBrandsList}
				value={value}
				creatable={false}
			/>
	);
}

const WarehouseFilter = ({value, onChange}) => {
	let list = WarehouseStore.getWarehouseList();
	if (list.length <= 1) return null;

	return (
		<SelectComponent
			name="mid"
			empty="all-warehouse"
			styleWrapper={{minWidth: '135px', flex: 1, margin: 0}}
			autoSelect={false}
			onChange={onChange}
			value={value}
			list={WarehouseStore.getWarehouseList}
			load={WarehouseActions.load}
			creatable={false}
		/>
	);
}

const StockFilter = ({value, onChange}) => {
	const instock = value
	return (
		<Field>
			<Control>
				<Radio
					key="instock-0"
					onChange={onChange}
					checked={instock === "0"}
					value="0"
					size="large"
					name="instock"
					className="mySelect"
				>
					{T("_all")}
				</Radio>
				<Radio
					key="instock-1"
					onChange={onChange}
					checked={instock === "1"}
					value="1"
					size="large"
					name="instock"
					className="mySelect"
				>
					{T("in-stock")}
				</Radio>
				<Radio
					key="instock-2"
					onChange={onChange}
					checked={instock === "2"}
					value="2"
					size="small"
					name="instock"
					className="mySelect"
				>
					{T("out-of-stock")}
				</Radio>
			</Control>
		</Field>
	);
}

const fetchData = ({start, end, disabledProductById, search, source, disabledStock, filter}) => {
	return new Promise((resolve) => {
			const newData = SelectProductStore.getProductList(search, source, disabledProductById, filter, disabledStock);
			resolve({data:newData.slice(start, end), length: newData.length});
	});
};

const ProductSelect = ({onChange, selected, source, mid, disabledProductById, single, zIndex, withoutMid, disabledKeyStore}) => {
	const [selectFromScan, setSelectFromScan] = useState({})
	const [isNewProduct, setIsNewProduct] = useState(false)
	const [list, setList] = useState([])
	const [lengthArray, setLengthArray] = useState(0)
	const [filter, setFilter] = useState({
		status: "all",
		category: { value: 0, label: T('all-categories') },
		shipment: { value: 0, label: T('all')},
		brand: { value: 0, label: T('all-brands') },
		mid: mid ? { value: mid } : { value: 0, label: T('all')},
		instock: window.localStorage.getItem("select-instock") || "0",
		withoutbarcode: false,
		withoutprice: false,
		search: "",
		margin_from: "",
		margin_to: "",
		price_from: "",
		price_to: "",
		sort: {},
		attr: {},
		reserv: { value: 0, label: T('all') },
		integration: { value: 0, label: T('all') },
	})

	const { start, end, page, total, setPage } =
		usePaginationHook({ perPage: 20,  listLength: lengthArray });
	const refFunc = useRef(null);


	const handleChangeInstock = ({target: {name, value}}) => {
		setFilter(prev => ({
			...prev,
			[name]: value
		}))
		AppStore.saveLocalStorageAndMobile({key: 'select-instock', value})
	}

	const handleChangeSelect = (selectedOption, actionMeta) => {
		setFilter(prev => ({
			...prev,
			[actionMeta.name]: selectedOption
		}))
	}

	const handleSetIsNewProduct = () => {
		setIsNewProduct(false)
	}

	useEffect(() => {
		if(mid) {
			setFilter(prev => ({
				...prev,
				mid: {value: mid},
			}))
		}
	}, [mid]);

	useEffect(() => {
		const listenFromScanner = ProductsActions.setProductFromScanner.completed.listen(
			(barcode) => {
				const product = ProductsStore.findProductByBarcode(barcode)
				const _scan_product = ProductsStore.get("fromScanner");
				const disabledOpenWithWarehouse = source !== 'shipment'
				if(product && Object.keys(product.stock).length > 1 && !filter.mid.value && !selectFromScan[barcode] && disabledOpenWithWarehouse) {
					const handleChange = (value) => {
						onChange({"select_product": value[0]})
						setSelectFromScan(prev => ({...prev, [barcode]: value[0]}));
					}
					AppStore.openModal(<SelectWarehouseProduct productID={product.id} onChange={handleChange}/>)
				} else if(product && filter.mid.value && product.stock[filter.mid.value] && disabledOpenWithWarehouse) {
					onChange({"select_product": {value: ProductsStore.getIdent(product.id, filter.mid.value)}})
				} else if(selectFromScan[barcode] && disabledOpenWithWarehouse) {
					onChange({"select_product": selectFromScan[barcode]})
				}
				else if (_scan_product) {
					onChange(_scan_product);
				} else {
					AppStore.openModal(<AddProductsModal source={source} mid={mid} barcode={barcode} onMountHandle={handleSetIsNewProduct}/>)
					setIsNewProduct(true)
				}
			}
		);

		const listenLoad = Actions.loadByPid.completed.listen((data) => {
			if (isNewProduct) {
				const firstMid = Object.values(data.data[0]['i'] || {})[0]
				onChange([{
					value: ProductsStore.getIdent(firstMid["product_id"], firstMid["marketplace_id"]),
					label: data.data[0]["t"],
				}]);
				setIsNewProduct(false);
			}
			const searchData = refFunc.current.getSearch();
			handleSearch(searchData)
		});

	return () => {
			listenFromScanner()
			listenLoad()
	}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectFromScan, isNewProduct, refFunc, filter])

	const handleOpenModalAddProduct = ({name, barcode}) => {
		setIsNewProduct(true)
		let barcodeInput = barcode;
		let nameInput = name
		if(!barcode && !isNaN(name)) {
			barcodeInput = name;
			nameInput = ''
		}

		AppStore.openModal(<AddProductsModal source={source} modal='add-product' barcode={barcodeInput} name={nameInput} mid={mid} onMountHandle={handleSetIsNewProduct}/>)
	}

	const loadData = async ({search, disabledProductById, start, end, source, disabledStock}) => {
		const newData = await fetchData({search, disabledProductById, start, end, source, disabledStock, filter});
		setList(newData.data);
		setLengthArray(newData.length);

		return newData;
	};

	const timer = useRef(null)

	const handleSearch = (value) => {
		loadData({ start, end, disabledProductById, source, search: value });
	};

	const handleOnEnter = async (indexSelect, search) => {
		const isBarcode = search && !isNaN(search) && search.length > 10;
		const list = await loadData({ start, end, disabledProductById, source, search, disabledStock: isBarcode });
		const product = list.data[indexSelect];
		if(product) {
			const select_product_from_scanner = ProductsStore.getProductFromScanner('', product)
			const disabledOpenWithWarehouse = source !== 'shipment'
			if(product && Object.keys(product.stock).length > 1 && !filter.mid.value  && disabledOpenWithWarehouse) {
				const handleChange = (value) => {
					onChange({"select_product": value[0]})
				}
				AppStore.openModal(<SelectWarehouseProduct productID={product.id} onChange={handleChange}/>)
			} else if(product && filter.mid.value && product.stock[filter.mid.value] && disabledOpenWithWarehouse) {
				onChange({"select_product": {value: ProductsStore.getIdent(product.id, filter.mid.value)}})
			} else if(select_product_from_scanner) {
				onChange(select_product_from_scanner);
			} else {
				handleOpenModalAddProduct({name: search})
			}
		} else {
			handleOpenModalAddProduct({name: search})
		}
	}

	const debouncedHandleSearch = useCallback(
		(value) => {
			clearTimeout(timer.current);

			timer.current = setTimeout(() => {
				handleSearch(value);
			}, 200);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filter]
	);

	useEffect(() => {
		const searchData = refFunc.current.getSearch();
		handleSearch(searchData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [start, end, disabledProductById, source, filter]);
	return (
		<Select
			ref={refFunc}
			zIndex={zIndex}
			list={list}
			maxHeightSelect={1000}
			styleWrapper={{marginBottom: 10}}
			isMulti
			disabledLabel
			placeholder='select-product'
			withPagination={{
				start, end, page, total,
				setPage
			}}
			type='product'
			mid={mid}
			onSearch={debouncedHandleSearch}
			disabledAutoSelect
			disabledKeyStore={disabledKeyStore}
			selected={mid ? selected.map(item => `${item}_${mid}`) : selected}
			onChange={onChange}
			addItem={(name) => handleOpenModalAddProduct({name})}
			addItemModal={() => handleOpenModalAddProduct({})}
			isCreate
			single={single}
			source={source}
			onEnter={handleOnEnter}
			withoutMid={withoutMid}
			styleSelect={{borderRadius: 4}}
			additionalTopContent={<div style={{padding: 10, background: '#fafafa', textAlign: 'left'}}>
				<div style={{display: 'flex', flexWrap: 'wrap', gap: 5}}>
					{(!mid && !withoutMid) && <WarehouseFilter value={filter.mid} onChange={handleChangeSelect}/>}
					<CategoryFilter onChange={handleChangeSelect} value={filter.category}/>
					<BrandFilter onChange={handleChangeSelect} value={filter.brand}/>
				</div>
				<StockFilter onChange={handleChangeInstock} value={filter.instock}/>
			</div>}
		/>
	);
};

export default connectToStores(ProductSelect, {SelectProductStore});