import React, { useState, useEffect } from 'react'
import T from '../../../components/i18n'
import { Field, Control } from 'react-bulma-components/lib/components/form';
import Box from 'react-bulma-components/lib/components/box';
import Button from "react-bulma-components/lib/components/button";
import SubmitButton from "../../../components/submit-button";
import Messages from 'components/messages';
import Heading from 'react-bulma-components/lib/components/heading';
import Content from 'react-bulma-components/lib/components/content';
import ClientsStore from '../clients-store';
import ClientsActions from '../clients-actions';
import { connectToStores } from 'tools/reflux-tools';
import confirm from 'tools/confirm';
import DiscountRow from './discount-row';
import RadioButtonsList
  from "../../../components/radio-buttons-list/radio-buttons-list";
import UserStore from "../../../user/user-store";

const RADIO_LIST = [
  {title: "percentage-amount", value: "discount"},
  {title: "bonus-accumulation", value: "bonus"}
]

const DiscountSettingsModal = ({clients, onClose}) => {
  const [isEnabled, setIsEnabled] = useState(clients?.isDiscountSystemEnable || false)
  const newDiscount = { purchases: "", discount: "" };
  const [discounts, setDiscounts] = useState(clients?.discounts?.length > 0 ? clients.discounts : [newDiscount]);
  const [discountType, setDiscountType] = useState(ClientsStore.getDiscountTypes())

  const [message, setMessage] = useState("");
  const [color, setColor] = useState(null)
  const [waiting, setWaiting] = useState(false);
  
  useEffect(() => {
    const onSetDiscountSystemCompleted = ClientsActions.setDiscountSystem.completed.listen(() => {
      setWaiting(false);
      setColor("success");
      setMessage(T("discounts-success-message"));
      if (typeof (onClose) == "function") {
        setTimeout(()=> onClose(), 1500);
      };
      
    });

    const onSetDiscountSystemFailed = ClientsActions.setDiscountSystem.failed.listen((res) => {
      const data = JSON.parse(res['response']);
      setColor('danger')
      setWaiting(false);
      setMessage({'msg': T(data['error'])})
    }); 
  
    return () => {
      if (typeof (onSetDiscountSystemCompleted) === "function") {
            onSetDiscountSystemCompleted();
        }

        if (typeof(onSetDiscountSystemFailed) === "function") {
            onSetDiscountSystemFailed();
        }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const addDiscount = () => {
    setDiscounts([...discounts, newDiscount])
  };
  
  const onSwitchChange = () => {
    setIsEnabled(!isEnabled)
  };
  
  const onChange = (evt, index) => {
    const inputName = evt.target.name;
    const newValue = evt.target.value;

    const updatedDiscounts = [...discounts];
    updatedDiscounts[index] = { ...updatedDiscounts[index], [inputName]: newValue };

    setDiscounts(updatedDiscounts);
  };

  const onChangeDiscountType = ({value}) => {
    setDiscountType(value)
  }

  const onDeleteField = (index) => {
    const updatedDiscounts = [...discounts];
    
    updatedDiscounts.splice(index, 1);
    setDiscounts(updatedDiscounts);
  }

  const disableDiscounts = () => {
      ClientsActions.setDiscountSystem(false)
      if (typeof(onClose) == "function") {
        onClose();
      }
  }

  const onSubmit = () => {
    setWaiting(true);

    if (!isEnabled) {
      confirm('confirm-disable-discounts', T('are-you-sure-to-disable-discounts'), disableDiscounts, T('disable-btn'));
      setWaiting(false);
      return
    }

    const specifiedDiscounts = [];
    for (let discount of discounts) {
      if (parseInt(discount.purchases) > 0 && parseInt(discount.discount) > 0) {
        specifiedDiscounts.push(discount)
      }
    }

    if (specifiedDiscounts.length === 0) {
      setMessage(T("specify-at-least-one-discount"));
      setWaiting(false);
      return 
    }

    ClientsActions.setDiscountSystem(isEnabled, specifiedDiscounts, discountType)
  };

  return (
    <div>
      <Box>
        <Messages message={message} color={color} close={() => setMessage(null)} />
        <Heading className="text-align-center">{T("discounts-settings")}</Heading>
        <Content className="text-align-justify">{T("discounts-settings-dashboard")}</Content>
        <Field>
          <Control>
            <label className="discounts-label">
            <div className="discounts-switch">
              <input
                className="discounts-input"
                type="checkbox"
                onChange={() => onSwitchChange()}
                checked={isEnabled}
                disabled={UserStore.isActiveLoyalCars()}
            />
            <span className="discounts-slider round"></span>
              </div>
              <span>{isEnabled ? T('disable-automatic-discounts') : T('enable-automatic-discounts')}</span></label>
          </Control>
        </Field>
        {UserStore.isActiveLoyalCars() && <p className='margin-bottom-10 text-danger bold' align='center'>{T('loyalty-system-cannot-be-enabled')}</p>}
        {!isEnabled ? null : 
          <>
            <RadioButtonsList list={RADIO_LIST} label='discount-type' name='types' defaultValue={discountType} onChange={onChangeDiscountType}/>
            <p>{T('discounts-settings-'+discountType)}</p> <br />
          {discounts?.map((discount, index) => <DiscountRow type={discountType} key={index} discount={discount} index={index} onChange={(evt) => onChange(evt, index)} onDelete={()=>onDeleteField(index)} />)}
          <Button.Group position="right">
          <Button size="small" rounded color="info" onClick={addDiscount}>{discounts.length > 0 ? T('add-discount') : T('add-first-discount')}</Button>
        </Button.Group>
          </>
        }
        {UserStore.isActiveLoyalCars() ? (
          <div align='right'>
            <Button onClick={onClose}>{T("close")}</Button>
          </div>
        ) : (
          <SubmitButton text={T('btn-save')} fullwidth waiting={waiting} submit={onSubmit}/>
        )}
      </Box>
    </div>
  );

}

export default connectToStores(DiscountSettingsModal, {clients: ClientsStore});