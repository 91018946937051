import React, {useEffect, useState} from 'react'
import s from './styles.module.scss'
import T from "../../../components/i18n";
import {useHistory, useLocation} from "react-router-dom";
import AppStore from "../../../app-store";
import LoginForm from "./login-form";
import RegistrationForm from "./registration-form";
import ErrorMessageControlHook
	from "../../../components/error-message-block/error-message-control.hook";
import UserStore from "../../../user/user-store";
import {redirectTo} from "../../../tools/redirect-to";
import UserActions from "../../../user/user-actions";
import SelectLang from "../../../templates/common/select-lang";
import PhonePresentation from "./phone-presentation/phone-presentation";

const LoginPage = () => {
	const location = useLocation()
	const history = useHistory()
	const isRegistration = location.pathname.includes("registration");
	const [isApp, setIsApp] = useState(AppStore.get('isAppPresent'))
	const [isActivePanel, setIsActivePanel] = useState(isRegistration)
	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()

	const handleActivePanel = (isActive) => {
		setIsActivePanel(isActive)
	}

	const whereToSend = () => {
		let main_rounte = "/dashboard";

		if(UserStore.isFirstReg()) {
			return "/start"
		}

		if (UserStore.isCashier()) {
			main_rounte = "/sales";
		} else if (UserStore.isManager()) {
			main_rounte = "/sales";
		} else if (UserStore.isWarehouseMan()) {
			main_rounte = "/products";
		}

		if (!UserStore.getModuleEnable(main_rounte.substring(1))) {
			main_rounte = "/products";
		}

		return main_rounte;
	};

	useEffect(() => {
		const loadChangeToken = UserActions.changeToken.completed.listen(() => {
			history.push(whereToSend());

			loadChangeToken();
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	if(!UserStore.isGuest()) {
		return redirectTo(whereToSend())
	}

	if(isApp) {
		return <PhonePresentation onOpenRegistration={() => setIsApp(false)}/>
	}

	return (
		<>
			<div className={s.wrapper}>
				<img src="/img/hp-black.png" className={s.logo} alt="Huge Profit"/>
				<div className={s.wrapper_container}>
					<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} disabledScroll/>
					<div className={`${s.container} ${isActivePanel ? s.active_panel : ''}`}>
						<div align='right' className={s.select_lang}>
							<SelectLang/>
						</div>
						<LoginForm changeForm={() => handleActivePanel(true)}/>
						<RegistrationForm setError={handleAutoRemoveError} changeForm={() => handleActivePanel(false)}/>
						<div className={s.container_overlay}>
							<div className={s.overlay}>
								<div className={`${s.overlay_panel} ${s.overlay_right}`}>
									<button className={s.btn} id="signIn" onClick={() => handleActivePanel(true)}>{T('registration')}</button>
								</div>
								<div className={`${s.overlay_panel} ${s.overlay_left}`}>
									<button className={s.btn} id="signUp" onClick={() => handleActivePanel(false)}>{T('login-btn-label')}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className={`${s.footerLinks} display-flex-row`}>
					<a href={AppStore.getLink("agreement")}>{T('agreement')}</a> |{" "}
					<a href={AppStore.getLink("policy")}>{T('policy')}</a>
				</div>
			</div>
		</>
	);
};

export default LoginPage;