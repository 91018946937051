import React from 'react'
import {
	Control,
	Input,
	Label
} from "react-bulma-components/lib/components/form";
import InfoWrapper from "../../../../components/info-wrapper";
import T from "../../../../components/i18n";
import NumberInput from "../../../../components/number-input/number-input";
import DatePicker from "react-datepicker/es";
import UserStore from "../../../../user/user-store";

const ClientInput = ({item, value, onChange, size, withPrefix, onChangeDate}) => {
	const label = item.infoLabel ? <Label><InfoWrapper info={item.infoLabel}>{T(item.label)}</InfoWrapper></Label> :  <Label>{T(item.label)}</Label>
	if(item.type === "date") {
		const WrapperDate = size === 'large' ? Control : 'div'
		return (
			<div>
				<Label>{T(item.label)}</Label>
				<WrapperDate className={size === 'large' ? '' : 'display-flex-row date-picker-full-width'}>
					<DatePicker
						name={item.name}
						selected={value}
						onChange={(date) => onChangeDate(date, item.name)}
						maxDate={new Date()}
						size={size}
						className="input"
						dateFormat="dd-MM-yyyy"
						peekNextMonth
						showMonthDropdown
						showYearDropdown
						dropdownMode="select"
						locale={UserStore.getLang()}
					/>
					{withPrefix && <div style={{width: 13}}/>}
				</WrapperDate>
			</div>
		)
	}
 	return (
		<div>
			{item.label && !item.disabledLabel && label}
			<div className='display-flex' style={withPrefix ? {gap: 5} : {}}>
				{item.type === 'number' ? (
					<NumberInput
						size={size}
						name={item.name}
						type="text"
						placeholder={T(item.placeholder)}
						onChange={onChange}
						color={value?.error ? "danger" : null}
						value={value?.value}
					/>
				) : (
					<Input
						size={size}
						name={item.name}
						type="text"
						placeholder={T(item.placeholder)}
						onChange={onChange}
						color={value?.error ? "danger" : null}
						value={value?.value}
					/>
				)}
				{withPrefix && <div style={{width: 13}}/>}
			</div>
		</div>)
};

export default ClientInput;