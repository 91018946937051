import React, {useEffect, useState} from 'react'
import HistoryStore from "../history-store";
import StockMarketplacesRowDesktop from "./stock-marketplaces-row-desktop";
import Box from "react-bulma-components/lib/components/box";
import T from "../../../components/i18n";
import Reports from "../../../components/reports";
import StockMarketplacesRowMobile from "./stock-marketplaces-row-mobile";
import usePaginationHook from "../../../tools/pagination-hook";
import FilterPanel from "./filter-panel";
import ProductsStore from "../../products/products-store";
import {filterSearch} from "../../../tools/filters-helper";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import HistoryActions from "../history-actions";

const EmptyMessage = () => {
	return (
		<Box>
			<h2>{T('not-have-history')}</h2>
		</Box>
	);
};

const COLUMNS = ["","reports-date", "integration", "status", 'products-request', 'reports-actions'];

const StockMarketplaces = () => {
	const {isMobileSize} = useIsMobile()
	const [valueFilter, setValueFilter] = useState({
		status: {value: 0, label: T('all')},
		channel: {value: 0, label: T('all')},
		date_start: new Date(),
		date_end: new Date(),
		search: ''
	})
	const data = HistoryStore.getStockMarketplaces(valueFilter.date_start, valueFilter.date_end)

	const filterData = data.filter(item => {
		let result = true

		valueFilter.date_start.setHours(0,0,0,0);
		valueFilter.date_end.setHours(23,59,59,999);

		const date = new Date(item.created_at * 1000)

		if (date < valueFilter['date_start'] || date > valueFilter['date_end']) {
			return false;
		}

		if(valueFilter.search) {
			const isExistProduct = item.stock.some(stockItem => {
				const product = ProductsStore.getProductByMid(stockItem.product_id, stockItem.mid);
				return filterSearch(valueFilter.search, [
					'sku', 'asin', 'brand_id',
					'category_id', 'id', 'name', 'ukzt',
					'parent_id'
				], product);
			});

			result = result && isExistProduct
		}

		if(valueFilter.status.value) {
			result = result && parseFloat(valueFilter.status.value) === parseFloat(item.status)
		}
		if(valueFilter.channel.value) {
			result = result && parseFloat(valueFilter.channel.value) === parseFloat(item.integration_id)
		}

		return result
	})

	const {total,page,setPage,PaginationBlock,end,start} = usePaginationHook({listLength: filterData.length})

	const handleChangeFilter = (name, value) => {
		setValueFilter(prev => ({...prev, [name]: value}))
	}

	useEffect(() => {
		HistoryActions.stockMarketplaces(false, valueFilter.date_start, valueFilter.date_end)
	}, [valueFilter]);

	return (
		<>
			{!isMobileSize && <FilterPanel valueFilter={valueFilter} onChangeFilter={handleChangeFilter}/>}
			<Box className='border-not-top'>
				{isMobileSize && <FilterPanel valueFilter={valueFilter} onChangeFilter={handleChangeFilter}/>}
				<PaginationBlock
					totalPage={total}
					setPage={setPage}
					currentPage={page}
					showPrevNext={false}
				/>
				<Reports rows={filterData.slice(start,end)} emptyMessage={EmptyMessage} columns={COLUMNS}>
					{isMobileSize ? <StockMarketplacesRowMobile role='row'/> : <StockMarketplacesRowDesktop role='row'/>}
				</Reports>
				<PaginationBlock
					totalPage={total}
					setPage={setPage}
					currentPage={page}
					showPrevNext={false}
				/>
			</Box>
		</>
	);
};

export default StockMarketplaces;