import React, { useEffect, useState } from "react";
import Preview from "./common/preview";
import s from "./styles.module.scss";
import SettingBlock from "./common/setting-block";
import {
  checkFont,
  defaultValueFunc,
  headingCheckbox,
  headingInput,
  listFooter,
  nameInput,
  tableCheckbox,
  tableInput,
} from "./utils";
import T from "../../../components/i18n";
import NotRequiredSettingBlock from "./common/not-required-setting-block";
import {
  Checkbox,
  Field,
  Label,
} from "react-bulma-components/lib/components/form";
import useControlSettings from "./common/use-control-settings";
import ActionButton from "./common/action-button";
import {Button, Notification} from "react-bulma-components";
import UserStore from "../../../user/user-store";
import { connectToStores } from "../../../tools/reflux-tools";
import UserActions from "../../../user/user-actions";
import ErrorMessageControlHook from "../../../components/error-message-block/error-message-control.hook";
import {errorHandler} from "../../../tools/error-handler";
import LogoSetting from "./common/logo-setting";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import InfoWrapper from "../../../components/info-wrapper";
import {confirmDelete} from "../../../tools/confirm-delete";

const DocumentSettingsTemplate = ({ name, label }) => {
  const {isMobileSize} = useIsMobile()
  const getSettingByName = UserStore.getGlobalSetting(name);
  const {
    heading,
    setHeadingState,
    table,
    slipName,
    footer,
    isTermoPrint,
    handleChangeCheckbox,
    handleChangeInput,
    handleChangeCheckboxFooter,
    handleChangeInputFooter,
    setIsTermoPrint,
    handleChangeAllValue,
  } = useControlSettings(
    Object.keys(getSettingByName || {})?.length
      ? getSettingByName
      : defaultValueFunc(name, label)
  );
  const [colorMessage, setColorMessage] = useState("success");

  const {
    setErrorMessage,
    ErrorMessageBlock,
    errorMessage,
    handleAutoRemoveError,
  } = ErrorMessageControlHook();


  const checkFontsData = () => {
    const errors = []

    const headingError = checkFont(heading.font, 'header-customizing')
    if (headingError) errors.push(headingError)

    const tableError = checkFont(table.font, 'table-customization')
    if (tableError) errors.push(tableError)

    const slipNameError = checkFont(slipName.font, 'name-customizing')
    if (slipNameError) errors.push(slipNameError)

    const footerErrors = Object.keys(footer).map(item => checkFont(footer[item].font, item))
    footerErrors.forEach(error => {
      if (error) errors.push(error);
    });

    if(errors.length) {
      const arrayErrors = errors.map(item => {
        let value = item
        if(item === 'price_in_words') {
          value = 'price-words'
        }
        if(item === 'signature_seller') {
          value = 'signature-seller'
        }
        if(item === 'signature_buyer') {
          value = 'signature_buyer'
        }
        if(item === 'add_info') {
          value = 'additional-info'
        }
        return T(value)
      })
      setColorMessage("danger")
      handleAutoRemoveError( 'font-error-doc', {list: arrayErrors})
    }

    return !errors.length
  }
  const handleCheckHeading = () => {
    setColorMessage("danger")

    if(heading.logo && !heading.logo.placement && !heading.logo.placement.value) {
      handleAutoRemoveError('select-position')
      return false
    }
    if(heading.logo && heading.width < 50 && heading.width > 400) {
      handleAutoRemoveError('logo-width-error')
      return false
    }

    return true
  }

  const handleSubmit = () => {
    if(checkFontsData() && handleCheckHeading()) {
      const headingData = JSON.parse(JSON.stringify(heading))
      if(headingData.logo) {
        headingData.logo.placement = headingData.logo.placement.value
      }

      UserStore.setGlobalSetting(
        {
          heading: headingData,
          table,
          slipName,
          footer,
          isTermoPrint: Number(isTermoPrint),
        },
        name
      );
    }

  };

  const handleResetToDefault = () => {
    confirmDelete(() => UserStore.deleteGlobalSetting(name), "confirm-action", false, 'confirm-label', 'cancel-label')
  };

  useEffect(() => {
    const updateValue = UserActions.updateSettings.completed.listen(() => {
      const getSettingByName = UserStore.getGlobalSetting(name);

      handleChangeAllValue(
        Object.keys(getSettingByName || {})?.length
          ? getSettingByName
          : defaultValueFunc(name, label)
      );
      setColorMessage("success");
      handleAutoRemoveError('message-success')
    });

    const updateValueFailed = UserActions.updateSettings.failed.listen((res) => {
      const message = errorHandler(res);
      setColorMessage('danger');
      handleAutoRemoveError(message)
    })

    return () => {
      updateValue();
      updateValueFailed()
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  const buttonReset = (
    <div
      className="margin-bottom-10"
      align="left"
    >
      <Button
        color="warning"
        size="small"
        rounded
        onClick={handleResetToDefault}
      >
        {T("reset-to-default-columns")}
      </Button>
    </div>
  );
  const notRequiredSetting = (
    <NotRequiredSettingBlock
      list={listFooter}
      values={footer}
      styles={isMobileSize ? {} :{flex: 1}}
      onChangeCheckbox={handleChangeCheckboxFooter}
      onChangeInput={handleChangeInputFooter}
    />
  );

  return (
    <>
      <ErrorMessageBlock
        message={errorMessage}
        onRemove={setErrorMessage}
        color={colorMessage}
      />
      {!getSettingByName && <Notification color='warning'>
        {T('notif-pr-doc-is-new')}
      </Notification>}
      <div className={s.container}>
        <div className={s.wrapper}>
          <Preview name={name} onSubmit={handleSubmit}/>
          <LogoSetting isTermo={isTermoPrint} values={heading.logo} setValues={setHeadingState}/>
          {!isMobileSize && notRequiredSetting}
          {!isMobileSize && buttonReset}
        </div>
        <div className={s.wrapper}>
          <SettingBlock
            type="heading"
            checkboxTitle='which-fields-show-heading'
            values={heading}
            label="header-customizing"
            listCheckbox={headingCheckbox}
            listInput={headingInput}
            onChangeCheckbox={handleChangeCheckbox}
            onChangeInput={handleChangeInput}
          />
          <SettingBlock
            type="name"
            values={slipName}
            label="name-customizing"
            listInput={nameInput}
            subTitle={<InfoWrapper info='document-short-tips'>{T("short-codes-print-doc")}</InfoWrapper>}
            onChangeInput={handleChangeInput}
          />
          <SettingBlock
            type="table"
            values={table}
            label="table-customization"
            checkboxTitle='which-fields-show-table'
            listCheckbox={tableCheckbox}
            listInput={tableInput}
            onChangeCheckbox={handleChangeCheckbox}
            onChangeInput={handleChangeInput}
          />
          {isMobileSize && notRequiredSetting}
          <Field style={{ textAlign: "left" }}>
            <Label size="large">
              <Checkbox
                name="termo-print"
                onChange={({ target: { checked } }) => setIsTermoPrint(checked)}
                checked={!!isTermoPrint}
              >
                {T("termo-print")}
              </Checkbox>
            </Label>
          </Field>
          {isMobileSize && buttonReset}
          <ActionButton waiting={false} onSubmit={handleSubmit} />
        </div>
      </div>
    </>
  );
};

export default connectToStores(DocumentSettingsTemplate, { _: UserStore });
