import React, {useEffect} from "react";
import BaseFilterLayout from "../../../components/base-filter-layout";
import ShipmentsStore from "../shipments-store";
import ShipmentFilters from "./shipments-filters";
import Actions from "../shipments-actions";
import ShipmentsActions from "../shipments-actions";
import getQueryParams from "../../../tools/query-params";
import {Link, useHistory, useLocation} from "react-router-dom";
import ProductsActions from "../../products/products-actions";
import {Dropdown} from "react-bulma-components";
import T from "../../../components/i18n";
import {createMobileAppLink} from "../../../tools/create-mobile-app-link";
import { LocalIcon } from "whitelables/wl-name/icons";


const AdditionActions = () => {
  return <Dropdown
    right={false} label={<LocalIcon icon="download" size="large" />}
  >
    <Dropdown.Item value='pdf' title={T("download-report-pdf")}>
      <Link
        className='drop-item'
        to={ShipmentsStore.printShipmentURL('pdf')}
        target="_blank"
        onClick={(evt) => createMobileAppLink(ShipmentsStore.printShipmentURL('pdf'), evt)}
      >
        {T("download-report-pdf")}
      </Link>
    </Dropdown.Item>
    <Dropdown.Item value='excel' title={T("download-report-excel")}>
      <Link
        className='drop-item'
        to={ShipmentsStore.printShipmentURL('xls')}
        target="_blank"
        download
        onClick={(evt) => createMobileAppLink(ShipmentsStore.printShipmentURL('xls'), evt)}
      >
        {T("download-report-excel")}
      </Link>
    </Dropdown.Item>
  </Dropdown>
}

const FilterPanel = () => {
  const location = useLocation()
  const history = useHistory();
  const handleRefresh = () => {
    ShipmentsStore.clearFilter();
    Actions.load(true);
    ProductsActions.load()
    history.push('shipments')
  };

  const handleChangeDate = (value) => {
    const daysKey = Object.keys(value).reverse();
    daysKey.forEach((item) => {
      if(value[item]) {
        ShipmentsActions.filter(item, value[item]);
      }
    });
  };

  const handleChange = ({ name, value }) => {
    if (name) {
      ShipmentsActions.filter(name, value);
    }
  };

  useEffect(() => {
    let sid = getQueryParams("sid");
    if (sid) {
      Actions.filter("search", sid);
    }

    return () => {
      const currentPath = window.location.pathname;
      if (currentPath !== '/shipments' && !currentPath.startsWith('/shipments/')) {
        ShipmentsStore.clearFilter()
      }
    }
  }, []);
  return (
    <BaseFilterLayout
      searchStringSetting={{
        onChange: handleChange,
        defaultValue: location?.state?.search || ShipmentsStore.getFilter("search"),
      }}
      refresh={{
        onChange: handleRefresh,
        isLoading: ShipmentsStore.get('loadingShipment'),
      }}
      dateRangeSetting={{
        onChange: handleChangeDate,
        date_start: location?.state?.period || ShipmentsStore.getFilter("date_start"),
        date_end: ShipmentsStore.getFilter("date_end"),
      }}
      hiddenFilter={<ShipmentFilters />}
      additionalSetting={<AdditionActions />}
    />
  );
};

export default FilterPanel;
