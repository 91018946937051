import React from 'react'
// import SelectComponents from "../../../../../tools/select-component";
// import T from	'../../../../../components/i18n'
// import RadioButtonListControl
// 	from "../../../../../components/radio-buttons-list/radio-button-list-control";
// import InfoWrapper from "../../../../../components/info-wrapper";

// const SELECT_LIST = [
// 	{
// 		name: "product-vendor-code",
// 		id: 's'
// 	},
// 	{
// 		name: 'first-name',
// 		id: 'n'
// 	},
// 	{
// 		name: 'product-barcode',
// 		id: 'a'
// 	}
// ]

// const RADIO_LIST = [
// 	{
// 		title: 'or',
// 		value: "or"
// 	},
// 	{
// 		title: 'and-label',
// 		value: "and"
// 	},
// ]

// const syncIntegrationsCrateValue = (value) => {
// 	if(value && value.length > 1){
// 		return value.map((item) => ({value: item, label: T(SELECT_LIST.find(findItem => findItem.id === item)?.name)}))
// 	} else if(value && value.length === 1){
// 		const split = value[0].split('')
// 		return split.map((item) => ({value: item, label: T(SELECT_LIST.find(findItem => findItem.id === item)?.name)}))
// 	}
// 	return value
// }

// const syncRulesSetting = (value) => {
// 	if(value && value.length > 1){
// 		return 'or'
// 	} else if(value && value.length === 1){
// 		const split = value[0].split('')
// 		if(split.length > 1){
// 			return 'and'
// 		}
// 	}
// 	return false
// }

const SyncIntegration = ({data, onChange}) => {
	// const syncRulesSettingValue = syncRulesSetting(data);
	// const [rulesSetting, setRulesSetting] = useState(syncRulesSettingValue)
	// const values = syncIntegrationsCrateValue(data)
	//
	// const handleChangeSelect = (selectedValue) => {
	// 	if(selectedValue && selectedValue?.length){
	// 		if(rulesSetting === 'and') {
	// 			const ids = selectedValue.map(item => item.value)
	// 			const join = ids.join('')
	// 			onChange([join], {name: 'sync_rules'})
	// 		} else {
	// 			const ids = selectedValue.map(item => item.value)
	// 			onChange(ids, {name: 'sync_rules'})
	// 		}
	// 	} else {
	// 		onChange(false, {name: 'sync_rules'})
	// 	}
	//
	// }

	// const handleChangeRadio = ({value}) => {
	// 	if(values && values.length){
	// 		if(value === 'and') {
	// 			const ids = values.map(item => item.value)
	// 			const join = ids.join('')
	// 			onChange([join], {name: 'sync_rules'})
	// 		} else {
	// 			const ids = values.map(item => item.value)
	// 			onChange(ids, {name: 'sync_rules'})
	// 		}
	// 	}
	//
	// 	setRulesSetting(value)
	// }

	return (
		<>
			{/*<SelectComponents label={<InfoWrapper info='principle-synchronization-works-tip'>{T( 'principle-sync')}</InfoWrapper>} value={values} list={() => SELECT_LIST} onChange={handleChangeSelect} isMulti={true}/>*/}
			{/*{syncRulesSettingValue && <RadioButtonListControl labelComponent={<InfoWrapper info='type-synchronization-works-tip'>{T('synchronization-type')}</InfoWrapper>} list={RADIO_LIST} onChange={handleChangeRadio} valueRadio={rulesSetting} name='rulesSetting'/>}*/}
		</>
	);
};

export default SyncIntegration;