import React, {useEffect, useState} from "react";
import { connectToStores } from "tools/reflux-tools";
import ReportsList from "components/reports-list";
import T from "components/i18n";
import OpenModalButton from "components/modal/open-modal-button";
import ClientsStore from "./clients-store"
import ClientsActions from "./clients-actions";
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import ClientsTotal from "./clients-total";
import PaginationBlock from "components/pagination";
import ReportRowMobile from "./report-row/report-row-mobile";
import ReportRowDesktop from "./report-row/report-row-desktop";
import UserStore from "user/user-store";
import ActionButtons from "./common/action-buttons";
import FilterPanel from "./common/filter-panel";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";
import Button from "react-bulma-components/lib/components/button";

let COLUMNS = [
    "clients-date",
    "clients-name",
    "clients-discount",
    "clients-total_amount",
    "clients-debt",
    "clients-balance",
    "reports-actions"
];

const EmptyMessage = () => {
    return (
      <Box>
          <Heading>{T('not-have-clients')}</Heading>
          <OpenModalButton link="/clients/add-client" text={T('add-clients-btn')} />
      </Box>

    )
}

const Clients = () => {
    const {isMobileSize} = useIsMobile()
  const [isOpenRows, setIsOpenRows] = useState(false)
    useEffect(() => {
        ClientsActions.load();
        ClientsActions.loadDiscounts();

        return () => {
            ClientsStore.clearFilter()
        }
    }, []);

    if (isMobileSize) {
        COLUMNS = [];
    }

    if (!UserStore.getPermissionEnable('show-cashflow')) {
        COLUMNS = COLUMNS.filter(item => item !== "clients-total_amount")
    }

    const listWithoutPage = ClientsStore.getClientsByPages(true)

    return (
      <div className="clients-page">
          {!isMobileSize && <FilterPanel list={listWithoutPage}/>}
          <ClientsTotal clients={ClientsStore.getClients()} />
          <ActionButtons/>
          <Box className="sales-report" style={isMobileSize ? {overflow: 'visible'} : { overflow: "auto" }}>
              {isMobileSize && <FilterPanel list={listWithoutPage}/>}
              {ClientsStore.getClientsByPages() && isMobileSize && (
                <div align="right" style={{ marginBottom: "5px" }}>
                  <Button
                    onClick={() => setIsOpenRows((prev) => !prev)}
                    style={{ fontSize: 10, backgroundColor: "transparent", padding: "3px 10px", lineHeight: 1, margin: 0, height: "auto"}}
                  >
                    {isOpenRows ? T("hide-more-filter") : T("unfold")}
                  </Button>
                </div>
              )}
              <PaginationBlock
                setPage={ClientsActions.setPage}
                totalPage={ClientsStore.get("count_page")}
                currentPage={ClientsStore.get("page")}
              />
              <ReportsList
                load={ClientsActions.load}
                emptyMessage={EmptyMessage}
                columns={COLUMNS}
                hideFooterOnEmpty
                isLoading={ClientsStore.get('isLoaded')}
                rows={ClientsStore.getClientsByPages()}
                sortActions={(value) => ClientsStore.onFilter('sort', value)}
                isSortingColumns={[true, true, true, true, true, true]}
              >

                  {isMobileSize ? <ReportRowMobile role='row' triggerIsOpen={isOpenRows} /> :
                    <ReportRowDesktop role='row'/>}
              </ReportsList>
              <PaginationBlock
                setPage={ClientsActions.setPage}
                totalPage={ClientsStore.get("count_page")}
                currentPage={ClientsStore.get("page")}
              />
          </Box>
      </div>
    )
}

export default connectToStores(Clients, { _: ClientsStore });
