import React from 'react'
import s from './styles.module.scss'
import T from '../../../../components/i18n'

const PhonePresentationItem = ({data}) => {
	return (
		<div className={s.item}>
			{data.type !== 'start' && <img src='/img/hp-black.png' className={s.logo} alt='logo'/>}
			<div className={s.wrapper_img}>
				<img className={s.img} src={data.img} alt={T(data.title)}/>
			</div>
			<div className={s.wrapper_content}>
				{data.title && <p className={s.title}>{T(data.title)}</p>}
				{data.subtitle && <p className={s.subtitle}>{T(data.subtitle)}</p>}
			</div>
		</div>
	);
};

export default PhonePresentationItem;