import React, { useEffect, useState } from "react";
import { Box, Heading } from "react-bulma-components";
import T from "../../../../components/i18n";
import { Input, Label } from "react-bulma-components/lib/components/form";
import ErrorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import SalesActions from "../../sales-actions";
import { errorHandler } from "../../../../tools/error-handler";
import Money from "../../../../components/money";
import DOM from "react-dom-factories";
import SubmitButton from "../../../../components/submit-button";
import Loader from "react-loader-spinner";
import { printElem } from "../../../../tools/print";
import SalesStore from "../../sales-store";
import UserStore from "../../../../user/user-store";
import SelectComponent from "../../../../tools/select-component";
import Button from "react-bulma-components/lib/components/button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import AppStore from "../../../../app-store";
import {createMobileAppLink} from "../../../../tools/create-mobile-app-link";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const PrepaymentFiscalization = ({ oid, amount, accountId, onClose }) => {
  const salesData = SalesStore.getSalesByOid(oid)
  const listPrro = UserStore.getPRROList(oid);

  const {
    handleAutoRemoveError,
    errorMessage,
    setErrorMessage,
    ErrorMessageBlock,
  } = ErrorMessageControlHook();
  const [isWaiting, setIsWaiting] = useState(false);
  const [isCompleted, setIsCompleted] = useState(false);
  const [value, setValue] = useState(`Передпалата за замовлення #${salesData?.order_number || ''}`);
  const [waitingPrint, setWaitingPrint] = useState(false);
  const [pdfCheck, setPdfCheck] = useState("")
  const {isMobileSize} = useIsMobile()

  const [integrationInfo, setIntegrationInfo] = useState(
    listPrro &&
    Array.isArray(listPrro) && {
      value: listPrro[0].id,
      label: listPrro[0].name,
    }
  );

  const handleChange = (evt) => {
    const value = evt.target.value;
    setValue(value);
  };

  useEffect(() => {
    const setToDpsCompl = SalesActions.setToDps.completed.listen((data) => {
      setIsWaiting(false);
      if (data["type"] === "vchasno") {
        setIsCompleted(SalesStore.getConvertPdfToImage(SalesStore.getVchasnoKasaPrintUrl(data["check_id"], "pdf")));
        setPdfCheck(SalesStore.getVchasnoKasaPrintUrl(data["check_id"]));
      } else {
        setIsCompleted(SalesStore.getCheckboxPrintSlip(data["check_id"]));
        setPdfCheck(SalesStore.getCheckboxPrintSlip(data["check_id"], "pdf"))
      }

      const isAutoPrint = UserStore.getMoreSetting("is_auto_fiscal_print");
      if (isAutoPrint) {
        if(data["type"] === "vchasno") {
          handlePrintUrl(SalesStore.getConvertPdfToImage(SalesStore.getVchasnoKasaPrintUrl(data["check_id"], "pdf")))
        } else {
          handlePrintUrl(SalesStore.getCheckboxPrintSlip(data["check_id"]))
        }
      }
    });
    const setToDpsFail = SalesActions.setToDps.failed.listen((res) => {
      const message = errorHandler(res);
      handleAutoRemoveError(message);
      setIsWaiting(false);
    });

    return () => {
      setToDpsCompl();
      setToDpsFail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = () => {
    if (!!value) {
      setIsWaiting(true);
      const getInfoIntegration = UserStore.getIntegrationNameById(integrationInfo.value);
      const type = UserStore.getPRROName(getInfoIntegration.integration_id);
      SalesActions.setToDps(oid, value, type, getInfoIntegration.id);
    } else {
      handleAutoRemoveError("not all fields required");
    }
  };

  const handleChangeSelect = (select) => {
    setIntegrationInfo(select);
  };

  const handlePrintUrl = (url) => {
    printElem(url || isCompleted);
    setWaitingPrint(true)
    setTimeout(() => {setWaitingPrint(false)}, 4000)
  };

  const handleDownload = async (evt) => {
    if(pdfCheck.includes('kasa.vchasno')) {
      if(AppStore.isMobileApp()) {
        createMobileAppLink(`${pdfCheck}.pdf`, evt)
      } else {
        const link = document.createElement('a');
        link.href = `${pdfCheck}.pdf`
        link.target = '_blank';
        link.click();
      }
    } else {
      const response = await fetch(pdfCheck);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = "document.pdf";
      link.click();
      URL.revokeObjectURL(link.href);
    }

  };

  const handleCopy = (evt) => {
    setTimeout(async () => {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(pdfCheck);
        } catch (error) {
          console.log('failed copy')
        }
      } else {
        console.log('failed copy')
      }
    }, 100);
  };

  const handleOpen = (evt) => {
    if(AppStore.isMobileApp()) {
      createMobileAppLink(`${pdfCheck}.pdf`, evt)
    } else {
      const link = document.createElement('a');
      link.href = pdfCheck
      link.target = '_blank';
      link.click();
    }
  }

  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading>
          {T("send-to-dps-prepaid-heading")}{" "}
          <Money aid={accountId} amount={amount} wrapper={DOM.span} />
        </Heading>
        {!isWaiting && !isCompleted && (
          <>
            {!!listPrro.length && listPrro.length > 1 && (
              <SelectComponent
                label='cashier'
                value={integrationInfo}
                onChange={handleChangeSelect}
                list={() => listPrro}
              />
            )}
            <Label>{T("comment")}</Label>
            <Input value={value} onChange={handleChange} />
          </>
        )}
        {isWaiting && (
          <div className="display-flex-center">
            <Loader
              type="Circles"
              className="button_loader"
              color="#000000"
              height={30}
              width={30}
            />
          </div>
        )}
        <div
          className="display-flex-row margin-top-10 flex-wrap"
          style={{ justifyContent: "flex-end" }}
        >
          {isCompleted && <div className='display-flex-row' style={{flex: 1}}>
            <Button className='button_icon' color='info' title={T('copy')} onClick={handleCopy}>
              <FontAwesomeIcon icon='copy' size='1x'/>
            </Button>
            <Button className='button_icon' color='info' title={T('export-pdf')} onClick={handleDownload}>
              <FontAwesomeIcon icon='download' size='1x'/>
            </Button>
            <Button className='button_icon' color='info' title={T('open-print-slip')} onClick={handleOpen}>
              <FontAwesomeIcon icon='folder-open' size='1x'/>
            </Button>
          </div>}
          {isCompleted && (
            <SubmitButton
              fullwidth={false}
              size="medium"
              rounded
              title={T('print-slip-pdf')}
              waiting={waitingPrint}
              onClick={handlePrintUrl}
              color="info"
              text={isMobileSize ? <FontAwesomeIcon icon='print' size='1x'/> : "print-fiscal-slip"}
            />
          )}
          {!isCompleted && (
            <SubmitButton
              size="medium"
              rounded
              disabled={isWaiting}
              text="fiscalize-label"
              fullwidth={false}
              submit={handleSubmit}
              color="success"
            />
          )}
          <SubmitButton
            size="medium"
            rounded
            disabled={isWaiting}
            text="close"
            fullwidth={false}
            submit={onClose}
            color="light"
          />
        </div>
      </Box>
    </>
  );
};

export default PrepaymentFiscalization;
