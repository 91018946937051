import React, {useEffect } from "react";
import { connectToStores } from "tools/reflux-tools";
import OpenModalButton from "components/modal/open-modal-button";
import T from "components/i18n";
import Reports from "components/reports";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import ExpensesActions from "../expenses/expenses-actions";
import InventoryRow from "./inventory-row";
import InventoryStore from "./inventory-store";
import Actions from "./inventory-actions";
import usePaginationHook from "../../tools/pagination-hook";
import {
  useIsMobile
} from "../../components/is-mobile-context/is-mobile-context";
import FilterPanel from "./filter-panel";
import {applyFilter} from "./utils";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";
const COLUMNS = ["info", "status", ""];

const EmptyMessage = () => {
  return (
    <Box align="center">
      <Heading>{T("not-have-inventory")}</Heading>
      <OpenModalButton
        link="/inventory/add-inventory"
        text={T("add-first-inventory-btn")}
      />
    </Box>
  );
};

const Inventory = () => {
  const {isMobileSize} = useIsMobile()
  const inventory = InventoryStore.get('inventory')
  const inventoryWithFilter = inventory?.filter(item => applyFilter(item, InventoryStore.get("_filter"), true))

  const { end, start, page, total, PaginationBlock, setPage } =
    usePaginationHook({ listLength: inventoryWithFilter?.length || 0 });

  useEffect(() => {
    Actions.load();
    ExpensesActions.load();
    const addExpenses = ExpensesActions.addExpenses.completed.listen(() => {
      Actions.load();
    })
    return () => {
      addExpenses()
      InventoryStore.clearFilter()
    }
  }, []);
  return (
    <>
      {!isMobileSize && <FilterPanel/>}
      <div className="display-flex-row-gap margin-bottom-10 top-button flex-wrap margin-08">
        <DocumentationButton sectionID='inventory'/>
        <OpenModalButton
          link="/inventory/add-inventory"
          text={T("create-inventory")}
          icon="plus-circle"
          size={isMobileSize ? "small" : "medium"}
        />
      </div>
      <Box className="inventory-report">
        <PaginationBlock
          setPage={setPage}
          totalPage={total}
          currentPage={page}
        />
        <Reports
          emptyMessage={EmptyMessage}
          columns={COLUMNS}
          hideFooterOnEmpty
          rows={inventoryWithFilter?.slice(start, end)}
          isLoading={InventoryStore.get('isLoading')}
        >
          <InventoryRow role="row" />
        </Reports>
        <PaginationBlock
          setPage={setPage}
          totalPage={total}
          currentPage={page}
        />
      </Box>
    </>
  );
};

export default connectToStores(Inventory, { _: InventoryStore });
