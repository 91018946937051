import React, { useEffect, useState } from "react";
import Box from "react-bulma-components/lib/components/box";
import Columns from "react-bulma-components/lib/components/columns";
import SelectComponent from "../../../tools/select-component";
import ExpensesStore from "../../expenses/expenses-store";
import ExpensesActions from "../../expenses/expenses-actions";
import {Heading, Icon, List} from "react-bulma-components";
import { generateOrderName, getClient, getPrice, getDate } from "./utils";
import T from "../../../components/i18n";
import Money from "../../../components/money";
import DOM from "react-dom-factories";
import Button from "react-bulma-components/lib/components/button";
import SubmitButton from "../../../components/submit-button";
import errorMessageControlHook from "../../../components/error-message-block/error-message-control.hook";
import { errorHandler } from "../../../tools/error-handler";
import SalesActions from "../sales-actions";
import SelectStore from "../select-store";
import {
  Checkbox,
  Control,
  Field,
  Label,
} from "react-bulma-components/lib/components/form";
import NumberInput from "../../../components/number-input/number-input";
import User from "../../../user/user-store";
import SalesStore from "../sales-store";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import UserStore from "../../../user/user-store";

const MassCodModal = ({ massList, isNotCod, onClose }) => {
  const {isMobileSize} = useIsMobile()
  const listAccount = ExpensesStore.getAccountWithoutTypesForSelect([1, 4], User.get("currency_id"));
  const list = massList.reduce((acc, curr) => {
    const findAccount = listAccount.find((item) => {
      if (item.id) {
        return item?.id !== curr.account_id;
      }
      return null;
    });
      acc[curr.oid] = {
        from_aid: {
          value: curr.account_id,
          label: ExpensesStore.getAccountNameForSelectById(curr.account_id, true),
        },
        to_aid: findAccount ? { value: findAccount?.id, label: findAccount.name } : {value: null},
        amount: curr.amount_sale - curr.prepaid_amount,
        comment: "",
        oid: curr.oid,
      };

    return acc;
  }, {});

  const {
    errorMessage,
    ErrorMessageBlock,
    handleAutoRemoveError,
    setErrorMessage,
  } = errorMessageControlHook();
  const [currentList, setCurrentList] = useState(list);
  const [amountState, setAmountState] = useState({});
  const [waiting, setWaiting] = useState(false);
  const [massSelect, setMassSelect] = useState({
    value: 0,
    label: T("select-account"),
  });
  const [uniqueAccount, setUniqueAccount] = useState([]);
  const [commissionAccount, setCommissionAccount] = useState({});
  const [valueSelect, setValueSelect] = useState({})
  const [splitCommission, setSplitCommission] = useState({})


  const handleChangeCommission = ({ target: { name, value } }) => {
    if(splitCommission[name]) {
      const calcCommission = calcSplitCommission(name, value);
      if(calcCommission) {
        const data = {}
        calcCommission.forEach(item => {
          data[item.oid] = item.commissionValue;
        })

        setSplitCommission(prev => ({
          ...prev,
          [name]: {
            ...data
          }
        }));
      }
    }
    setCommissionAccount((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangeSelect = (select, name, oid) => {
    setCurrentList((prev) => {
      const oldValue = prev[oid].to_aid.value

      const data = {
        ...prev,
        [oid]: {
          ...prev[oid],
          [name]: select,
        }
      }

      if(valueSelect[oldValue] === 'fix') {
        const calcCommission = calcSplitCommission(oldValue, false, data)

        setSplitCommission(prev => ({
          ...prev,
          [oldValue]: calcCommission.reduce((acc, curr) => {
            acc[curr.oid] = curr.commissionValue;
            return acc
          }, {}),
        }))
      } else if(valueSelect[select.value] === "fix") {
        const calcCommission = calcSplitCommission(select.value, false, data)

        setSplitCommission(prev => ({
          ...prev,
          [select.value]: calcCommission.reduce((acc, curr) => {
            acc[curr.oid] = curr.commissionValue;
            return acc
          }, {}),
        }))
      }

      return data
    });
  };

  const massChangeToAccount = (select) => {
    if (select?.value !== 0) {
      setMassSelect(select);
      setCurrentList((prev) => {
        const keysPrev = Object.keys(prev);
        const copyPrev = JSON.parse(JSON.stringify(prev));
        keysPrev.forEach((item) => (copyPrev[item].to_aid = select));
        return copyPrev;
      });
    }
  };

  const handleCalcCommission = (data) => {
    return Object.values(data).reduce(
      (acc, cur) => {
        let amount_from = cur.amount;
        let amount_to = cur.amount;

        const from_account = ExpensesStore.getAccount(
          cur?.from_aid?.value || cur?.from_aid
        );
        const commission_from = from_account["commission_from"];

        if (commission_from > 0) {
          amount_from =
            parseFloat(amount_from) + (amount_from / 100) * commission_from;
        }

        const to_account = ExpensesStore.getAccount(
          cur?.to_aid?.value || cur?.to_aid
        );
        let commission_to =  to_account["commission_to"];

        if(commissionAccount[cur?.to_aid?.value || cur?.to_aid] || commissionAccount[cur?.to_aid?.value || cur?.to_aid] === '') {
          commission_to = commissionAccount[cur?.to_aid?.value || cur?.to_aid]
        }

        if (commission_to && commission_to > 0 && valueSelect[cur?.to_aid?.value || cur?.to_aid] !== 'fix') {
          amount_to = amount_to - (amount_to / 100) * (commission_to || 0);
        } else if(valueSelect[cur?.to_aid?.value || cur?.to_aid] === 'fix' && splitCommission[cur?.to_aid?.value]) {
          acc.fixCommissionByAccount[cur?.to_aid?.value || cur?.to_aid] = Object.values(splitCommission[cur?.to_aid?.value]).reduce((acc, cur) => {
            acc += parseFloat(cur || 0)
            return acc;
          },0)
        } else if(valueSelect[cur?.to_aid?.value || cur?.to_aid] === 'fix' && commission_to) {
          if(acc.fixCommissionByAccount[cur?.to_aid?.value || cur?.to_aid]) {
            acc.fixCommissionByAccount[cur?.to_aid?.value || cur?.to_aid] += parseFloat(commission_to)
          } else {
            acc.fixCommissionByAccount[cur?.to_aid?.value || cur?.to_aid] = parseFloat(commission_to)
          }
            if(acc.maxInputByAccount[cur?.to_aid?.value || cur?.to_aid]) {
              acc.maxInputByAccount[cur?.to_aid?.value || cur?.to_aid] += parseFloat(amount_to)
            } else {
              acc.maxInputByAccount[cur?.to_aid?.value || cur?.to_aid] = parseFloat(amount_to)
            }

        }

        acc.amount_from_all_sum += amount_from;
        acc.amount_to_all_sum += amount_to;
        acc.amount += cur.amount;

        return acc;
      },
      {
        amount_from_all_sum: 0,
        amount_to_all_sum: 0,
        amount: 0,
        fixCommissionByAccount: {},
        maxInputByAccount: {}
      }
    );
  };

  useEffect(() => {
    setAmountState(handleCalcCommission(currentList));
    const uniqueAcc = Object.values(currentList).reduce((acc, cur) => {
      if (!acc.includes(cur.to_aid.value)) {
        acc.push(cur.to_aid.value);
      }
      return acc;
    }, []);

    setUniqueAccount(uniqueAcc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentList, commissionAccount, valueSelect, splitCommission]);

  const handleSubmit = () => {
    setWaiting(true);
    const data = Object.values(currentList).map(
      ({ from_aid, to_aid, amount, comment, oid }) => {

        const data = {
          from_aid: from_aid.value,
          to_aid: to_aid.value,
          amount,
          comment,
          oid,
        }

        if(commissionAccount[to_aid.value]) {
            if(valueSelect[to_aid.value] === 'fix') {
              if(splitCommission[to_aid.value]) {
                data.commission = -splitCommission[to_aid.value][oid];
              } else {
                data.commission = -commissionAccount[to_aid.value];
              }
            } else {
              data.commission = commissionAccount[to_aid.value];
            }
        } else {
          const getAccount = ExpensesStore.getAccount(to_aid.value);
            if(getAccount && getAccount.commission_to) {
              data.commission = getAccount.commission_to;
              if(valueSelect[to_aid.value] === 'fix') {
                if(splitCommission[to_aid.value]) {
                  data.commission = -splitCommission[to_aid.value][oid];
                } else {
                  data.commission = -getAccount.commission_to;
                }
              } else {
                data.commission = getAccount.commission_to;
              }
            }
        }

        return data
      }
    );
    ExpensesActions.transferFunds(
      false,
      false,
      false,
      false,
      false,
      false,
      data,
    );
  };

  useEffect(() => {
    const transferFundsComp = ExpensesActions.transferFunds.completed.listen(
      () => {
        if(!isNotCod) {
          SalesActions.finishParcel(null, Object.keys(currentList),'cash_on_delivery');
        } else {
          Object.values(currentList).forEach((item) => {
            SalesStore.setAccountInSale(item.oid, item.to_aid.value)
          })
        }
        SelectStore.clearSelectGroup();
        onClose();
      }
    );

    const transferFundFail = ExpensesActions.transferFunds.failed.listen(
      (res) => {
        const message = errorHandler(res);
        setWaiting(false);
        handleAutoRemoveError(message);
      }
    );
    return () => {
      transferFundsComp();
      transferFundFail();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentList]);

  const handleChangeSelectValue = (evt, item) => {
    const value = evt.target.value;
    setCommissionAccount((prev) => ({ ...prev, [item]: 0 }));
    setValueSelect(prev => ({ ...prev, [item]: value }));
  }

  const calcSplitCommission = (account, commissionValue, currentListValue) => {
    const getAccount = ExpensesStore.getAccount(account);
    let commission = commissionAccount[account] === '' ? '' : commissionAccount[account] || getAccount.commission_to

    if(commissionValue) {
      commission = commissionValue
    }

    if(commission) {
      const valuesCurrentList = Object.values(currentListValue || currentList || {}).filter(item => parseInt(item.to_aid.value) === parseInt(account))
      const totalAmount = valuesCurrentList.reduce((sum, item) => sum + parseFloat(item.amount), 0);
      const calcCommission = valuesCurrentList.map(item => {
        const percentage = (item.amount / totalAmount) * 100;
        const commissionValue = parseFloat(((percentage * commission) / 100).toFixed(2));

        return {
          oid: item.oid,
          commissionValue
        };
      });

      const totalCommissionValue = calcCommission.reduce((sum, item) => sum + item.commissionValue, 0);
      const difference = parseFloat((commission - totalCommissionValue).toFixed(2));
      if (difference !== 0) {
        calcCommission[calcCommission.length - 1].commissionValue = parseFloat(
          (calcCommission[calcCommission.length - 1].commissionValue + difference).toFixed(2)
        );
      }

      return calcCommission
    }
    return false;
  }

  const handleChangeIsSplitCommission = ({target: {checked}}, account) => {
    setSplitCommission(prev => {
      const data = {}
      if(checked) {
        const calcCommission = calcSplitCommission(account)
        if(calcCommission) {
          calcCommission.forEach(item => {
            data[item.oid] = item.commissionValue;
          })
        }
      }
      return {
        ...prev,
        [account]: checked ? data : false,
      }
    })
  }

  const handleChangeSplitCommission = ({target: {value}},account, name) => {
    setSplitCommission(prev => {
      const data = {
        ...prev,
        [account]: {
          ...prev[account],
          [name]: value
        }
      }
      const sumCommission = Object.values(data[account]).reduce((sum, cur) => sum + parseFloat(cur || 0),0)
      setCommissionAccount((prev) => ({ ...prev, [account]: sumCommission }));
      return data
    });
  }


  return (
    <>
      <ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
      <Box>
        <Heading size={4}>
          {T("amount-transfer")}:{" "}
          <Money amount={amountState.amount} wrapper={DOM.span} />
        </Heading>
        <Heading subtitle className="has-text-danger" size={isMobileSize ? 6 : 5}>
          {T("count-edit-sale", { count: massList.length })}
        </Heading>
        <div className="margin-bottom-10">
          <SelectComponent
            name="to_aid"
            label="change-mass-account"
            epmty="select-account"
            onChange={massChangeToAccount}
            value={massSelect}
            list={() => listAccount}
            creatable={false}
          />
        </div>
        <List style={{ overflow: "auto", maxHeight: "40vh" }}>
          {massList.map((item) => {
            return <List.Item key={item.oid}>
              <Columns>
                <Columns.Column style={{minWidth: 160, maxWidth: 160}}>
                  <span>
                    <b>{generateOrderName(item)}</b>
                    &nbsp;
                    <br/>
                    <b>{getPrice(item)}</b>
                    <br/>
                    {getDate(item)}
                  </span>
                  <br/>
                  {getClient(item)}
                </Columns.Column>

                <Columns.Column>
                  <SelectComponent
                    name="from_aid"
                    label="from-account"
                    onChange={(select, {name}) =>
                      handleChangeSelect(select, name, item.oid)
                    }
                    value={currentList[item.oid].from_aid || false}
                    readOnly={true}
                    list={() => ExpensesStore.getAccountsList(false, false, false, true)}
                    creatable={false}
                  />
                </Columns.Column>
                <Columns.Column>
                  <SelectComponent
                    name="to_aid"
                    label="to-account"
                    onChange={(select, {name}) =>
                      handleChangeSelect(select, name, item.oid)
                    }
                    value={currentList[item.oid].to_aid}
                    list={() => listAccount}
                    creatable={false}
                  />
                </Columns.Column>
                {splitCommission[currentList[item.oid].to_aid.value] && valueSelect[currentList[item.oid].to_aid.value] === 'fix' &&
                  <Columns.Column>
                    <Field>
                      <Label>{T('commission')}</Label>
                      <Control iconRight>
                        <NumberInput
                          size='large'
                          max={item.amount}
                          name={`commission-${item.oid}`}
                          value={splitCommission[currentList[item.oid].to_aid.value][item.oid] || ""}
                          onChange={(evt) => handleChangeSplitCommission(evt, currentList[item.oid].to_aid.value, item.oid)}
                        />
                        <Icon align="right">
                          <span>{UserStore.getCurrency()}</span>
                        </Icon>
                      </Control>
                    </Field>
                  </Columns.Column>}
              </Columns>
            </List.Item>
          })}
        </List>
        {uniqueAccount.map((item) => {
          const getAccount = ExpensesStore.getAccount(item);
          return (
            getAccount && (
              <React.Fragment key={item}>
                <Field>
                  <Label>
                    {T("transfer-fund-commissin-to")}: {getAccount.name}
                  </Label>
                  <Control className='display-flex-row'>
                    <NumberInput
                      name={`${item}`}
                      max={valueSelect[item] === 'fix' ? amountState.maxInputByAccount[item] : 100}
                      value={commissionAccount[item] === '' ? '' : commissionAccount[item] || getAccount.commission_to}
                      onChange={handleChangeCommission}
                    />
                    <select onChange={(evt) => handleChangeSelectValue(evt, item)}
                            value={valueSelect[item]}
                            style={{height: 40, width: 52, borderRadius: 12}}>
                      <option value='%'>
                        %
                      </option>
                      <option value='fix'>
                        {T('fix')}
                      </option>
                    </select>
                  </Control>
                </Field>
                {valueSelect[item] === 'fix' && (
                  <Field>
                    <Checkbox onChange={(evt) => handleChangeIsSplitCommission(evt, item)}>{T('split-account-fee')}</Checkbox>
                  </Field>
                )}
              </React.Fragment>
            )
          );
        })}
        <Box>
          <div>
            {T("transfer-fund-amount-from")}:{" "}
            <Money
              amount={amountState.amount_from_all_sum}
              wrapper={DOM.span}
            />
          </div>
          <div>
            {T("transfer-fund-finish-amount")}:{" "}
            <Money amount={amountState.amount_to_all_sum - Object.values(amountState?.fixCommissionByAccount || {}).reduce((acc,cur) => acc+cur,0)} wrapper={DOM.span}/>
          </div>
        </Box>
        <Button.Group position="right">
          <SubmitButton
            text="move-money-btn"
            size="large"
            submit={handleSubmit}
            fullwidth={false}
            waiting={waiting}
          />
          <Button color="light" rounded onClick={onClose} size="large">
            {T("close")}
          </Button>
        </Button.Group>
      </Box>
    </>
  );
};

export default MassCodModal;
