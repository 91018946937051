import React, {useState, forwardRef} from 'react'
import {
	Checkbox,
} from "react-bulma-components/lib/components/form";
import Image from "react-bulma-components/lib/components/image";
import s from "./styles.module.scss";
import T from "../i18n";
import User from "../../user/user-store";
import TypeProductTag from "../type-product-tag/type-product-tag";
import ExpirationDateTag from "../expiration-date-tag/expiration-date-tag";
import ProductsStore from "../../modules/products/products-store";
import WarehouseStore from "../../modules/warehouse/warehouse-store";
import UserStore from "../../user/user-store";
import { LocalIcon } from "whitelables/wl-name/icons";



const SelectItemProduct = forwardRef(({onClick, name, id,
														 type_product, stock, brand, skus,
														 image, units, selected=[],
														 setIsOpenMenu, single,withoutMid, setSearch, activeIndex, index, onMouseEnter, setInteractionMethod,
													 }, ref) => {
	const stockList = Object.values(stock);

	const infoWarehouse = stockList.reduce((acc, cur) => {
		acc[cur.marketplace_id] = {
			marketplace_id: cur.marketplace_id,
		};

		acc[cur.marketplace_id].price = `${cur.price || 0} ${UserStore.getCurrency()}`;

		acc[cur.marketplace_id].count = ProductsStore.getStockUnits(cur['instock'], units)

		if (WarehouseStore.isDropshipping(cur.marketplace_id) || ProductsStore.isServicesProduct(id)) {
			acc[cur.marketplace_id].count = ProductsStore.getStockUnits('∞', units)
		}

		if (WarehouseStore.isNeedName()) {
			acc[cur.marketplace_id].warehouseName = T(WarehouseStore.getWarehouseName(cur.marketplace_id));
		}

		return acc
	}, {})

	const someIsOpenChildren = Object.values(stockList).some(item => selected.includes(`${id}_${item.marketplace_id}`) || false)

	const [isOpen, setIsOpen] = useState(someIsOpenChildren)

	const img =
		"/bimages/get/34-" +
		User.get("id") +
		"-" +
		id +
		"-0.jpg";

	const services = <TypeProductTag typeProduct={type_product}
																	 short/>
	const exp_date = <ExpirationDateTag pid={id} short/>


	const brandName = brand ? `${brand}.` : ""
	const skusName = skus ? `${skus}.` : ""

	const firstItemWarehouseInfo = Object.values(infoWarehouse)[0];

	const handleOpenWarehouse = (evt) => {
		evt.stopPropagation();
		evt.preventDefault()
		setIsOpen(prev => !prev);
	}

	const handleSubmit = (evt, mid, disabledOpen) => {
		evt.stopPropagation();
		let need_deleted = false
		if(!evt.target.checked && evt.target.type === "checkbox") {
			need_deleted = true
		}

		if(!disabledOpen) {
			setIsOpenMenu(false);
			setSearch('')
		}
		onClick([{ label: name, value: `${id}_${mid}`}], need_deleted)
	}
	return (
		<>
			<div
				ref={ref}
				onMouseMove={() => setInteractionMethod("mouse")}
				onMouseEnter={onMouseEnter}
				className={`${s.select_item_product} ${activeIndex === index ? s.active_element : ''}`}
				onClick={stockList.length > 1 ? handleOpenWarehouse : (evt) => handleSubmit(evt,firstItemWarehouseInfo.marketplace_id)}
				title={name}
			>
				{stockList.length > 1  && <>
					<LocalIcon icon='arrow-left' style={{width: 13, height: 13, padding: 5, transform: isOpen ? 'rotate(270deg)' :'rotate(90deg)'}}/>
					<Image src={img} size={32} style={image ? {} : {height: 'auto', minWidth: 32}}/>
					<div className='display-flex-column'>
						<div className={s.title}>
							{exp_date} {services} {id}. {brandName} {name}. {skusName}
						</div>
					</div>
				</>}
				{stockList.length === 1 && <>
					{!single && <Checkbox
						style={{margin: 0, padding: 5, display: 'flex'}}
						name="pr"
						onClick={(evt) => evt.stopPropagation()}
						onChange={(evt) => handleSubmit(evt, firstItemWarehouseInfo.marketplace_id, true)}
						checked={selected.includes(withoutMid ? id : `${id}_${firstItemWarehouseInfo.marketplace_id}`) || false}
					/>}
					<Image src={img} size={32} style={image ? {} : {height: 'auto', minWidth: 32}}/>
					<div className='display-flex-column'>
						<div className={s.title}>
							{exp_date} {services} {id}. {brandName} {name}. {skusName}
						</div>
						<b>{!withoutMid && <>{firstItemWarehouseInfo.warehouseName}. {firstItemWarehouseInfo.count}.</>} {UserStore.getPermissionEnable('salesprice') && firstItemWarehouseInfo.price}</b>
					</div>
				</>}
			</div>
			{isOpen && stockList.length > 1 && <>
				{Object.values(infoWarehouse).map(item => <div key={`${id}-item-${item.marketplace_id}`} className={`${s.select_item_product} ${s.sub_item}`} onClick={(evt) => handleSubmit(evt,item.marketplace_id)}>
					{!single && <Checkbox
						style={{margin: 0, padding: 5, display: 'flex'}}
						name="pr"
						onClick={(evt) => evt.stopPropagation()}
						onChange={(evt) => handleSubmit(evt, item.marketplace_id, true)}
						checked={selected.includes(`${id}_${item.marketplace_id}`) || false}
					/>}
					<b>{item.warehouseName}. {item.count}. {UserStore.getPermissionEnable('salesprice') && item.price}</b></div>
				)}
			</>
			}
		</>)
});

export default SelectItemProduct;