import React, { useState } from "react";
import { Box, Button, Heading } from "react-bulma-components";
import T from "../../../../../components/i18n";
import SocialInput from "./social-input";
import ClientInput from "../client-input";
import { LocalIcon } from "whitelables/wl-name/icons";
import AdditionalFieldsNotDefault from "../additional-fields-not-default";
import AppStore from "../../../../../app-store";

const AdditionalInfoClient = ({ socials, socialChange, state, onChangeDate, disabledBox, inputsNotDefault, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleAddSocial = (name) => {
    if (socialChange) {
      const newDataSocials = { ...socials };

      if (newDataSocials[name]) {
        newDataSocials[name].value.push("");
      }
      socialChange(newDataSocials);
    }
  };

  const handleDeleteSocial = (name, index) => {
    if (socialChange) {
      const newDataSocials = { ...socials };

      if (newDataSocials[name]) {
        newDataSocials[name].value.splice(index, 1);
      }
      socialChange(newDataSocials);
    }
  };

	const handleChangeValue = (name, index, value) => {
		if (socialChange) {
			const newDataSocials = { ...socials };

			if (newDataSocials[name]) {
				newDataSocials[name].value[index] = value;
			}
			socialChange(newDataSocials);
		}
	}

	const socialsData = Object.entries(socials)

	const handleChangeDate = (value, name) => {
		if(value) {
			onChangeDate(value, name);
		}
	}

	const Wrapper = disabledBox ? 'div' : Box;

	return (
    <Wrapper style={{margin: '10px 0'}}>
      <div className="display-flex-row-gap ">
				<div className="display-flex-row">
					<Heading style={{ margin: 0 }}>{T("more-info")}</Heading>
					<Button className='button-link-icon' onClick={() => AppStore.openModal(<AdditionalFieldsNotDefault/>)}>
						<LocalIcon icon="gear" size="small" />
					</Button>
				</div>
        <Button
          color="warning"
          size="small"
          onClick={() => setIsOpen((prev) => !prev)}
          rounded
        >
          {isOpen ? T("hide-more-filter") : T("unfold")}
        </Button>
      </div>
      {isOpen && (
        <div
          className="margin-top-10"
          style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 10 }}
        >
					{inputsNotDefault.map(item =>  <ClientInput key={item.name} withPrefix item={item} value={state[item.name]} onChange={onChange} onChangeDate={handleChangeDate}/>)}
					{socialsData.map(([key, social]) => {
            return (
              <div key={key} className="display-flex-column" style={{ gap: 5 }}>
                {social.value.map((item, index) => (
                  <SocialInput
										is_global={social.is_global}
                    key={`${key}-${index}`}
                    name={key}
                    label={social.label}
                    value={item}
                    index={index}
										type={social.type}
										info={social.info}
                    disabledDelete={social.value.length > 1}
										onChangeValue={handleChangeValue}
										onDeleteSocial={() => handleDeleteSocial(key, index)}
                  />
                ))}
                <div align='right' style={{ paddingRight: 6 }}>
                  <Button
                    size="small"
                    className="button-link-without-style"
                    rounded
                    onClick={() => handleAddSocial(key)}
                  >
                    {T("add-field")}
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
			{/*<div>*/}
			{/*		<Label size='large'>{T('consumer-key')}</Label>*/}
			{/*	<div className='display-flex-row'>*/}
			{/*		<Input value={customField} onChange={handleChangeCustomField}/>*/}
			{/*		{!!customField &&<FontAwesomeIcon icon='plus-circle' className="text-success cursor-pointer" size='2x'*/}
			{/*											onClick={handleAddCustomField}/>}*/}
			{/*	</div>*/}
			{/*</div>*/}
    </Wrapper>
  );
};

export default AdditionalInfoClient;
