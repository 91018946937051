import React from "react";
import {
  Control,
  Field,
  Input,
  Label,
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";

const AddFieldList = ({ list, values, onChange }) => {
  return (
    !!list &&
    !!list?.length && (
      <>
        <Label size="large">{T("additional-fields")}</Label>
        {list?.map((name, index) => (
          <Field key={`ad-f-${name}-${index}`}>
            <Control>
              <Label>{name}</Label>
              <Input
                size="large"
                name={name}
                type="text"
                autoComplete="nope"
                onChange={onChange}
                value={values[name]}
              />
            </Control>
          </Field>
        ))}
      </>
    )
  );
};

export default AddFieldList;
