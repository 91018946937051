import React, {useEffect, useState} from 'react'
import Box from "react-bulma-components/lib/components/box";
import CatalogTabs from "./catalog-tabs";
import {useParams} from "react-router-dom";
import AddExpensesCategoryModal
	from "../../expenses/add-expenses-category-modal";
import AddChannelModal from "../add-channel-modal";
import AddWriteOffCategoryModal
	from "../../write-off/modal/add-write-off-category";
import AddClientsTagModal
	from "../../clients/clients-tags/add-clients-tag-modal";
import AddSupplierModal from "../../shipments/add-supplier-modal";
import CatalogList from "./catalog-list/catalog-list";
import ExpensesStore from "../../expenses/expenses-store";
import SalesStore from "../../sales/sales-store";
import WriteOffStore from "../../write-off/write-off-store";
import ShipmentsStore from "../../shipments/shipments-store";
import ClientsStore from "../../clients/clients-store";
import Columns from "react-bulma-components/lib/components/columns";
import AddStatusModal from "../../sales/add-status-modal";
import AddAdditionalField from "../modal/add-additional-field";
import UserStore from "../../../user/user-store";
import ProductsActions from "../../products/products-actions";
import OrdersActions from "../../orders/orders-actions";
import WriteOffActions from "../../write-off/write-off-actions";
import ClientsActions from "../../clients/clients-actions";
import {connectToStores} from "../../../tools/reflux-tools";
import ErrorMessageBlock
	from "../../../components/error-message-block/error-message-block";
import T from '../../../components/i18n'
import UserActions from "../../../user/user-actions";
import OrdersStore from 'modules/orders/orders-store';
import AddProductionCategory
	from "../../production/modal/add-production-category";
import ProductionStore from "../../production/production-store";
import ProductionActions from "../../production/production-actions";
import ChatsActions from "../../chats/chats-actions";
import AddChatsStatus from "../../chats/modal/add-chats-status";
import ChatsStore from "../../chats/chats-store";
import AddMakePayment from "../terminal/modal/add-make-payment";

const DATA_LISTS = [
	{
		column: 1,
		name: 'additional-fields',
		modal: (data, additionalSettings) => <AddAdditionalField {...data} {...additionalSettings}/>,
		btnText: 'add-additional-field-btn',
		additionalSettings: {
			disabledRadio: true
		},
		listItems: UserStore.getAdditionalFieldsLists,
		isMulti: true,
		filter: {
			name: 'additional-fields',
			column: 1
		},
		onDelete: ({type, id}) => UserStore.deleteAdditionalFieldByName(type, id)
	},
	{
		column: 1,
		name: 'make-the-payment',
		modal: (data) => <AddMakePayment {...data}/>,
		btnText: 'add',
		listItems: UserStore.getMakePaymentList,
		filter: {
			name: 'make-the-payment',
			column: 2
		},
		onDelete: ({id}) => UserStore.deleteMakePaymentList(id)
	},
	{
		column: 2,
		name: 'production-category',
		modal: (data) => <AddProductionCategory modal='production-category' {...data}/>,
		btnText: 'add-production-category',
		listItems: ProductionStore.getFormattingCategoryList,
		filter: {
			name: 'products',
			column: 1
		},
		onDelete:({id}) => ProductionActions.deleteCategory(id)
	},
	{
		column: 1,
		name: 'payments-category',
		modal: (data, additionalSettings) => <AddExpensesCategoryModal modal="add-expenses-category" {...data} {...additionalSettings}/>,
		btnText: 'add-category-btn-label',
		listItems: ExpensesStore.getAllCategory,
		additionalSettings: {
			isEdit: true
		},
		filter: {
			name: 'Payments',
			column: 1
		},
		onDelete: ({type, id}) => ProductsActions.deleteCatalog(type, id)
	},
	{
		column: 1,
		name: 'chats-status',
		modal: (data, additionalSettings) => <AddChatsStatus {...data} {...additionalSettings}/>,
		btnText: 'add-status',
		listItems: ChatsStore.getChatsStatus,
		additionalSettings: {
			isEdit: true
		},
		filter: {
			name: 'other_receipt',
			column: 1
		},
		onDelete: ({id}) => ChatsActions.deleteChatStatus(id)
	},
	{
		column: 2,
		name: 'sales-channels',
		modal: (data) => <AddChannelModal {...data}/>,
		btnText: 'add-sales-channel-btn-label',
		listItems: SalesStore.getChannelsList,
		filter: {
			name: 'sales',
			column: 1
		},
		onDelete: ({type, id}) => ProductsActions.deleteCatalog(type, id)
	},
	{
		column: 3,
		name: 'statuses',
		modal: (data) => <AddStatusModal {...data} notDisabledFinish/>,
		btnText: 'add-status',
		listItems: OrdersStore.getStatusesListsForCatalog,
		isMulti: true,
		filter: {
			name: 'sales',
			column: 2
		},
		onDelete: ({id}) => OrdersActions.deleteStatus(id)
	},
	{
		column: 3,
		name: 'write-off-categories',
		modal: (data, additionalSettings) => <AddWriteOffCategoryModal {...data} {...additionalSettings}/>,
		additionalSettings: {
			isEdit: true
		},
		btnText: 'add-category-btn-label',
		listItems: WriteOffStore.getCategoryList,
		filter: {
			name: 'products',
			column: 2
		},
		onDelete: ({id}) => WriteOffActions.deleteCategory({id})
	},
	{
		column: 3,
		name: 'clients-tags-label',
		modal: (data, additionalSettings) => <AddClientsTagModal {...data} {...additionalSettings}/>,
		btnText: 'add-clients-tags',
		listItems: ClientsStore.getClientTagList,
		additionalSettings: {
			isEdit: true
		},
		filter: {
			name: 'clients',
			column: 1
		},
		onDelete: ({id}) => ClientsActions.deleteClientsTag({tid: id})
	},
	{
		column: 3,
		name: 'suppliers',
		modal: (data) => <AddSupplierModal {...data}/>,
		btnText: 'add-supplier-btn-label',
		listItems: ShipmentsStore.getSuppliersList,
		filter: {
			name: 'products',
			column: 3
		},
		onDelete: ({type, id}) => ProductsActions.deleteCatalog(type, id)
	},
];

const Catalog = () => {
	const {catalogID} = useParams()
	const [errorMessage, setErrorMessage] = useState(null)

	const handleAutoRemoveMessage = (message) => {
		setErrorMessage(T(message))
		setTimeout(() => setErrorMessage(null) ,4000)
	}
	const renderWithFilter = ({filter, item, column}) => {
		if(filter) {
			return item?.filter?.name === filter && item?.filter?.column === column
		}
		return item.column === column
	}

	useEffect(() => {
		const productCatalogDelFail = ProductsActions.deleteCatalog.failed.listen(() => {
			handleAutoRemoveMessage('delete-error')
		})
		const writeOffDelFail = WriteOffActions.deleteCategory.failed.listen(() => {
			handleAutoRemoveMessage('delete-error')
		})
		const clientTagDelFail = ClientsActions.deleteClientsTag.failed.listen(() => {
			handleAutoRemoveMessage('delete-error')
		})
		const orderStatusDelFail = OrdersActions.deleteStatus.failed.listen(() => {
			handleAutoRemoveMessage('delete-error')
		})
		const addFieldDelFail = UserActions.updateSettings.failed.listen(() => {
			handleAutoRemoveMessage('delete-error')
		})
		return () => {
			productCatalogDelFail()
			writeOffDelFail()
			clientTagDelFail()
			orderStatusDelFail()
			addFieldDelFail()
		}
	}, []);
	return (
			<Box>
				<CatalogTabs/>
				<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
				<Columns className="catalog-list margin-top-10">
					<Columns.Column>
						{DATA_LISTS.map(item => (
							renderWithFilter({filter: catalogID, item, column: 1}) && (
								<CatalogList key={item.name} {...item} onError={handleAutoRemoveMessage}/>)))}
					</Columns.Column>
					<Columns.Column>
						{DATA_LISTS.map(item => (
							renderWithFilter({filter: catalogID, item, column: 2}) && (
								<CatalogList key={item.name} {...item} onError={handleAutoRemoveMessage}/>)))}
					</Columns.Column>
					<Columns.Column>
						{DATA_LISTS.map(item => (
							renderWithFilter({filter: catalogID, item, column: 3}) && (
								<CatalogList key={item.name} {...item} onError={handleAutoRemoveMessage}/>)))}
					</Columns.Column>
				</Columns>
			</Box>
	);
};

export default connectToStores(Catalog, {
	write_off: WriteOffStore,
	client: ClientsStore,
	sales: SalesStore,
	expenses: ExpensesStore,
	production: ProductionStore,
	chat: ChatsStore
});