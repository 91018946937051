import React from 'react';
import T from "components/i18n";
import DOM from "react-dom-factories";
import SubmitButton from 'components/submit-button';
import Messages from 'components/messages';
import { connectToStores } from "tools/reflux-tools";
import {
  Field,
  Control,
  Textarea,
  Input,
  Label, Checkbox,
} from 'react-bulma-components/lib/components/form';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';
import Icon from 'react-bulma-components/lib/components/icon';
import List from 'react-bulma-components/lib/components/list';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import Media from 'react-bulma-components/lib/components/media';
import Content from 'react-bulma-components/lib/components/content';
import Money from 'components/money';
import ProductionStore from './production-store'
import Actions from './production-actions'
import ProductsStore from '../products/products-store'
import WarehouseStore from '../warehouse/warehouse-store'
import ExpensesStore from '../expenses/expenses-store'
import ExpensesActions from '../expenses/expenses-actions'
import SelectComponent from 'tools/select-component'
import User from 'user/user-store';
import "react-datepicker/dist/react-datepicker.css";
import BaseForm from 'components/modules/base-form';
import AppStore from "../../app-store";
import AddExpensesCategoryModal from "../expenses/add-expenses-category-modal";
import ProductsActions from "../products/products-actions";
import AddProductionCategory from "./modal/add-production-category";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import ViewProductModal
  from "../products/modal/view-product/view-product-modal";
import NumberInput from "../../components/number-input/number-input";
import UserStore from "user/user-store";
import CustomPriceDropdown from "../sales/common/custom-price-dropdown";
import ProductSelect from "../../components/Select/product-select";

class AddProduction extends BaseForm {
    constructor(props) {
        super(props);
            
        let product_data = {},
            close = false;

        const data = JSON.parse(JSON.stringify(props.data || "{}")),
              step = props.step || 0;

        const items = data['items'] || {};
        if (Object.keys(items).length > 0) {
          for (let key in items) {
            const pr = items[key],
                  pid = pr['product_id'];
            let data_info = ProductsStore.getProductByMid(pid, pr.marketplace_id);
            if (data_info) {
              data_info['quantity'] = pr['quantity'];
              let netprice = pr?.amount
              if(!!data['real_netprice']) {
                const getProduct = ProductsStore.getProductByMid(pr.id, pr.mid)
                netprice = getProduct.netprice
              }
              product_data[pid] = this.formattingProductInfo(data_info, pr.marketplace_id, netprice);
            } else {
              product_data[pid] = this.formattingProductInfoRemoveProduct(pid);
            }
          }
        }

        this.product_data_list = [];

        for (let i in items) {
          this.product_data_list.push(items[i]['product_id']);
        }

        let result_product = ProductsStore.getProductByMid(data.result_product_id, data.result_marketplace_id);

        if (result_product) {
          result_product = this.formattingProductInfo(result_product, data.result_marketplace_id);
        }

        const count_production = 1;
        const result = this.calcSaleAmount(product_data, count_production),
              expenses = this.calcExpenses(data.lines, count_production);
        let lines = [];
        for (let i in data['lines']) {
          let dd = data['lines'][i];
          if (dd['expenses_category_id']) {
            dd['expenses_category_id'] = {'value': dd['expenses_category_id']}
          }
          lines.push(dd)
        }
        this.state = {
          date: new Date(),
          id: props.copy ? null : data.id || null,
          status: data.status || 'pending',
          name: data.name || '',
          comment: data.comment || '',
          category: data.category_id ? {value: data.category_id, label: ProductionStore.getCategoryName(data.category_id, true)} : false,
          product_data: product_data, 
          count_production: count_production, 
          products_select: data.select_product || [],
          product_result: result_product || null,
          amount: result['amount'] || 0, //amount after received items
          quantity: result['count'] || 0, //quantity items after received items
          expenses: expenses, //amount expenses after received items
          total: result['amount'] + expenses,
          step: step,
          production: step === 2,
          lines: lines || [{'name': '', 'amount': 0, 'expenses_category_id': null}],
          close: close,
          waiting: null,
          message: null,
          refresh: 0,
          real_netprice: !!data['real_netprice'] || false
        };


        this.item_length = 0;
        this.onChange = this.onChange.bind(this);
        this.onChangeCount = this.onChangeCount.bind(this);
        this.onChangeProductData = this.onChangeProductData.bind(this);
        this.onChangeSelectProduct = this.onChangeSelectProduct.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        this.renderStepFinish = this.renderStepFinish.bind(this);
        this.renderStepProductions = this.renderStepProductions.bind(this);
        this.submit = this.submit.bind(this);
        this.deleteProductionItem = this.deleteProductionItem.bind(this);
        this.onAddExpenses = this.onAddExpenses.bind(this);
        this.onChangeNetPrice = this.onChangeNetPrice.bind(this);
        this.onChangeCheckbox = this.onChangeCheckbox.bind(this);

        this.listenAdd = null;
    }

    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }

      if (typeof(this.listenAddFailed) == "function") {
        this.listenAddFailed();
      }
      if (typeof(this.listenGetList) == "function") {
        this.listenGetList();
      }
    }

    componentDidMount() {
        const {select_product} = this.props;

        if (select_product) {
            this.onChangeSelectProduct(select_product);
        }

        ProductsStore.closeModal();
        
        this.listenAdd = Actions.add.completed.listen((data) => {
            console.log("!!!! LISTEN createProduction"); 
            this.setState({
              "waiting": false,
              "step": 1
            });
        });

        this.listenGetList = ProductsActions.getImagesList.completed.listen(data => {
          this.setState(prev =>({
            refresh: prev.refresh + 1
          }))
        })

        this.listenAddFailed = Actions.add.failed.listen((res) => {
            console.log("!!!! LISTEN Failed Production", res); 
            let data = {'error': 'internal-service-error'};
            
            if (res && typeof(res['response']) !== "undefined") {
              data = JSON.parse(res['response']);
            }
            
            this.setState({
              "waiting": false,
              "message": T(data['error']),
            });
        });
    }

    deleteProductionItem(pid) {
      let product_data = this.state.product_data;
      // const state = this.state;

      if (typeof(product_data[pid]) != "undefined") {
        delete product_data[pid];

        let new_list = []
        for (let i in this.product_data_list) {
            if (parseInt(this.product_data_list[i]) !== parseInt(pid)) {
              new_list.push(this.product_data_list[i]);
            }
        }

        this.product_data_list = new_list;

        this.updateData(product_data);

        // Actions.delete({
        //         'mov_id': this.state.id, 'pid': pid, 'type': 'item'
        //   });

        // let data = {
        //     "pid": pid,
        //     "sid": state.id
        // }          
      }
    }

    submit() {
        const state = this.state;        

        if (this.product_data_list.length === 0 && (state.lines.length === 0 || state.lines[0]['amount'] === 0)) {
          this.setState({
            'message': T('select-min-one-item-production-or-expenses')
          });
          return false;
        }
        if (!state.product_result) {
          this.setState({
            'message': T('select-result-production')
          });
          return false;
        }

        if (state.expenses > 0 && (!state.account || state.account.value === 0) && state.step === 2) {
          this.setState({
              'message':  T('not all fields required')
          })

          return false;
        }

        let lines = [];
        for (let i in state.lines) {
          let dd = state.lines[i];
          if (!dd['expenses_category_id'] || dd['name'] === "" || !dd['amount'] || dd['amount'] === 0) {
              this.setState({
                  'message':  T('not all fields required')
              })

              return false;
          }

          lines.push(dd)
        }

        let send_product = []
        for (let i in this.product_data_list) {
          let pid = this.product_data_list[i];
          send_product.push(state.product_data[pid]);
        }

        let data = {
            "name": state.name,
            "comment": state.comment,
            "products": send_product,
            "lines": lines,
            "step": state.step,
            "total": state.total,
            "cp": state.count_production,
            "result_mid": state.product_result['mid'],
            "result_pid": state.product_result['id'],
            "real_netprice": state.real_netprice,
        }

        if (state.id) {
          data['id'] = this.state.id;
        }

        if (state.account) {
          data['account_id'] = state.account.value;
        }
        if(state.category.value) {
          data['category_id'] = this.state.category.value;
        }

        this.setState({"waiting": true});
        Actions.add(data);

        // this.listenAdd = Actions.add.completed.listen(() => {
        //     this.setState({"step": 1});
        // });
    }

    calcTotal(product, expenses) {
      this.setState({'total': product + expenses})
    }

    onChangeCheckbox({target: {name, checked}}) {
      if(checked) {
        const product_data = this.state.product_data;
        for (let i in this.product_data_list) {
          const pid = this.product_data_list[i];
          let product = product_data[pid];
          if (product) {
            const getProduct = ProductsStore.getProductByMid(product.id, product.mid);
            product.netprice = getProduct.netprice
          }
        }
        this.updateData(product_data)
      }

      this.setState({
        [name]: checked
      })
    }

    updateData(product_data) {
        const result = this.calcSaleAmount(product_data);

        this.setState({
            'product_data': product_data,
            'amount': result['amount'],
            'quantity': result['count']
        });

        this.calcTotal(result['amount'], this.state.expenses);
    }

    calcSaleAmount(products, count_production) {
      let amount = 0;
      let count = 0;
      for (let i in products) {
        const prod = products[i],
              count_item =  parseFloat(prod['quantity']);
        amount += parseFloat(prod['netprice']*count_item);
        count += count_item;
      }

      if (!count_production) {
        count_production= this.state.count_production;
      } 

      amount *= count_production;
      count *= count_production ;

      return {amount, count};
    }

    formattingProductInfo(data, mid, netprice) {
      let name = data['name'],
          instock = data['instock'];

      if (typeof(data['stock']) !== "undefined" && mid &&
        typeof(data['stock'][mid]) !== "undefined") {
        instock = data['stock'][mid]['instock'];
      }



      return {
                'id': data['id'],
                'name': name,
                'brand': data['brand'] || "",
                'netprice': netprice || data['netprice'] || 0,
                'raw_net_price': data['raw_net_price'] || 0,
                'mid': data['mid'] || 0,
                'quantity': data['quantity'] || 0,
                'sku': data['sku'],
                'units': data['units'],
                'asin': data['asin'],
                'instock': instock || 0,
                'wid': data['wid']
              }
    }

    formattingProductInfoRemoveProduct(pid) {
      return {
            'id': pid,
            'remove': true,
            'name': T('product-remove'),
            'netprice': 0,
            'brand': "",
            'retail_price': 0,
            'finish_price': 0,
            'raw_net_price':  0,
            'quantity': 0,
            'units': "",
            'sku': "",
            'asin': "",
            'instock': "",
            'wid': ""
          }
    }

    onChangeSelectProduct(selectedOption, need_delete) {
      let product_data = this.state.product_data;
      for (let p in selectedOption) {
          let pid = selectedOption[p]['value'].split("_")[0],
              mid = selectedOption[p]['value'].split("_")[1],
              data = ProductsStore.getProductByMid(pid, mid);
          if (data) {
              if (need_delete) {
                this.deleteProductionItem(pid);
              } else {
                  if (data['instock'] === 0 && this.state.step > 0) {
                    this.setState({
                      "message": T('not-have-stock')
                    })
                    continue;
                  }

                  if (typeof(product_data[pid]) != "undefined") {
                    product_data[pid]['quantity'] += 1;
                  } else {
                    let product_info = this.formattingProductInfo(data, mid);
                    this.product_data_list.unshift(pid)
                    product_info['quantity'] = 1; 
                    product_data[pid] = product_info;
                  }
            }
          }
      } 

      this.updateData(product_data);
    }

    onChangeSelectProductResult(selectedOption) {
        for (let p in selectedOption) {
          let pid = selectedOption[p]['value'].split("_")[0],
              mid = selectedOption[p]['value'].split("_")[1],
              data = ProductsStore.getProductByMid(pid, mid);

            if (data) {
              let product_info = this.formattingProductInfo(data, mid);
              this.setState({
                product_result: product_info
              })
            }
        }
    }

    onChange(evt) {
        const value = evt.target.value,
            name = evt.target.name;

        this.setState({
            [name]: value
        });
    }

    updateProductionCount(evt) {
      let cpr = this.checkVal(evt.target.value);

      const count_production = (cpr || 1),
            result = this.calcSaleAmount(this.state.product_data, count_production),
            expenses = this.calcExpenses(this.state.lines, count_production);

        this.setState({
          amount: result['amount'],
          quantity: result['count'],
          count_production: cpr,
          expenses: expenses,
          total: result['amount'] + expenses,
        })
    }

    calcExpenses(lines, count_production) {
      let total = 0;
        for (let j in lines) {
           let pr = lines[j]['amount'];
           total += isNaN(parseFloat(pr)) ? 0 : parseFloat(pr);
        }

      if (!count_production) {
        count_production = this.state.count_production
      }
      return total*count_production;
    }

    onChangeExpensesSelect(selectedOption, actionMeta, i) {
        let lines = this.state.lines;
        lines[i][actionMeta.name] = selectedOption;
        this.setState({
          'lines': lines,
        })
    }

    onChangeExpenses(evt, i) {
        const value = evt.target.value,
              name = evt.target.name;

        // let button = T('change');

        let lines = this.state.lines;
        lines[i][name] = name === "amount" ? this.checkVal(value) : value;

        let total = this.calcExpenses(lines);
        this.setState({
          'lines': lines,
          'expenses': total
        });

        this.calcTotal(this.state.amount, total);
    }

    onChangeProductData(evt, name) {
      const value = evt.target.value,
            pid = evt.target.name.split("-")[1];

        let product_data = this.state.product_data;

        product_data[pid][name] = value;

        this.updateData(product_data)
    }


    onChangeCount(evt) {
        const value = evt.target.value,
            pid = evt.target.name.split("-")[2];

        let product_data = this.state.product_data;
        product_data[pid]['quantity'] = this.checkVal(value) || 0;
        this.updateData(product_data)
    }

    getProductName(prod) {
      const pid = prod.id;

      let name = "";

      if (prod.asin && prod.asin !== "") {
          name += prod.asin+". ";
      }

      if (prod.sku  && prod.sku !== "") {
          name += prod.sku+". ";
      }

      if (prod.brand && prod.brand !== "") {
          name += prod.brand+". ";
      }

      name += prod.name;

      return  ( 
          <span>ID: {pid}. {name}</span>
      );
      
    }

    productLine(prod) {
      if (this.state.step === 2) {
        return this.props.isMobileSize ? this.productLineProductionMobile(prod) : this.productLineProduction(prod)
      }

      return this.props.isMobileSize ? this.productLineMobile(prod) : this.productLineDesktop(prod);
    }
    
    productLineMobile(prod) { 
        const pid = prod.id,
              name = this.getProductName(prod);
        return (
          <List.Item key={pid} className="font-size-80">
                <Heading size={6} className="has-text-info">
                  <Button className='button-link-without-style' onClick={() => AppStore.openModal(<ViewProductModal modal='view-product' pid={pid} />)}>
                    {name}
                  </Button>
                </Heading>

                <Columns className="is-mobile">
                  <Columns.Column>
                <Field horizontal>
                        <Label>{T('In-stock')}:</Label>
                        <b>{prod.instock} {T('items-p')}</b>
                 </Field>
                 </Columns.Column>
                 <Columns.Column>
                <Field horizontal>
                      <Label>{T('quantity')}:</Label>
                      <Control iconRight>
                          <Input
                              key={"count-pid-"+pid} 
                              name={"count-pid-"+pid} 
                              type="text" 
                              className="for-slim-icon"
                              min="1"
                              onChange={this.onChangeCount} 
                              value={this.state.product_data[pid]['quantity']}
                              />
                              <Icon align="right" className="for-slim-icon ">
                                  <span className="small-text">{T('items-p')}</span>
                              </Icon>
                          </Control>
                </Field>
                </Columns.Column>
                </Columns>
                <Columns className="is-mobile">
                  <Columns.Column>
                    <Field horizontal>
                      <Label>{T('netcost')}:</Label>
                      <div className='display-flex-row'>
                        <Control iconRight>
                          <NumberInput disabled={this.state.real_netprice} className="for-slim-icon" value={this.state.product_data[pid]['netprice']} onChange={(evt) => this.onChangeNetPrice(pid, evt)}/>
                          <Icon align="right" className="for-slim-icon">
                            <span>{UserStore.getCurrency()}</span>
                          </Icon>
                        </Control>
                        {!this.state.real_netprice && <CustomPriceDropdown positionCenter
                                              pid_mid={`${pid}_${prod['mid']}`}
                                              withNetPrice disabledDiscount
                                              onChangeCost={(evt) => this.onChangeNetPrice(pid, evt)}/>}
                      </div>
                    </Field>
                  </Columns.Column>
                  <Columns.Column>
                    <Field horizontal>
                      <Label>{T('cost-of-sales')}:</Label>
                      <Money amount={this.state.product_data[pid]['netprice'] * (this.state.product_data[pid]['quantity'] || 1)} />
                    </Field>
                  </Columns.Column>
                </Columns>
                <div>
                      <Link to="#" onClick={() => this.deleteProductionItem(pid)} title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size="1x" /> {T('delete-from-list')}
                      </Link>
                </div>
          </List.Item>
        )

    }

    onChangeNetPrice(pid, {target: {value}}) {
      const productData = this.state.product_data

      productData[pid]['netprice'] = value;

      this.updateData(productData)
    }

    productLineDesktop(prod) { 
        let name = prod.name;
        const pid = prod.id;

        let warehouse = ""
        if (WarehouseStore.getWarehouseList().length > 1) {
          warehouse = (
            <div>
                <span className='silver'> {T("history-data-marketplace_id")}</span>:  {T(WarehouseStore.getWarehouseName(prod['mid']))}
            </div>
            );
        }

        if (!WarehouseStore.isDropshipping(prod['mid'])) {
          name += " ("+T('available-small')+" "+prod['instock']+" "+ProductsStore.getUnits(this.state.product_data[pid]['units'])+")";
        }

        const netcost = <div><span className='silver'>{T('netcost')}: </span> <Money wrapper={DOM.span} amount={this.state.product_data[pid]['netprice']} /></div>

        return (
          <List.Item key={pid}>
              <Columns style={{alignItems: 'center'}} className={this.props.isMobileSize ? "is-mobile" : null}>
                <Columns.Column size="two-fifths">
                  <Media>
                    <Media.Item>
                        <Content>
                          <Button className='button-link-without-style' onClick={() => AppStore.openModal(<ViewProductModal modal='view-product' pid={pid} />)}>
                            {name}
                          </Button>
                          {warehouse}
                          {netcost}
                        </Content>
                    </Media.Item>
                  </Media>
                </Columns.Column>
                <Columns.Column>
                  <Control iconRight>
                    <Input  size="small" 
                        key={"count-pid-"+pid} 
                        name={"count-pid-"+pid} 
                        type="text" 
                        min="0"
                        onChange={this.onChangeCount} 
                        value={this.state.product_data[pid]['quantity']}
                        />
                        <Icon align="right">
                        <span>{ProductsStore.getUnits(this.state.product_data[pid]['units'])}</span>
                      </Icon>
                    </Control>
                </Columns.Column>
                <Columns.Column className='display-flex-row'>
                  <Control iconRight>
                    <NumberInput disabled={this.state.real_netprice} size='small' value={this.state.product_data[pid]['netprice']} onChange={(evt) => this.onChangeNetPrice(pid, evt)}/>
                    <Icon align="right">
                      <span>{UserStore.getCurrency()}</span>
                    </Icon>
                  </Control>
                  {!this.state.real_netprice && <CustomPriceDropdown pid_mid={`${pid}_${prod['mid']}`}
                                        withNetPrice disabledDiscount
                                        onChangeCost={(evt) => this.onChangeNetPrice(pid, evt)}/>}
                </Columns.Column>
                <Columns.Column>
                    <Heading size={6}><Money amount={this.state.product_data[pid]['netprice'] * (this.state.product_data[pid]['quantity'] || 1)} /></Heading>
                </Columns.Column>
                <Columns.Column className="delete-on-bundle">
                      <Link to="#" onClick={() => this.deleteProductionItem(pid)} title={T('delete')}>
                        <FontAwesomeIcon icon="trash-alt" size="1x" />
                      </Link>
                    </Columns.Column>
              </Columns>
          </List.Item>
        )
    }

    productLineResult(prod) { 
        let name = prod.name;
        const pid = prod.id;

        let warehouse = ""
        if (WarehouseStore.getWarehouseList().length > 1) {
          warehouse = (
            <div>
                <span className='silver'> {T("history-data-marketplace_id")}</span>:  {T(WarehouseStore.getWarehouseName(prod['mid']))}
            </div>
            );
        }

        if (!WarehouseStore.isDropshipping(prod['mid'])) {
          name += " ("+T('available-small')+" "+prod['instock']+" "+ProductsStore.getUnits(prod['units'])+")";
        }

        return (
          <List.Item key={pid}>
              <Columns style={{alignItems: 'center'}} className='is-mobile'>
                <Columns.Column>
                  <Media>
                    <Media.Item style={{alignItems: 'center'}}>
                        <Content>
                          <Button className='button-link-without-style' onClick={() => AppStore.openModal(<ViewProductModal modal='view-product' pid={pid} />)}>
                            {name}
                          </Button>
                          {warehouse}
                        </Content>
                    </Media.Item>
                  </Media>
                </Columns.Column>
                {this.state.step === 2 ?
                <Columns.Column style={{maxWidth: '40%'}}>
                  <Field>
                    <Label>{T('count-production')}</Label>
                    <Control iconRight>
                    <Input  size="medium" 
                        key={"count-production"} 
                        name={"count_production"} 
                        type="text" 
                        onChange={(e)=>{this.updateProductionCount(e)}}
                        value={this.state.count_production}
                        />
                        <Icon align="right">
                        <span>{ProductsStore.getUnits(prod['units'])}</span>
                      </Icon>
                    </Control>
                  </Field>
                </Columns.Column>
                 : 
                <Columns.Column className="delete-on-bundle" style={{width: 'fit-content'}}>
                      <Link to="#" onClick={() => this.setState({'product_result': null})} title={T('delete')}>
                          <FontAwesomeIcon icon="trash-alt" size="1x" />
                      </Link>
                </Columns.Column>
                }
              </Columns>
          </List.Item>
        )
    }


    getProductionList() {
      const sp = this.state.product_data;

      let production_list = [],
         empty_list = (
            <List.Item key="s-1" className="text-align-center"><span  className="has-text-danger ">{T('select-min-one-item-production')}</span></List.Item>
          );

      for (let i in this.product_data_list) {
          const pid = this.product_data_list[i];
          let product = sp[pid];
          if (product) {
            production_list.push(this.productLine(product));
          } 
      }

      this.item_length = production_list.length || 0;

      return (
          <List hoverable className="production-order-list">
              {production_list.length > 0 ? production_list : empty_list}
          </List>

        );
    } 

    getExpensesList() {
      let response = [];


      for (let i in this.state.lines) {
        response.push(this.state.step === 2 ? this.getProductionLine(i) : this.getLine(i));
      }

      return (
          <List hoverable className="production-expenses-list">
              {response.length > 0 ? response : ''}
          </List>
        );
    }

    onAddExpenses({id, label}, index) {
      this.onChangeExpensesSelect({value: id, label}, {name: 'expenses_category_id'}, index)
    }

    getLine(i) {
      return (
          <List.Item key={i}>
            <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
              <Columns.Column>
                  <Field>
                     <SelectComponent 
                          name="expenses_category_id"
                          onChange={(evt, sel) => {this.onChangeExpensesSelect(evt, sel, i)}}
                          value={this.state.lines[i]['expenses_category_id']} 
                          list={() => ExpensesStore.getCategoryWithProfit(2)}
                          addItem={(value) => AppStore.openModal(<AddExpensesCategoryModal {...value} isDisabledReceipt defaultValue="0" is_profit='2' disabledProfit onSubmit={(data) => this.onAddExpenses(data, i)}/>)}
                          addItemModal={() => AppStore.openModal(<AddExpensesCategoryModal isDisabledReceipt defaultValue="0" is_profit='2' disabledProfit onSubmit={(data) => this.onAddExpenses(data, i)}/>)}
                          load={ExpensesActions.loadCategory}
                          creatable={true}/>
                  </Field>   
              </Columns.Column>
              <Columns.Column>
                  <Field>
                      <Control >
                        <Input  size="large" 
                              name="name"
                              type="text"
                              placeholder={T('expenses-name')}
                              onChange={(evt) => {this.onChangeExpenses(evt, i)}} 
                              value={this.state.lines[i]['name']}
                              />
                      </Control>
                </Field>
              </Columns.Column>
              <Columns.Column size="one-fifth">
                 <Field>
                      <Control iconRight>
                        <Input  size="large" 
                              name="amount"
                              type="text"
                              placeholder={T("netprice-price")}
                              onChange={(evt) => {this.onChangeExpenses(evt, i)}} 
                              value={this.state.lines[i]['amount']}
                              />
                          <Icon align="right">
                            <span className="small-text">{User.getCurrency()}</span>
                          </Icon>
                      </Control>
                </Field>
              </Columns.Column>
              <Columns.Column align="center" className="delete-on-sale margin-top-10">
                  <Link to="#" onClick={() => this.deleteLine(i)} title={T('delete')}>
                      <FontAwesomeIcon icon="trash-alt" size="1x" />
                  </Link>
              </Columns.Column>
            </Columns>
          </List.Item>
        );

    } 

    getProductionLine(i) {
      return (
          <List.Item key={i}>
            <Columns className={this.props.isMobileSize ? "is-mobile" : ""}>
              <Columns.Column>
                  {ExpensesStore.getCategoryName(this.state.lines[i]['expenses_category_id']['value'])}
              </Columns.Column>
              <Columns.Column>
                  {this.state.lines[i]['name']}
              </Columns.Column>
              <Columns.Column size="one-fifth">
                 <Money amount={this.state.lines[i]['amount']} />
              </Columns.Column>
              <Columns.Column size="one-fifth">
                <Money amount={this.state.lines[i]['amount'] * this.state.count_production} />
              </Columns.Column>
            </Columns>
          </List.Item>
        );

    }

    deleteLine(i) {
      let lines = this.state.lines;

      delete lines[i];

      lines = lines.filter(n => n);

      this.setState({
        'lines': lines
      });
    }


    addLine() {
      let lines = this.state.lines;

      lines.push({
        'name': '',
        'amount': 0
      })

      this.setState({
        'lines': lines
      });
    }


    productLineProductionMobile(prod) { 
      let name = prod.name;
        const pid = prod.id;


        let warehouse = ""
        if (WarehouseStore.getWarehouseList().length > 1) {
          warehouse = (
            <div>
                <span className='silver'> {T("mid")}</span>:  {T(WarehouseStore.getWarehouseName(prod['mid']))}
            </div>
            );
        }

        name += " ("+T('available-small')+" "+prod['instock']+" "+ProductsStore.getUnits(this.state.product_data[pid]['units'])+")";

        const netcost = <div><span className='silver'>{T('netcost')}: </span> <Money wrapper={DOM.span} amount={this.state.product_data[pid]['netprice']} /></div>

        let netprice = prod['netprice']

        if(this.state.real_netprice) {
          const getProduct = ProductsStore.getProductByMid(prod.id, prod.mid)
          netprice = getProduct.netprice
        }

        return (
          <List.Item key={pid} className={(prod['quantity'] * this.state.count_production) > prod['instock'] ? 'channal_color_8' : '' }>
              <Columns style={{alignItems: 'center'}} className={this.props.isMobileSize ? "is-mobile" : null}>
                <Columns.Column size="two-fifths">
                  <Media>
                      <Media.Item>
                          <Content>
                            <Button className='button-link-without-style' onClick={() => AppStore.openModal(<ViewProductModal modal='view-product' pid={pid} />)}>
                              {name}
                            </Button>
                            {warehouse}
                            {netcost}
                          </Content>
                    </Media.Item>
                  </Media>
                </Columns.Column>
                <Columns.Column>
                  {prod['quantity']} {ProductsStore.getUnits(prod['units'])}
                </Columns.Column>
                <Columns.Column>
                    <Money amount={prod['quantity'] * netprice} />
                </Columns.Column>
                <Columns.Column >
                  <Money amount={prod['quantity'] * netprice * this.state.count_production} />
                </Columns.Column>
              </Columns>
          </List.Item>
        )
    }

    productLineProduction(prod) { 
        let name = prod.name;
        const pid = prod.id;


        let warehouse = ""
        if (WarehouseStore.getWarehouseList().length > 1) {
          warehouse = (
            <div>
                <span className='silver'> {T("mid")}</span>:  {T(WarehouseStore.getWarehouseName(prod['mid']))}
            </div>
            );
        }
        let instock = prod['instock']
        let isServicesIfDrop = ProductsStore.isServicesProduct(pid) || WarehouseStore.isDropshipping(prod['mid'])

        if(isServicesIfDrop) {
          instock = "∞"
        }

        name += " ("+T('available-small')+" "+instock+" "+ProductsStore.getUnits(this.state.product_data[pid]['units'])+")";

        const netcost = <div><span className='silver'>{T('netcost')}: </span> <Money wrapper={DOM.span} amount={this.state.product_data[pid]['netprice']} /></div>

        let netprice = prod['netprice']

        if(this.state.real_netprice) {
          const getProduct = ProductsStore.getProductByMid(prod.id, prod.mid)
          netprice = getProduct.netprice
        }

        return (
          <List.Item key={pid} className={(prod['quantity'] * this.state.count_production)  > prod['instock'] && !isServicesIfDrop ? 'channal_color_8' : '' }>
              <Columns style={{alignItems: 'center'}} className={this.props.isMobileSize ? "is-mobile" : null}>
                <Columns.Column size="two-fifths">
                  <Media>
                    <Media.Item>
                        <Content>
                          <Button className='button-link-without-style' onClick={() => AppStore.openModal(<ViewProductModal modal='view-product' pid={pid} />)}>
                            {name}
                          </Button>
                          {warehouse}
                          {netcost}
                        </Content>
                  </Media.Item>
                  </Media>
                </Columns.Column>
                <Columns.Column>
                  {prod['quantity']} {ProductsStore.getUnits(prod['units'])}
                </Columns.Column>
                <Columns.Column >
                  <Money amount={prod['quantity'] * netprice} />
                </Columns.Column>
                <Columns.Column >
                  <Money amount={prod['quantity'] * netprice * this.state.count_production} />
                </Columns.Column>
              </Columns>
          </List.Item>
        )
    }


   
    renderStepOne() {
      const {onClose, copy} = this.props;
      return (
          <Box>
            {copy && <Heading>{T('copy-label')}</Heading>}
          <ProductSelect
              zIndex={33}
              onChange={this.onChangeSelectProduct}
              disabledProductById={this.state.product_result?.id}
              disabledKeyStore
              source="production-add"
              selected={
                this.product_data_list?.map(item => {
                  const mid = this.state.product_data[item].mid
                  return `${item}_${mid}`
                })
              }
            />
            {this.getProductionList()}

            <Field>
              <Checkbox name='real_netprice' onChange={this.onChangeCheckbox} checked={this.state.real_netprice}>{T('use-netprice')}</Checkbox>
            </Field>

            <Label>{T('expenses-productions')}</Label>
            {this.getExpensesList()}

            <div align="right">
              <Button size="small"  rounded color="primary"
                  onClick={() => this.addLine()}
                  >{T('add-expenses-btn')}</Button>
            </div>
          
            <Field>
                  <Control>
                    <Label>{T('name-templates')}</Label>
                    <Input  size="large" 
                          name='name' 
                          type="text" 
                          onChange={(e) => this.onChange(e)} 
                          value={this.state.name}
                          />
                  </Control>
            </Field>
            <Label>{T('production-category')}</Label>
            <SelectComponent
              name='category'
              onChange={this.onChangeSelect}
              value={this.state.category}
              list={ProductionStore.getFormattingCategoryList}
              placeholder='production-category'
              addItem={({ name }) => AppStore.openModal(<AddProductionCategory modal='production-category' defaultValue={name}/>)}
              addItemModal={() => AppStore.openModal(<AddProductionCategory modal='production-category'/>)}
              creatable={true}
            />
            <ProductSelect
              onChange={(e, i) => this.onChangeSelectProductResult(e, i)}
              disabledProductById={Object.keys(this.state.product_data || {})}
              source="production"
              single={true}
              disabledKeyStore
              selected={
                this.product_data_list?.map(item => {
                  const mid = this.state.product_data[item].mid
                  return `${item}_${mid}`
                })
              }
            />
            <List>
              {this.state.product_result ? this.productLineResult(this.state.product_result) : null}
            </List>
            {this.renderInfoBlock()}
            <Field>
              <Control>
                <Label>{T('comment')}</Label>
                <Textarea 
                      name="comment" 
                      type="text" 
                      rows={2}
                      placeholder={T('production-comment-placeholder')}
                      onChange={this.onChange} 
                      value={this.state.comment}
                      />
              </Control>
            </Field>  

            <Button.Group position="centered">
              <SubmitButton text={this.state.id ? 'edit-production' : 'create-production'} fullwidth={false}  size="large"  color="primary" waiting={this.state.waiting} submit={this.submit}/>
              <Button  size="large" rounded color="light"
                onClick={onClose}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    renderStepProductions() {
      const {onClose} = this.props;
      return (
          <Box>
            <Heading>{this.state.name}</Heading>
            <p>{this.state.comment}</p>
            <Label>{T('accessories')}</Label>
            {this.getProductionList()}

            <Label>{T('expenses-productions')}</Label>
            {this.getExpensesList()}

            <Label>{T('result-production')}</Label>
            <List>
                {this.state.product_result && this.productLineResult(this.state.product_result)}
            </List>

            {!!this.state.category.value &&
              <span className='margin-bottom- 10'><b>{T('production-category')}: </b>{ProductionStore.getCategoryName(this.state.category.value)}</span>}
            {this.renderInfoBlock()}

            <Columns>
              <Columns.Column size="two-thirds">
                  <Field>
                      <SelectComponent 
                                name="account"
                                label="production-debit-the-account"
                                onChange={this.onChangeSelect} 
                                value={this.state.account} 
                                list={() => ExpensesStore.getPersonalAccounts()}
                                load={ExpensesActions.loadAccounts}
                                creatable={false}/>
                    </Field>
                 </Columns.Column>
                  <Columns.Column  align="center" >
                      <Heading><Money className="has-text-danger" amount={this.state.expenses} /></Heading>
                  </Columns.Column>
            </Columns>
            <Button.Group position="centered">
              <SubmitButton text={'produce-goods'} fullwidth={false}  size="large"  color="primary" waiting={this.state.waiting} submit={this.submit}/>
              <Button  size="large" rounded color="light"
                onClick={onClose}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }


    renderStepFinish() {
      const {onClose} = this.props;
      return (
        <Box>
          <h1 className="title">{T(!this.state.production ? 'production-created' : 'production-finished')}</h1>
          <Button.Group>
              <Button fullwidth size="large"  rounded color="primary"
                onClick={onClose}
                >{T('close')}</Button>
            </Button.Group>
        </Box>
      );
    }

    renderInfoBlock() {
      return (
          <Box className="margin-top-10">
              <Heading>{T('production-count-item-added', {count: this.state.count_production})}: <Money amount={this.state.total} wrapper={DOM.span}/></Heading>
              <Columns>
                <Columns.Column>
                      <div><b>{T('conponent-on-amount')}:</b> <Money amount={this.state.amount}  wrapper={DOM.span}/>  </div>
                      <div><b>{T('quantity')}: {this.state.quantity} {T('items-e')} | {this.item_length} {T('items-short')}</b></div>
                </Columns.Column>
                <Columns.Column>
                      <div><b>{T('expenses-on-amount')}:</b> <Money amount={this.state.expenses}  wrapper={DOM.span}/> </div>
                </Columns.Column>
              </Columns>
          </Box>
        )
    }

    render() { 
        const stepNaviganor = [this.renderStepOne, this.renderStepFinish, this.renderStepProductions],
              currentStep = stepNaviganor[this.state.step];
        return (
              <div>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                {currentStep()}
              </div>
        );
    }

}

//<Heading>Оформление поставки товара</Heading>

export default connectToStores(withIsMobileSize(AddProduction), {
    products: ProductsStore,
    production: ProductionStore,
    expenses: ExpensesStore
  });
