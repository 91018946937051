import React, {useState} from "react";
import EmployeesFilter from "./employees-filter";
import UserStore from "../../../user/user-store";
import Box from "react-bulma-components/lib/components/box";
import Reports from "../../../components/reports";
import T from "../../../components/i18n";
import EmployeesRowDesktop from "./employees-row-desktop";
import SalesStore from "../../sales/sales-store";
import EmployeesRowMobile from "./employees-row-mobile";
import { calcStatisticsEmployees } from "./utils";
import {filterSearch} from "../../../tools/filters-helper";
import StatisticsBlock from "../../../components/statistics-block";
import Money from "../../../components/money";
import InfoWrapper from "../../../components/info-wrapper";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import DynamicTabList
  from "../../../components/dynamic-tab-list/dynamic-tab-list";

const emptyMessage = () => {
  return (
    <Box>
      <h2>{T("not-have-history")}</h2>
    </Box>
  );
};

const Employees = ({defaultTab,onChangeTab,listTabs}) => {
  const {isMobileSize} = useIsMobile()
  const [searchInput, setSearchInput] = useState('')

  const getSubUsers = UserStore.getSubUsersDataList(true, true);
  const getSales = SalesStore.getSales();

  const employeesDataWithStatistics = calcStatisticsEmployees(
    getSubUsers,
    getSales
  );

  let COLUMNS = [
    "",
    "client-name",
    "saled-on",
    'dashboard-sales-profit',
    <InfoWrapper info={T('report-total-sales')}>{T("total-sales")}</InfoWrapper>,
    "sold-units",
    "num-days",
    "accruals",
  ];


  const handleChangeSearchInput = ({value}) => {
    setSearchInput(value)
  }

  const filterWithSearchList = employeesDataWithStatistics.filter(item => {
    return filterSearch(searchInput, ['name', 'phone', 'email'], item)
  })

  const calcSumStatistics = filterWithSearchList.reduce((acc, cur) => {
    acc.sum_amount += cur.sales_amount
    acc.sum_sales_total += cur.sales_total
    acc.sum_accruals += cur.sumAccrual
    return acc
  }, {
    sum_amount: 0,
    sum_sales_total: 0,
    sum_accruals: 0
  })

  const listStatistics = [
    { value: <Money amount={calcSumStatistics.sum_amount} />, name: "saled-on" },
    { value: calcSumStatistics.sum_sales_total, name: "total-sales" },
    { value: <Money amount={calcSumStatistics.sum_accruals} />, name: "accruals" },
  ];

  if(!UserStore.getPermissionEnable("incomeinformation")) {
    COLUMNS = COLUMNS.filter(item => item !== 'dashboard-sales-profit')
  }

  return (
    <div>
      {!isMobileSize && <EmployeesFilter search={searchInput} onChangeSearch={handleChangeSearchInput}/>}
      <StatisticsBlock list={listStatistics} classNameText='title margin-bottom-0' classNameTitle='font-size-07'/>
      {isMobileSize && <DynamicTabList
        list={listTabs}
        onChangeTab={onChangeTab}
        defaultTab={defaultTab}
      />}
      <Box className='border-not-top'>
        {isMobileSize && <EmployeesFilter />}
        <Reports
          columns={COLUMNS}
          emptyMessage={emptyMessage}
          rows={filterWithSearchList}
        >
          {isMobileSize ? (
            <EmployeesRowMobile role="row" />
          ) : (
            <EmployeesRowDesktop
              role="row"
              statistics={employeesDataWithStatistics}
            />
          )}
        </Reports>
      </Box>
    </div>
  );
};

export default Employees;
