import React, {isValidElement, useRef, useState} from "react";
import T from "../../../components/i18n";
import s from "./styles.module.scss";
import { LocalIcon } from "whitelables/wl-name/icons";
import NotificationStore from "../notification-store";
import {Button} from "react-bulma-components";

const NotificationRow = ({ color, is_read, id, name, message }) => {
  const [isOpen, setIsOpen] = useState(false)
  let messageData = T(message);

  let typeIcon = s.newsType;
  let iconName = "news";
  if (color === "danger") {
    typeIcon = s.warningType;
    iconName = "error";
  } else if (color === "warning") {
    iconName = "warning";
    typeIcon = s.errorType;
  } else if (color === "success") {
    typeIcon = s.successType
  }

  const [startX, setStartX] = useState(null);
  const [endX, setEndX] = useState(null);
  const [translateX, setTranslateX] = useState(0);

  const swipeContainerRef = useRef(null);

  const handleTouchStart = (e) => {
    e.stopPropagation()
    if(!is_read) setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    e.stopPropagation()
    if(!is_read) {
      setEndX(e.touches[0].clientX);
      const distance = startX - e.touches[0].clientX;
      setTranslateX(-distance);
    }
  };

  const handleTouchEnd = (e) => {
    e.stopPropagation()
    if(!is_read) {
      if (startX && endX && startX - endX > 150) {
        if(color === "news") {
          NotificationStore.setReadNew(id)
        } else {

        }
      }

      setStartX(null);
      setEndX(null);
      setTranslateX(0);
    }
  };

  const length200MessageData = messageData?.length > 200

  const handleIsOpen = (evt) => {
    evt.stopPropagation()
    if(length200MessageData) {
      setIsOpen(prev => !prev)
    }
  }


  let messageText = messageData
  if(!isValidElement(message)){
    messageText =  <div dangerouslySetInnerHTML={{__html: T(message)}}
                             style={{whiteSpace: 'pre-wrap'}}/>
  }

  return (
    <div
      className={`${s.item} ${is_read && !isOpen ? s.read : ''}`}
      ref={swipeContainerRef}
      style={{
        transform: `translateX(${translateX}px)`,
        transition: "transform 0.3s ease-out",
      }}
      onClick={handleIsOpen}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <div className={s.title}>
        <span >{T(name)}</span>
        <span className={is_read && !isOpen ? s.read : typeIcon}>
          <LocalIcon icon={iconName} className={s.icon} />
        </span>
      </div>
      <div style={{whiteSpace: 'pre-wrap'}}>
        {length200MessageData && !isOpen
          ? `${messageData.slice(0, 200)}...`
          : messageText}
        {isOpen && messageText}
        {length200MessageData && (
          <div className='display-flex-center'>
            <br/>
            <Button className='button_link' onClick={handleIsOpen}>
              {isOpen ? T('hide-more-filter') : T('unfold')}
            </Button>
          </div>)}
      </div>
    </div>
  );
};

export default NotificationRow;
