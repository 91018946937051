import React, {useState} from 'react'
import SalesStore from "../../sales/sales-store";
import T from "../../../components/i18n";
import {
	Checkbox,
	Field
} from "react-bulma-components/lib/components/form";
import { LocalIcon } from 'whitelables/wl-name/icons'
import StatusButton from "../common/status-button";
import OrderSubRowDesktop from "./order-sub-row-desktop";
import ActionsDropdownOrder from "../common/actions-dropdown-order";
import {openSaleModal} from "../common/actions-func";
import {Link} from "react-router-dom";
import ChannelBlock from "../../../components/channel-block/channel-block";
import CommunicationClient from "../../../components/communication-client";
import ClientInfoRow from "../../../components/client-info-row/client-info-row";
import OrdersStore from "../orders-store";
import ChatsStore from "../../chats/chats-store";
import GoToChatClient from "../../../components/go-to-chat-client";
import Tag from "../../../components/tag";
import ClientsStore from "../../clients/clients-store";
import {parseJSON} from "../../../tools/error-handler";
import formatDate from "../../../tools/format-date";

const OrderRowDesktop = ({data,num, isCheckbox, isRefund}) => {
	const [isOpen, setIsOpen] = useState(false)
	const channel_id = SalesStore.foundChannelsByIntegrationId(data?.integration_id);
	const handleOpen = (evt) => {
		evt.stopPropagation()
		setIsOpen(prev => !prev)
	}

	const addressParse = JSON.parse(data?.address_1 || "{}")

	const getAddressLine = () => {
		return <><span className="silver">{T('address')}:</span> {addressParse?.city}, {addressParse?.address_1 || ''}</> || null
	}

	const isReserve = data.order_data.some(item => {
		const pid = item.local_product_id
		const oid = data.order_id
		return OrdersStore.getIsReserveOrdersByOidPid(oid, pid);
	})


	const getClientChat = ChatsStore.getChatByClientID(data.client_id)

	const tagsGroup = ClientsStore.getGroupClientTagByClientId(data.client_id);

	const info = parseJSON(data.info)

	return (
		<>
			<tr className={`order-row is-one-${(num % 2 !== 0) ? "even" : "odd"} ${isOpen ? 'selected-border' : ''}`} onClick={handleOpen}>
				<td>
					<div className={`display-flex-center black icon-open ${isOpen ? 'active' : ''}`}>
						<LocalIcon icon='arrow-left' size='small'/>
					</div>
				</td>
				<td>
					<Field>
						{isCheckbox && <Checkbox
							name="select_all"
							onChange={() => {
							}}
							checked={false}
						/>}
						{data?.id}<br/>
						<small className='small-font'>{formatDate(data?.date_created)}</small>
					</Field>
				</td>
				<td>
					<b>{T('order')}: {info?.order_text || data.order_id}</b>
					({data?.order_data?.length} {T(data?.order_data?.length > 1 ? 'products-label' : 'product-label' )})
					{data?.sales_id && (
						<>
							<br/>
							<Link
								to={{
									pathname: 'sales',
									state: {
										tab: 'all',
										status: 'all',
										period: new Date(data?.date_created),
										search: String(data['sales_id'])
									}
								}}
							>
								{T('main_sale')}: #{data.sales_id}
							</Link>
						</>
					)}
					<br/>
					<ChannelBlock channel_id={channel_id}/>
				</td>
				<td>
					{data.client_id ? (
							<ClientInfoRow clientID={data.client_id} withoutPhone/>
						)
						: <span>{data?.first_name} {data?.last_name}</span>}
					<br/>
					{data?.phone && (
						<span className='display-flex-row flex-wrap'>
							<b>{T('phone')}:</b>
							{data.phone}
							{getClientChat ? (
								<GoToChatClient chatID={getClientChat}/>
							) : (
								<CommunicationClient phone={data.phone}
																		 style={{width: 'fit-content'}}/>
							)}
						</span>)}
					{data?.email && <><b>{T('Email')}:</b> {data.email}</>}
					<div className='display-flex-row flex-wrap'>
						{!!tagsGroup?.length &&
							tagsGroup.map((item) => (
								<Tag
									key={`tag-${item.id}`}
									color={item.color}
									name={item.name}
								/>
							))}
					</div>
				</td>
				<td>
					<span className='text-success'>
						{data.price} {data.currency}
					</span>
					{!!data?.discount &&
						<p>{T('sales-discount')}: {Math.abs(data?.discount)}</p>}
					{data?.info?.is_paid ? (
						<div>
							<span className="channel_block has-background-success">{T('paid')}</span>  <br/>
							{(!!data?.order_data[0]?.payment_type || (!!data.info && !!data.info?.payment_type)) && <b>{data?.order_data[0]?.payment_type || data.info?.payment_type}</b>}
						</div>
					) : (
						<div>
							{ !!data?.order_data[0] && !!data?.order_data[0]?.payment_type  && <b>{data?.order_data[0]?.payment_type}</b>}
						</div>
					)}
				</td>
				<td>
					<StatusButton data={data} isRefund={isRefund}/>
				</td>
				{!isRefund && <td>
					<div className="display-flex-row">
						{data.status !== 'cancelled' && <div style={{marginRight: data?.sales_id ? '18px' : 0}}
									onClick={(evt) => openSaleModal({evt, data})}>
							{!data?.sales_id &&
								<LocalIcon icon='circle-success' className='svg-actions'/>}
						</div>}
						<ActionsDropdownOrder data={data} isReserve={isReserve}/>
					</div>
				</td>}
			</tr>
			{isOpen && <tr className={`order-row is-one-${(num % 2 !== 0) ? "even" : "odd"} ${isOpen ? 'selected-border' : ''}`}>
				<td colSpan={8}>
					{((addressParse?.city && addressParse?.address_1) || (data.info && data?.info?.comment)) && <div className='display-flex-column' style={{padding: '10px', background: num % 2 === 0 ? '#fff' : '#fafafa'}}>
						{addressParse?.city && addressParse?.address_1 && <div>
							{getAddressLine()}
						</div>}
						{data?.info?.comment && (
							<div>
								<span className="silver">{T('comment')}:</span> {data?.info?.comment}
							</div>)}
					</div>}
					{data.order_data.map(item => (
						<OrderSubRowDesktop
							key={`sub-row-${item.id}`}
							oid={data.order_id}
							order_id={data.id}
							pid={item.local_product_id}
							currency={data.currency}
							integrationID={data.integration_id}
							status={data.status}
							{...item}
						/>))}
				</td>
			</tr>}
		</>
	);
};

export default OrderRowDesktop;