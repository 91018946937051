import React, {useEffect, useState} from 'react'
import HistoryActions from "../history-actions";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import {Box, Button} from "react-bulma-components";
import Reports from "../../../components/reports";
import SalesRowMobile from '../../sales/mobile/sales-row-mobile';
import DesktopSalesRow from "../../sales/desktop/sales-row" ;
import usePaginationHook from "../../../tools/pagination-hook";
import T from "../../../components/i18n";
import HistoryStore from "../history-store";
import User from "../../../user/user-store";
import SalesActions from "../../sales/sales-actions";
import FilterPanel from "./filter-panel";

let COLUMNS = [
	"",
	"purchase",
	"who-add-sale",
	"customer",
	"delivery",
	"account",
	"payment-pay",
	"status",
	"reports-actions",
];

const EmptyMessage = () => {
	return (
		<Box>
			<h2>{T("not-have-history")}</h2>
		</Box>
	);
};

const SalesDeleted = () => {
		const {isMobileSize} = useIsMobile()
		const [isOpenRow, setIsOpenRow] = useState(false);

		const rows = HistoryStore.get('salesDeleted');

		const list = rows.filter(item => HistoryStore.applyFilterSales(item))


		const {PaginationBlock,setPage,end,page,total,start} = usePaginationHook({perPage: 20, listLength: list.length})

		const CUSTOMIZE_COLUMNS_DATA = COLUMNS.map((item, index) => ({
			label: item,
			is_global: item === "" || item === "purchase" ? 1 : 0,
			position: index + 1,
			is_active: item !== "who-add-sale",
		})).filter(item => {
			if(!User.getPermissionEnable("salesprice") && item.label === 'payment-pay') {
				return false
			}

			if(!User.getModuleEnable("clients") && item.label === 'customer') {
				return false
			}

			return true
		});

	const ACTIVE_COLUMNS = CUSTOMIZE_COLUMNS_DATA
		.sort((a, b) => a.position - b.position)
		.reduce((acc, cur) => {
			if (cur.is_active) {
				acc.push(cur.label);
			}

			return acc;
		}, []);

	useEffect(() => {
		HistoryActions.loadSalesDeleted()
		const completedRecovery = SalesActions.add.completed.listen(() => {
			HistoryActions.loadSalesDeleted()
		})

		return () => {
			completedRecovery()
		}
	}, []);

	return (
			<>
				{!isMobileSize && (
					<FilterPanel/>
				)}
				{!isMobileSize && (
					<div
						align="right"
						className='display-flex-row'
						style={{ marginBottom: "5px", position: "relative", justifyContent: "flex-end" }}
					>
						{!!list.length && (
							<div align="right">
								<Button
									onClick={() => setIsOpenRow((prev) => !prev)}
									style={{ fontSize: "12px", backgroundColor: "transparent" }}
								>
									{isOpenRow ? T("show-all-sales-btn") : T("hide-all-sales-btn")}
								</Button>
							</div>
						)}
					</div>
				)}
				<Box className="sales-report border-not-top" style={{ overflow: "auto" }}>
					{isMobileSize && (
						<FilterPanel />
					)}
					{list.length > 1 && isMobileSize && (
						<div align="right" style={{ marginBottom: "5px" }}>
							<Button
								onClick={() => setIsOpenRow((prev) => !prev)}
								style={{ fontSize: 10, backgroundColor: "transparent", padding: "3px 10px", lineHeight: 1, margin: 0, height: "auto"}}
							>
								{isOpenRow ? T("hide-more-filter") : T("unfold")}
							</Button>
						</div>
					)}
					<PaginationBlock
						totalPage={total}
						setPage={setPage}
						currentPage={page}
					/>

					<Reports
						emptyMessage={EmptyMessage}
						columns={ACTIVE_COLUMNS}
						hideFooterOnEmpty
						isLoading={HistoryStore.loadingSalesDeleted}
						minWidthList={CUSTOMIZE_COLUMNS_DATA.length * 110}
						rows={list.slice(start, end)}
					>
						{isMobileSize ? (
							<SalesRowMobile
								key="r0"
								role="row"
								columnLength={CUSTOMIZE_COLUMNS_DATA.length}
								triggerIsOpen={isOpenRow}
								isDeleted
							/>
						) : (
							<DesktopSalesRow
								activeColumn={CUSTOMIZE_COLUMNS_DATA}
								key="r0"
								role="row"
								triggerIsOpen={isOpenRow}
								isDeleted
							/>
						)}
					</Reports>
					<PaginationBlock
						totalPage={total}
						setPage={setPage}
						currentPage={page}
					/>
				</Box>
			</>
		);
};

export default SalesDeleted;