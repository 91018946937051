import React, {useEffect, useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import T from '../../../../components/i18n'
import {Input, Label} from "react-bulma-components/lib/components/form";
import UserStore from "../../../../user/user-store";
import SubmitButton from "../../../../components/submit-button";
import UserActions from "../../../../user/user-actions";
import {errorHandler} from "../../../../tools/error-handler";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import NumberInput from "../../../../components/number-input/number-input";
import {connectToStores} from "../../../../tools/reflux-tools";

const AddMakePayment = ({name, position,is_not_deleted,  onClose}) => {
	const [value, setValue] = useState(name || "")
	const [positionData, setPositionData] = useState(position || "")
	const [waiting, setWaiting] = useState(false)
	const handleChange = ({target: {value}}) => {
		setValue(value)
	}
	const handleChangePosition = ({target: {value}}) => {
		setPositionData(value)
	}

	const {setErrorMessage,ErrorMessageBlock,errorMessage,handleAutoRemoveError} = ErrorMessageControlHook()

	const handleSubmit = () => {
		const makePaymentList = UserStore.getGlobalSetting('makePaymentList') || []
		if(!!value) {
			setWaiting(true)
			const isFind = makePaymentList.find(item => {
				if(typeof item === 'object') {
					return item.name === value && item.name !== name
				} else {
					return item === value && item !== name
				}
			})

			const isFindName = makePaymentList.find(item => {
				if(typeof item === 'object') {
					return item.name === name
				} else {
					return item === value
				}
			})

			if(isFind) {
				handleAutoRemoveError("field-already-exists ")
				setWaiting(false)
			} else if (name && isFindName) {
				const newList = makePaymentList.reduce((acc, cur) => {
					const newItem = {}

					if(typeof cur === 'object') {
						if(cur.name === name) {
							newItem.name = value
							newItem.position = positionData || 0
						} else {
							newItem.name = cur.name
							newItem.position = cur.position || 0
						}
					} else {
						if(cur === name) {
							newItem.name = value
							newItem.position = positionData || 0
						} else {
							newItem.name = cur
							newItem.position = 0
						}
					}
					acc.push(newItem)

					return acc
				}, [])
				UserStore.setGlobalSetting(newList, 'makePaymentList')
			} else {
				const newList = [...makePaymentList, {
					name: value,
					position: positionData || 0
				}]

				UserStore.setGlobalSetting(newList, 'makePaymentList')
			}
		} else {
			handleAutoRemoveError("not all fields required")
		}

	}

	useEffect(() => {
		const updateValue = UserActions.updateSettings.completed.listen(() => {
				onClose()
		});
		const updateValueFailed = UserActions.updateSettings.failed.listen((res) => {
			const message = errorHandler(res);
			handleAutoRemoveError(message)
		})

		return () => {
			updateValue()
			updateValueFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			<Box>
				<Heading>{T('add-make-the-payment')}</Heading>
				<Label>{T('name')}</Label>
				<Input disabled={!!is_not_deleted} value={value} name='makePayment' onChange={handleChange} />
				<Label className='margin-top-10'>{T('position-label')}</Label>
				<NumberInput value={positionData} name='position' onChange={handleChangePosition} placeholder='0'/>
				<div className='display-flex-row margin-top-10' style={{justifyContent: 'flex-end'}}>
					<Button size='medium' onClick={onClose}>{T('close')}</Button>
					<SubmitButton size='medium' text='add' fullwidth={false} submit={handleSubmit} waiting={waiting}/>
				</div>
			</Box>
		</>
	);
};

export default connectToStores(AddMakePayment, {UserStore});