import React from 'react'
import {Button} from "react-bulma-components";
import T from '../../../components/i18n'
import AppStore from "../../../app-store";
import AddSupplierModal from "../add-supplier-modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
const ActionsButton = () => {
	const {isMobileSize} = useIsMobile()
	return (
		<div className="display-flex-row margin-bottom-10 margin-08" style={{justifyContent: "flex-end"}}>
			<Button color='info' className='display-flex-row' rounded size={isMobileSize ? 'small' : 'medium'} onClick={() => AppStore.openModal(<AddSupplierModal/>)}>
				<FontAwesomeIcon icon='plus-circle'/>{T('add-supplier-btn-label')}
			</Button>
		</div>
	);
};

export default ActionsButton;