import React, { Component } from 'react';
import T from "components/i18n";
// import { redirectTo } from 'tools/redirect-to'

import Heading from 'react-bulma-components/lib/components/heading';

import Box from 'react-bulma-components/lib/components/box';
import Button from 'react-bulma-components/lib/components/button';

import Actions from './orders-actions'
import Store from './orders-store'
import SelectComponent from 'tools/select-component';

import SubmitButton from 'components/submit-button';
import Messages from 'components/messages';
import OrdersActions from "./orders-actions";
import AppStore from "../../app-store";
import AddStatusModal from "../sales/add-status-modal";

class ChangeStatusModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
          new_status: null,
          status: {value: props.data.status},
          message: null,
          waiting: null,
          oid: props.data.order_id,
          iid: props.data.integration_id,
          commission: 0,

          close: false,
          step: 0
        };

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
        this.renderStepOne = this.renderStepOne.bind(this);
        // this.renderStepSecond = this.renderStepSecond.bind(this);

    }

    componentWillUnmount() {
      // if (typeof(this.tranferFundListen) == "function") {
      //   this.tranferFundListen();
      // }

      if (typeof(this.changeStatusCompl) == "function") {
          this.changeStatusCompl();
      }
    }

    componentDidMount() {
      this.changeStatusCompl = OrdersActions.updateStatusOrder.completed.listen(() => {
        this.props.onClose()
      })

      this.changeStatusCompl = OrdersActions.updateStatusOrder.failed.listen((res) => {
        const response = JSON.parse(res.response || "{}")
        this.setState({
          message: {msg: T(response?.error) || T('unhandled_error')},
          waiting: false
        })
      })
    }

 


    onChange(selectedOption, actions) {
      if (!selectedOption) return;


      this.setState({
        [actions.name]: selectedOption
      });
    }


    submit() {
        const {submit, data} = this.props;

        this.setState({"waiting": true});

        submit(data, this.state.status.value);

    }

    renderStepOne() {
      return (
          <Box>
              <Heading>
                  {T('change-order-status')}
              </Heading>
              <SelectComponent 
                            name="status"
                            onChange={this.onChange} 
                            value={this.state.status} 
                            addItem={({name})=> AppStore.openModal(<AddStatusModal name={name} defaultType='orders'/>)}
                            addItemModal={() => AppStore.openModal(<AddStatusModal defaultType='orders'/>)}
                            list={() => [{name: "processing", id:"processing"}, ...Store.getOrdersStatuses(this.props.data.integration_id)]}
                            load={Actions.loadStatus}
                            creatable={true}/>
              <Button.Group>
                    <SubmitButton text='change-order-status' waiting={this.state.waiting} submit={this.submit}/>
              </Button.Group>
          </Box>
      );
    }

    render() {
        const stepNaviganor = [this.renderStepOne, this.renderStepSecond],
              currentStep = stepNaviganor[this.state.step];


        return (
              <div>
                <Messages message={this.state.message} close={() => this.setState({message: null})} />
                {currentStep()}
              </div>
        );

    }  
}

export default ChangeStatusModal;