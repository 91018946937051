import T from "../../../components/i18n";
import Money from "../../../components/money";
import ClientsStore from "../../clients/clients-store";
import React from "react";
import DOM from "react-dom-factories";
import formatDate from 'tools/format-date';

export const generateOrderName = (data) => {
	const quantity = data.items.reduce((acc, cur) => {
		acc += cur.quantity;
		return acc;
	}, 0);

	return (
		<span>
      {data["order_number_text"]}{" "}
			<br/>
			<span className="silver nowrap">
        {T("items-count", { count: data["items"].length })} ({quantity}{" "}
				{T("items-e")})
      </span>
    </span>
	);
};

export const getPrice = (data) => {
	return <Money aid={data["account_id"]} amount={data["amount_sale"] || 0} wrapper={DOM.span} />;
};

export const getDate = (data) => {
	return <span className="silver">{formatDate(data['purchase_date'])}</span>;
};

export const getClient = (data) => {
	const client = ClientsStore.getClientById(data["client_id"]);
	if (client && client.simple_name) {
		return (
			<span className="">
        {client["phone"]}
				&nbsp;
				({client["simple_name"]})
      </span>
		);
	} else {
		return null;
	}
};