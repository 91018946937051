import React, { Component } from 'react';
import T from "components/i18n";
import DOM from "react-dom-factories";
import OpenModalFactory from 'components/modal/open-modal-factory';
import Money from 'components/money';
import AddClientModal from 'modules/clients/modal/add-client-modal/add-client-modal'
import AddDeliveryLink from 'modules/clients/delivery';
import Button from 'react-bulma-components/lib/components/button';
import { connectToStores } from "tools/reflux-tools";
import { filterClient } from 'tools/filters-helper';
import ClientsStore from 'modules/clients/clients-store';
import ClientsActions from 'modules/clients/clients-actions';
import OpenInsideModal from "components/modal/open-inside-modal";
import {
    Control, 
    Input, 
    Radio, 
    Field,
    Label, 
} from 'react-bulma-components/lib/components/form';
import SelectComponent from 'tools/select-component';
import SalesStore from '../sales-store'
import Actions from '../sales-actions'
import User from 'user/user-store';
import Messages from 'components/messages';
import Box from 'react-bulma-components/lib/components/box';
import Icon from 'react-bulma-components/lib/components/icon';
import Columns from 'react-bulma-components/lib/components/columns';
import Heading from 'react-bulma-components/lib/components/heading';
import SubmitButton from 'components/submit-button';
import {
  withIsMobileSize
} from "../../../components/is-mobile-context/is-mobile-context";
import AppStore from "../../../app-store";

const getSize = (products) => {
  let sizes = {};

  for (let i in products) {
        const prod = products[i],
        size = prod['size'].split("x"),
        sum = size.reduce((partialSum, a) => partialSum + parseFloat(a), 0);

        sizes[sum] = size;
  }

  let max = Math.max(Object.keys(sizes));

  return sizes[max] || ['', '', '', ''];
}

const buildDesc = (products) => {
  let name = "";

  for (let i in products) {
        const prod = products[i];
        name += prod['name']+".";
  }

  return name.slice(0,49);
}

class CreateUkrPoshtaModal extends Component {
    constructor(props) {
        super(props);

        const products = props.product,
            size = getSize(products);

        this.init = true;
        this.state = {
          message: "",
          sender: null,
          step: 0,
          paid: "Recipient",
          money: this.props.isCOD ? window.localStorage.getItem('COD-urkp') || '2' : window.localStorage.getItem('money-urkp') || this.props.money || "1",
          types: "EXPRESS",
          amount: props.amount,
          length: size[1],
          width: size[2],
          heigth: size[0],
          weight: size[3],
          waiting: null,
          count_place: "",
          client: props.client || null,
          postoffice: props.postoffice || null,
          client_data: null, 
          desc: buildDesc(products), 
        }

        this.onCompleteCreate = props.onCompleteCreate;

        this.onChange = this.onChange.bind(this);
        this.onChangeSelect = this.onChangeSelect.bind(this);
        this.onChangeSelectOffice = this.onChangeSelectOffice.bind(this);
        this.onChangeSelectClient = this.onChangeSelectClient.bind(this);
        this.renderFinish = this.renderFinish.bind(this);
        this.renderMain = this.renderMain.bind(this);
        this.onCloseAddPost = this.onCloseAddPost.bind(this);
        this.submit = this.submit.bind(this);

    }

    submit() {
        if (!this.state.postoffice) {
          this.setState({
              waiting: false,
              message: T('not all fields required')
          })
          return false;
        }

        if ((!this.state.client_data.first_name || this.state.client_data.first_name === "") || (!this.state.client_data.last_name || this.state.client_data.last_name === "")) {
          this.setState({
              waiting: false,
              message: T('not all fields required')
          })
          return false;
        }

        if (this.state.money === "2" && (!this.state.client_data.middle_name || this.state.client_data.middle_name === "")) {
            this.setState({
              waiting: false,
              message: T('middle_name-requied')
          })
            return false;
        }

        let request = {
          'paid': this.state.paid,
          'money': this.state.money,
          'types': this.state.types,
          'amount': this.state.amount || 10,
          'count_place': this.state.count_place || 1,
          'postoffice': this.state.postoffice.value,
          'sender': this.state.sender.value,
          'width': this.state.width || 10,
          'heigth': this.state.heigth || 10,
          'length': this.state.length || 10,
          'weight': this.state.weight || 1,
          'desc': this.state.desc || '',
        }

        this.setState({"waiting": true});
        Actions.createTrackingNumber(request);
    } 

    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }   

      if (typeof(this.listenAddError) == "function") {
          this.listenAddError();
      }      

    }


    componentDidMount() {
        this.listenAdd = Actions.createTrackingNumber.completed.listen((res) => {
          if (res['success']) {
            this.setState({
                'waiting': false,
                'response': res,
                'step': 1
            })
          } else {
            this.setState({
              waiting: false,
              message: T(res.error)
            })
          }
        });   

        this.listenAddError = Actions.createTrackingNumber.failed.listen((r) => {
            let res = JSON.parse(r.response);
            this.setState({
              waiting: false,
              message: T(res.error)
            })
        });

        setTimeout(() => {this.init = false;}, 1000)
        

        if (this.props.client) {
            this.onChangeSelectClient(this.props.client, {'name': "client"});
        }
    }

    onChange(evt, saveLocal) {
        const value = evt.target.value,
            name = evt.target.name;

      if(saveLocal) {
        if(this.props.isCOD) {
          AppStore.saveLocalStorageAndMobile({key: 'COD-urkp', value})
        } else {
          AppStore.saveLocalStorageAndMobile({key: 'money-urkp', value})
        }
      }

        this.setState({
            [name]: value
        });
    }     

    onChangeSelect(selectedOption, actions) {
      this.setState({
          [actions.name]:  selectedOption,
          message: null
      });
    }

    onChangeSelectOffice(selectedOption, action) {
        if (action.action === "select-option") {
          if (selectedOption.value === 0 && (this.state.client && this.state.client.value !== 0) && !this.init) {
            this.setState({
              "addPostOffice": true
            });
          } else {
            if(this.props.onChangePostoffice) {
              this.props.onChangePostoffice(selectedOption, action)
            }
            this.setState({
              [action.name]: selectedOption
            });
          }
        }
    }

    onChangeSelectClient(selectedOption, action) {
      const client = ClientsStore.getClientById(selectedOption.value);
      if(this.props.onChangeClient) {
        this.props.onChangeClient(selectedOption, action);
      }
      this.setState({
          [action.name]: selectedOption,
          "client_data": client
      })
    }    

    renderFinish() {
      const data = this.state.response || {};
      return (
          <Box>
              <div align="center">
                <Heading size={2} className="text-success">{T('ttn-success-created')}</Heading>
                <Heading size={1} className="text-danger" subtitle><b>{data['IntDocNumber'] || "000000000000"}</b></Heading>
              </div>

                <p className="big-text"><b>{T('delivery-cost')}: <Money amount={data['CostOnSite']}  wrapper={DOM.span} /></b></p>
                <p className="big-text"><b>{T('delivery-time')}: {data['EstimatedDeliveryDate']}</b></p>

                <div className='margin-top-10' align="center">
                  <Button size="large" color="success" onClick={()=>{this.onCompleteCreate(data['IntDocNumber']); this.props.onClose(); }}>{T('btn-done')}</Button>
                </div>
          </Box>
        );
    }
    
    renderMain() {
      const sizeClass = this.props.isMobileSize ? "small" : null;

      return (
        <Box>
            <SelectComponent 
                  name="sender"
                  label="sender"
                  onChange={this.onChangeSelect} 
                  value={this.state.sender} 
                  list={() => User.getSenderInfoList(3)}
                  creatable={false}/>

            <Columns>
            <Columns.Column>
              <Field kind="group">
                <Control fullwidth>
                <SelectComponent 
                  name="client"
                  label="client"
                  onChange={this.onChangeSelectClient} 
                  value={this.state.client} 
                  filterOption = {filterClient}
                  list={ClientsStore.getClientsList}
                  load={ClientsActions.load}
                  addItem={({name}) => AppStore.openModal(<AddClientModal phone={name} className='modal-start'/>)}
                  addItemModal={() => AppStore.openModal(<AddClientModal className='modal-start'/>)}
                  empty={'enter-client-phone'}
                  creatable={true}/>
                </Control>
                {this.state.client && this.state.client.value !== 0 ? 
                <Control className="margin-top-10">
                    <Label>&nbsp;</Label>
                    <OpenInsideModal renderAs="icon" size="1x" icon="edit">
                          <AddClientModal
                            className='modal-start'
                              edit={true}
                              data={this.state.client_data}
                              client_id={this.state.client.value}
                          />
                    </OpenInsideModal> 
                </Control>
                : null}
              </Field>
                
            </Columns.Column>
            <Columns.Column>
                <SelectComponent 
                  name="postoffice"
                  label="post-office-number"
                  readOnly={(!this.state.client || this.state.client.value === 0 ?  true : false)}
                  onChange={this.onChangeSelectOffice} 
                  value={this.state.postoffice}
                  list={() => {return ClientsStore.getClientDeliveryListSelect(
                    this.state.client ? this.state.client.value : false, User.getLang(), User.UP_ID
                  )}}
                  placeholder='select-the-delivery-address'
                  load={ClientsActions.load}
                  addItem={ClientsActions.addClient}
                  empty={'add-delivery-address-btn'}
                  creatable={false}/>
            </Columns.Column>
            </Columns>

            <Label>{T('dimensions')}</Label>
            <Columns className="is-mobile">
                <Columns.Column>
                  <Control iconRight>
                    <Input  size={sizeClass} 
                        key={"weight"} 
                        name={"weight"} 
                        placeholder={T("weight")}
                        type="number" 
                        min="0.1"
                        onChange={this.onChange} 
                        value={this.state.weight}
                        />
                        <Icon align="right">
                        <span>{T('kg')}</span>
                      </Icon>
                    </Control>
                </Columns.Column>
                <Columns.Column>
                  <Control iconRight>
                    <Input  size={sizeClass}  
                        name={"heigth"} 
                        placeholder={T("heigth")}
                        type="number" 
                        min="1"
                        onChange={this.onChange} 
                        value={this.state.heigth}
                        />
                        <Icon align="right">
                        <span>{T('cm-short')}</span>
                      </Icon>
                    </Control>
                </Columns.Column>
                <Columns.Column>
                  <Control iconRight>
                    <Input  size={sizeClass} 
                        name={"width"} 
                        placeholder={T("width")}
                        type="number" 
                        min="1"
                        onChange={this.onChange} 
                        value={this.state.width}
                        />
                        <Icon align="right">
                        <span>{T('cm-short')}</span>
                      </Icon>
                    </Control>
                </Columns.Column>
                <Columns.Column>
                  <Control iconRight>
                    <Input  size={sizeClass} 
                        name={"length"} 
                        placeholder={T("length")}
                        type="number" 
                        min="1"
                        onChange={this.onChange} 
                        value={this.state.length}
                        />
                        <Icon align="right">
                        <span>{T('cm-short')}</span>
                      </Icon>
                    </Control>
                </Columns.Column>
          </Columns>

          <Columns className="is-mobile">
                <Columns.Column>
                <div>
                  <Control iconRight>
                  <Radio
                            onChange={this.onChange} 
                            checked={this.state.types === "EXPRESS"} 
                            value={"EXPRESS"} 
                            size="large"
                            name="types">
                          {T("express")}
                        </Radio>
                        <Radio 
                            onChange={this.onChange} 
                            checked={this.state.types === "STANDARD"}
                            value={"STANDARD"} 
                            size="large"
                            name="types" >
                          {T("standart")}
                        </Radio>
                  </Control>
                </div>
                <div className="margin-top-20">
                  <Label>{T('why-paid')}</Label>
                  <Control iconRight>
                  <Radio
                            onChange={this.onChange} 
                            checked={this.state.paid === "Sender"} 
                            value={"Sender"} 
                            size="large"
                            name="paid">
                          {T("sender")}
                        </Radio>
                        <Radio 
                            onChange={this.onChange} 
                            checked={this.state.paid === "Recipient"}
                            value={"Recipient"} 
                            size="large"
                            name="paid" >
                          {T("recipient")}
                        </Radio>
                  </Control>
                </div>
                </Columns.Column>
                <Columns.Column>
                  <Control iconRight>
                    <Radio
                        onChange={(evt) => this.onChange(evt, true)}
                        checked={this.state.money === "1"} 
                        value={"1"} 
                        size="large"
                        disabled={this.props.isCOD}
                        name="money">
                      {T("grade")}
                    </Radio>
                    <Radio 
                        onChange={(evt) => this.onChange(evt, true)}
                        checked={this.state.money === "2"}
                        value={"2"} 
                        size="large"
                        name="money" >
                      {T("remittance")}
                    </Radio>
                    <Radio
                      onChange={(evt) => this.onChange(evt, true)}
                      checked={this.state.money === "3"}
                      value={"3"}
                      size="large"
                      name="money" >
                      {T("payment-control")}
                    </Radio>
                  </Control>
                  <Control iconRight className="margin-top-10">
                    <Input size={sizeClass} 
                        name="amount"
                        placeholder={T("amount")}
                        type="number" 
                        min="0"
                        onChange={this.onChange} 
                        value={this.state.amount}
                        />
                        <Icon align="right">
                        <span>{User.getCurrency()}</span>
                      </Icon>
                    </Control>
                </Columns.Column>
          </Columns>
          <Columns className="is-mobile">
                <Columns.Column>
                    <Control iconRight className="margin-top-10">
                    <Input size={sizeClass} 
                        name="desc"
                        placeholder={T("desc-parcel")}
                        type="text" 
                        maxLength={50}
                        onChange={this.onChange} 
                        value={this.state.desc}
                        />
                    </Control>
                </Columns.Column>
          </Columns>


          <Button.Group position="centered">
              <Button size={sizeClass}  rounded color="light"
                onClick={() => { this.props.onClose()}}
                >{T('close')}</Button>

                <SubmitButton size={sizeClass} 
                            text='create-ukrposhta-ttn' 
                            fullwidth={false} 
                            waiting={this.state.waiting} 
                            submit={this.submit}/>
          </Button.Group>
        </Box>
        );
    }

    onCloseAddPost() {
        this.setState({
          "addPostOffice": false
        });
    }

    render() {
        const stepNaviganor = [this.renderMain, this.renderFinish],
              currentStep = stepNaviganor[this.state.step];

        return (
          <div>
            <Messages message={this.state.message} close={() => this.setState({message: null})} />
            {currentStep()}

            {/*{this.state.close ? redirectTo("/sales") : ""}*/}

            {ClientsStore.get('openModal') ? OpenModalFactory(
              <AddClientModal className='modal-start' data={{"phone": ClientsStore.get('phone')}}/>, ClientsStore.closeModal
            ) : null}


            {this.state.addPostOffice && this.state.client_data ? OpenModalFactory(
              <AddDeliveryLink modal='add-delivery' onClose={this.onCloseAddPost} date={{  delivery: User.UP_ID,
                                        phone: this.state.client_data['phone'],
                                        client_id: this.state.client_data["id"],
                                        name: this.state.client_data["name"],
                                        inner: true,
                                        onClose: this.onCloseAddPost
                                    }} />, this.onCloseAddPost
            ) : null}

          </div>
        )
    }

}


export default connectToStores(withIsMobileSize(CreateUkrPoshtaModal), {
    sales: SalesStore,
    clients: ClientsStore
});