import React, {useEffect, useState} from 'react'
import AddClientsNotDefault
	from "../../../settings/settings-tabs/additional-settings-account/add-clients-not-default";
import {Box, Button, Heading} from "react-bulma-components";
import T from "../../../../components/i18n";
import SubmitButton from "../../../../components/submit-button";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import UserStore from "../../../../user/user-store";
import UserActions from "../../../../user/user-actions";
import {errorHandler} from "../../../../tools/error-handler";
import {INPUT_DATA_ADD_CLIENTS} from "./add-client-modal";

const AdditionalFieldsNotDefault = ({onClose}) => {
	const getClientsDefaultValues = UserStore.getMoreSetting('addClientsDefault')
	const addClientsDefaultValues = Object.values(INPUT_DATA_ADD_CLIENTS).reduce((acc,cur) => {
		if(getClientsDefaultValues) {
			if(getClientsDefaultValues[cur.name]) {
				acc[cur.name] = {
					ch: getClientsDefaultValues[cur.name].ch,
				};
			} else {
				acc[cur.name] = {
					ch: false,
				};
			}
		} else {
			if(cur.notDefault) {
				acc[cur.name] = {
					ch: true,
				}
			} else {
				acc[cur.name] = {
					ch: false,
				};
			}
		}

		return acc
	}, {});
	const {setErrorMessage,ErrorMessageBlock,errorMessage,handleAutoRemoveError} = ErrorMessageControlHook()
	const [waiting, setWaiting] = useState(false);
	const [values, setValues] = useState(addClientsDefaultValues)

	useEffect(() => {
		const updateSetting = UserActions.updateSettings.completed.listen(() => {
			onClose()
		})
		const updateSettingFail = UserActions.updateSettings.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})
		return () => {
			updateSettingFail()
			updateSetting()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const handleChangeClientsDefault = ({target: {name, checked}}) => {
		setValues(prev => ({
				...prev,
				[name]: {
					ch:checked
				}
		}))
	}

	const handleSubmit = () => {
		setWaiting(true)
		const clientData = Object.entries(values).reduce((acc, [key, value]) => {
			if(value.ch) {
				acc[key] = value
			}
			return acc
		}, {})

		UserStore.setMoreSetting(clientData, 'addClientsDefault')
	}

	const handleResetSetting = () => {
		setWaiting(true)
		UserStore.deleteMoreSetting('addClientsDefault')
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading>{T('additional-info')}</Heading>
				<AddClientsNotDefault values={values} onChange={handleChangeClientsDefault}/>
				<div className='display-flex-row margin-top-10 flex-wrap' style={{justifyContent: 'space-between'}}>
					<SubmitButton size='small' fullwidth={false} color='warning' text='reset-to-default-columns' submit={handleResetSetting} waiting={waiting}/>
					<div className='display-flex-row'>
						<Button onClick={onClose} size='large'>{T('close')}</Button>
						<SubmitButton text='btn-save' color='success' submit={handleSubmit} fullwidth={false} waiting={waiting}/>
					</div>
				</div>
			</Box>
		</>
	);
};

export default AdditionalFieldsNotDefault;