import React, {useEffect, useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import T from	'../../../../components/i18n'
import InputsGroupField
	from "../../integrations-nw/modal/common/inputs-group-field";
import SelectComponent from "../../../../tools/select-component";
import {Field, Label} from "react-bulma-components/lib/components/form";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
import SubmitButton from "../../../../components/submit-button";
import TerminalActions from "../terminal-actions";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import {errorHandler, parseJSON} from "../../../../tools/error-handler";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserStore from "../../../../user/user-store";
import Select from "../../../../components/Select";
import AppStore from "../../../../app-store";
import AddMakePayment from "./add-make-payment";
import {connectToStores} from "../../../../tools/reflux-tools";

const INPUT_DATA = [
	{
		name: "rrn",
		label: "RRN",
	},
	{
		name: "card_mask",
		label: "card_mask_label"
	},
	{
		name: "auth_code",
		label: "auth_code_label"
	},
	{
		name: "owner_name",
		label: "owner_name_label"
	},
	{
		name: "terminal",
		label: "terminal_label_label"
	},
	{
		name: "bank_name",
		label: "bank_name_label"
	},
	{
		name: "receipt_no",
		label: "receipt_no_label"
	}
]

const paymentSystemData = [
	{name: "Visa", id: "Visa"},
	{name: "Mastercard", id: "Mastercard"},
]

export const makePaymentListDefault = ["З поточного рахунку", 'Картка', "Переказ з картки",
	"Інтернет еквайринг", "Інтернет банкінг", "Торговий еквайринг", "Платіж через інтегратора Interkassa", "Платіж через інтегратора Portmone",
	"Платіж через інтегратора LiqPay", "Платіж через інтегратора mono checkout", "Платіж через інтегратора Novapay",
	"Платіж через інтегратора EasyPay", "Платіж через інтегратора Platon", "Переказ через ННПП"]

const AddManualRrn = ({onClose, onResponseTerminal, account_id, terminalId, changeMakePaymentLabel}) => {
	const {isMobileSize} = useIsMobile()
	const [value, setValue] = useState({})
	const [isOpenAdditionalField, setIsOpenAdditionalField] = useState(false)
	const [waiting, setWaiting] = useState(false)
	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()

	useEffect(() => {
		if(terminalId) {
			TerminalActions.loadReceiptById(terminalId)
		}
		const loadReceipt = TerminalActions.loadReceiptById.completed.listen((data) => {
			const result = data.data.find(item => item.id === terminalId)
			if(result) {
				const parseJson = parseJSON(result.response)
				if(Object.keys(parseJson).length > 2) {
					setIsOpenAdditionalField(true)
				}
				if(parseJson.payment_label) {
					parseJson.payment_label = {value: parseJson.payment_label}
				}
				setValue(parseJson)
			}
		});

		return () => {
			loadReceipt()
		}
	}, [terminalId]);
	const handleChangeInput = ({target: {value, name}}) => {
		setValue(prev => ({...prev, [name]: value}))
	}

	const handleChangeSelect = (selectValue) => {
		setValue(prev => ({...prev, "payment_system": selectValue}))
	}

	const handleChangePayment = ({value}) => {
		setValue(prev=> ({...prev, "payment_label": value}))
	}

	const handleSubmit = () => {
		const dataSubmit = {
			rrn: value?.rrn || "",
			card_mask: value?.card_mask || "",
			auth_code: value?.auth_code || "",
			owner_name: value?.owner_name || "",
			terminal: value?.terminal || "",
			bank_name: value?.bank_name || "",
			receipt_no: value?.receipt_no || "",
			payment_system: value?.payment_system?.value || '',
		}

		if(value.payment_label && value.payment_label.value) {
			dataSubmit.payment_label = value.payment_label.value
		}

		if(value.rrn && value?.rrn?.length < 12) {
			handleAutoRemoveError('rrn-error-length')
			return
		}

		if(value.auth_code && value.auth_code.length < 6) {
			handleAutoRemoveError('auto-code-error-length')
			return
		}

		setWaiting(true)
		TerminalActions.addTerminalReceipt(dataSubmit, account_id)
	}

	useEffect(() => {
		const terminalAddReceiptCompleted =
			TerminalActions.addTerminalReceipt.completed.listen((data) => {
				onResponseTerminal(data.id, value.rrn);
				changeMakePaymentLabel(value?.payment_label.label)
				onClose();
			});
		const terminalAddReceiptFailed =
			TerminalActions.addTerminalReceipt.failed.listen((res) => {
				const messageRes = errorHandler(res)
				setWaiting(false);
				handleAutoRemoveError(messageRes);
			});

		return () => {
			terminalAddReceiptCompleted()
			terminalAddReceiptFailed()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	const makePaymentListCustom = UserStore.getMakePaymentList()

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			<Box>
				<Heading>{T('fiscal-data')}</Heading>
				<Label size='large'>{T('make-the-payment')}</Label>
				<Select
					list={makePaymentListCustom}
					placeholder={T('make-the-payment')}
					addItem={(name) => AppStore.openModal(<AddMakePayment name={name}/>)}
					addItemModal={() => AppStore.openModal(<AddMakePayment/>)}
					isCreate
					onChange={handleChangePayment}
					name='payment_label'
					value={value?.payment_label}
					disabledAutoSelect
				/>
				<Label className='margin-top-20 display-flex-row-gap cursor-pointer' size='large' onClick={() => setIsOpenAdditionalField(prev => !prev)}>{T('data-epz')} <FontAwesomeIcon icon={isOpenAdditionalField ? 'minus-circle' : 'plus-circle'}/></Label>
				{isOpenAdditionalField && <div className='display-flex-row flex-wrap' style={{columnGap: 10}}>
					<Field style={isMobileSize ? {width: '100%'} : {minWidth: '48%'}}>
						<SelectComponent value={value.payment_system}
														 placeholder='payment_system' label='payment_system'
														 name='payment_system'
														 list={() => paymentSystemData}
														 onChange={handleChangeSelect}/>
					</Field>
					<InputsGroupField dataInput={INPUT_DATA} onChange={handleChangeInput}
														inputValues={value}
														styleField={isMobileSize ? {width: '100%'} : {minWidth: '48%'}}
														disabledMargin/>
				</div>}
				<div className='display-flex-row flex-wrap margin-top-20' style={{justifyContent: 'flex-end'}}>
					<Button onClick={onClose} size='medium'>{T('close')}</Button>
					<SubmitButton fullwidth={false} submit={handleSubmit} text={terminalId ? 'btn-edit' : 'save-btn-label'} size='medium' waiting={waiting}/>
				</div>
			</Box>
		</>
	);
};

export default connectToStores(AddManualRrn, {UserStore});