import React, {useEffect, useState} from 'react'
import ProductsStore from "../../products-store";
import AddVariableModal from "./add-variable-modal";
import {prepareWarehouse} from "../../common/function";
import Actions from "../../products-actions";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import ProductsActions from "../../products-actions";
import {connectToStores} from "../../../../tools/reflux-tools";
import Loader from "react-loader-spinner";

const checkField = (data) => {
	let message = "";

	if (data["category_id"] === 0) {
		message = "HTTP 400: Bad Request (no arguments)";
	} else if (data["name"]?.length < 2) {
		message = "toshort-name";
	}

	return message;
}

const EditVariableModal = ({pid, parent_id, onClose}) => {
 const {errorMessage,ErrorMessageBlock,handleAutoRemoveError,setErrorMessage} = ErrorMessageControlHook()
	const product = ProductsStore.getProduct(pid);
	const parentProduct = ProductsStore.getProduct(parent_id);
	const [isWaiting, setIsWaiting] = useState(true)

	const attr = []
	if (parentProduct.attr_dict) {
		const raw_attr = JSON.parse(parentProduct.attr_dict);
		for (let i in raw_attr) {
			attr.push({
				value: raw_attr[i],
			});
		}
	} else if(product) {
		const raw_attr = JSON.parse(product.attr_dict);
		for (let i in raw_attr) {
			attr.push({
				value: raw_attr[i],
			});
		}
	}

	useEffect(() => {
		const getIdsVariable = ProductsStore.getIdsProductVariable(parent_id);
		ProductsActions.loadAdditionalInfoProduct(getIdsVariable)
		ProductsActions.loadByPid(getIdsVariable);

		const loadByPid = ProductsActions.loadByPid.completed.listen(() => {
			setIsWaiting(false);
		});
		return () => {
			loadByPid()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleSubmit = (data_variable, attr) => {
			let data = {
				warehouses: prepareWarehouse(data_variable["warehouses"]),
				barcode: data_variable["barcode"],
				vendorCode: data_variable["vendorCode"],
				units: parentProduct.units || product.units,
				af: JSON.stringify(data_variable['af']),
				custom_price: JSON.stringify(data_variable.custom_price),
				category_id: parentProduct.category_id || product.category_id,
				name: parentProduct.real_name || product.real_name,
				desc: parentProduct.desc || product.desc || '' ,
				brand: parentProduct.brand_id || product.brand_id,
				type_product: 2,
				attr: parentProduct.attr_dict || product.attr_dict,
				is_variable: 1,
				size_height: data_variable.size_height,
				size_width: data_variable.size_width,
				size_length: data_variable.size_length,
				weight: data_variable.weight,
				ukzt: data_variable.ukzt,
				storage_place: data_variable.storage_place,
				expirated_date: data_variable.expirated_date,
				parent_id: parentProduct.parent_id || product.parent_id,
			};

			const afData = { ...data_variable.af };

			if (!!Object.keys(data_variable.custom_price).length) {
				afData.custom_price = data_variable.custom_price;
			}

			data["af"] = JSON.stringify(afData || {});

			const error = checkField(data)
			if (error) {
				handleAutoRemoveError(error)
				return;
			}

			if (data_variable["pid"]) {
				data["pid"] = data_variable["pid"];
			}

			if (attr) {
				data["attr_value"] = JSON.stringify(attr);
			}

			Actions.addProduct(data);
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage} />
			{isWaiting ? (
			<div className="display-flex-center">
				<Loader type="Rings" color="#00BFFF" height={80} width={80} />
			</div>
			) : (
			<AddVariableModal attr={attr} {...product} onCloseProduct={onClose} onClose={onClose} submit={handleSubmit} edit className='modal-start' isOpenParent disabledAddSelectProduct/>
			)}
		</>
	);
};

export default connectToStores(EditVariableModal, {ProductsStore});