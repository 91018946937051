import React, {useState} from 'react'
import HistoryStore from "../history-store";
import HistoryActions from "../history-actions";
import FilterPanel from "../common/filter-panel";
import Box from "react-bulma-components/lib/components/box";
import PaginationBlock from "../../../components/pagination";
import Reports from "../../../components/reports";
import HistoryRow from "./history-row";
import T from "../../../components/i18n";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import Button from "react-bulma-components/lib/components/button";

const EmptyMessage = () => {
	return (
		<Box>
			<h2>{T("not-have-history")}</h2>
		</Box>
	);
};

const COLUMNS = ["history-action", "info-short", "comment"];

const HistoryContent = () => {
	const {isMobileSize} = useIsMobile()
	const listHistory = HistoryStore.getPageList(null, null, "history")
	const [isOpenRows, setIsOpenRows] = useState(false)


	return (
		<div className="history-report font-size-80">
			{!isMobileSize && <FilterPanel/>}
			<Box className='border-not-top'>
				{isMobileSize && <FilterPanel/>}
				{listHistory.length > 1 && isMobileSize && (
					<div align="right" style={{ marginBottom: "5px" }}>
						<Button
							onClick={() => setIsOpenRows((prev) => !prev)}
							style={{ fontSize: 10, backgroundColor: "transparent", padding: "3px 10px", lineHeight: 1, margin: 0, height: "auto"}}
						>
							{isOpenRows ? T("hide-more-filter") : T("unfold")}
						</Button>
					</div>
				)}
				<PaginationBlock
					setPage={HistoryActions.setPage}
					totalPage={HistoryStore.get("count_page")}
					currentPage={HistoryStore.get("page")}
				/>
				<Reports
					emptyMessage={EmptyMessage}
					columns={COLUMNS}
					hideFooterOnEmpty
					isLoading={HistoryStore.get("isLoaded")}
					rows={listHistory}
				>
					<HistoryRow role="row" triggerIsOpen={isOpenRows} />
				</Reports>
				<PaginationBlock
					setPage={HistoryActions.setPage}
					totalPage={HistoryStore.get("count_page")}
					currentPage={HistoryStore.get("page")}
				/>
			</Box>
		</div>
	);
};

export default HistoryContent;