import React from "react";
import {
  Checkbox,
  Control,
  Field,
  Input,
  Label,
  Textarea,
} from "react-bulma-components/lib/components/form";
import T from "../../../../components/i18n";
import s from "./styles.module.scss";
import NumberInput from "../../../../components/number-input/number-input";
import { Icon } from "react-bulma-components";

const NotRequiredSettingBlock = ({
  values,
  list,
  onChangeCheckbox,
  onChangeInput,
  styles
}) => {
  const handleChangeCheckbox = ({ target: { name, checked } }) => {
    onChangeCheckbox && onChangeCheckbox(name, checked);
  };
  const handleChangeInput = ({ target: { name, value } }, globalName) => {
    onChangeInput && onChangeInput(name, value, globalName);
  };

  return (
    <div className={s.wrapper} style={styles}>
      {list.map((item) => (
        <React.Fragment key={item.name}>
          <Field>
            <Label size="large">
              <Checkbox
                name={item.name}
                checked={values && !!values[item.name]}
                onChange={handleChangeCheckbox}
              >
                  {T(item.label)}
              </Checkbox>
            </Label>
          </Field>
          {!!values &&
            !!values[item.name] &&
            item.children.map((child) => (
              <Field key={child.name} style={{ width: "100%" }}>
                {child.label && <Label align="left">{T(child.label)}</Label>}
                <Control iconRight={!!child.icon}>
                  {child.type === "number" && (
                    <NumberInput
                      name={child.name}
                      value={values[item.name][child.name]}
                      onChange={(evt) => handleChangeInput(evt, item.name)}
                    />
                  )}
                  {child.type === "textarea" && (
                    <Textarea
                      rows={3}
                      name={child.name}
                      value={values[item.name][child.name]}
                      onChange={(evt) => handleChangeInput(evt, item.name)}
                    />
                  )}
                  {child.type === "text" && (
                    <Input
                      name={child.name}
                      value={values[item.name][child.name]}
                      onChange={(evt) => handleChangeInput(evt, item.name)}
                    />
                  )}
                  {!!child.icon && (
                    <Icon align="right" style={{ top: "3px" }}>
                      <span>{child.icon}</span>
                    </Icon>
                  )}
                </Control>
              </Field>
            ))}
        </React.Fragment>
      ))}
    </div>
  );
};

export default NotRequiredSettingBlock;
