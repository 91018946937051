import React from 'react'
import UserStore from "../../user/user-store";
import { LocalIcon } from 'whitelables/wl-name/icons'

const ScanSelectItem = () => {
	const handleBarcodeIconClick = (event) => {
		event.stopPropagation();
		UserStore.postMessage("init-scan");
	};
	return UserStore.isMobileApp() && <div style={{ display: "flex", alignItems: "center" }}>
			<div
				onClick={handleBarcodeIconClick}
				onTouchStart={handleBarcodeIconClick}
			>
				<LocalIcon icon="barcode-scan" width={14} />
			</div>
		</div>
};

export default ScanSelectItem;