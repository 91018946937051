import yajax from "yajax";
import React from "react";
import { createStore } from "../../tools/reflux-tools";
import Actions from "./sales-actions";
import ProductActions from "../products/products-actions";
import ShipmentsActions from "../shipments/shipments-actions";
import ProductsStore from "../products/products-store";
import LightStore from "../products/light-store";
import OrdersStore from "../orders/orders-store";
import ExpensesActions from "../expenses/expenses-actions";
import UserActions from "user/user-actions";
import User from "user/user-store";
import { LocalIcon } from "whitelables/wl-name/icons";

import AppStore from "../../app-store";
import { filterSearch } from "tools/filters-helper";
// import ClientsActions from 'modules/clients/clients-actions';
import T from "../../components/i18n";
// import ClientsStore from "../clients/clients-store";
import UserStore from "user/user-store";
import { formatDateString, getDatePeriod } from "../../tools/date-range";
import ClientsStore from "../clients/clients-store";
import { parseJSON } from "../../tools/error-handler";
import { checkAndParseInt } from "../../tools/check-and-parse-int/check-and-parse-int";
import ExpensesStore from "../expenses/expenses-store";
import calcMargin from "../../tools/calc-margin";
import { calcDiffPeriodDate } from "../../tools/calc-diff-period-date/calc-diff-period-date";
import { formattedDate } from "../../tools/formatted-date-dd-mm-yy";
import {
  gmtToUtcTimestamp
} from "../../tools/gmt-to-utc-timestamp/gmt-to-utc-timestamp";

const RENAME_FIELD = [
    ["purchase_date", "g"],
    ["date_ident", "d"],
    ["amount", "a"],
    ["oid", "o"],
    ["amount_sale", "s"],
    ["prepaid_amount", "p"],
    ["responsible_user_id", "r"],
    ["channel_id", "c"],
    ["client_id", "j"],
    ["comment", "q"],
    ["discount", "w"],
    ["discount_type", "t"],
    ["order_status", "z"],
    ["account_id", "y"],
    ["prapaid_account_id", "v"],
    ["clients_delivery_id", "l"],
    ["tracking_number", "n"],
    ["delivery_info", "b"],
    ["dps", "f"],
    ["fee", "fe"],
    ["currency", "u"],
    ["remote_order_id", "ro"],
    ["seller_order_id", "so"],
    ["seller_order_text", "sot"],
    ["order_number", "on"],
    ["af", "af"],
    ["terminal_trx", "tx"],
    ['cash_from_client','cc']
  ],
  ITEM_FIELD = [
    ["oiid", "o"],
    ["is_refund", "i"],
    ["quantity", "q"],
    ["barcode", "b"],
    ["product_id", "p"],
    ["parent_id", "a"],
    ["net_price", "n"],
    ["price", "r"],
    ["amount", "m"],
    ["profit", "f"],
    ["item_profit", "t"],
    ["mid", "y"],
    ["product_name", "d"],
    ["product_deleted", "e"],
    ["tax", "x"],
    ["channel_tax", "ct"],
    ["base_price", "bp"],
    ["type_price", "tp"],
    ["discount", "di"],
  ];

const CLEAR_FILTER = () => ({
  status: "all",
  date_start: new Date(),
  date_end: new Date(),
  account: { value: 0, label: T("all-account") },
  clients: { value: 0, label: T("all") },
  brand: { value: 0, label: T("all-brands") },
  channel: { value: 0, label: T("all") },
  mid: { value: 0, label: T("all") },
  rid: { value: 0, label: T("all") },
  category: { value: 0, label: T("all-categories") },
  search: "",
  delivery: { value: 0, label: T("all") },
  deliveryCity: { value: 0, label: T("all") },
  deliveredKeeperDate: { value: 0, label: T("all") },
  commission: { value: 0, label: T("all") },
  fiscal: { value: 0, label: T("all") },
  dateStartReport: getDatePeriod(93),
  tags: {value: 0,label: T('all')},
});

const SalesStore = createStore({
  listenables: Actions,
  _prefix: "/bsales/",
  _report_prefix: "/bsales-tr/",
  print_ttn: "/bprint/ttn",
  state: {
    sales: null,
    dateInit: new Date(),
    channels: null,
    refundRows: [],
    client_sale: {},
    dictDeliveredKeeperDate: {},
    counting: {
      delivery: 0,
      reserve_sale: 0,
      delivering: 0,
      refund: 0,
      saled: 0,
      cod: 0,
    },
    sales_reports: null,
    openModal: false,
    dataModal: {},
    isLoaded: false,
    isLoadedReport: false,
    _filter: CLEAR_FILTER(),
  },
  failedLoad: 0,
  timeoutSales: null,
  statuses: [
    "saled",
    "delivery",
    "delivering",
    "on-delivering",
    "arrived",
    "cod",
    "refund",
  ],

  get(field) {
    return this.state[field];
  },

  clearFilter(isDate, isStatus) {
    const _filter = {
      status: "all",
      date_start: new Date(),
      date_end: new Date(),
      account: { value: 0, label: T("all-account") },
      clients: { value: 0, label: T("all") },
      brand: { value: 0, label: T("all-brands") },
      channel: { value: 0, label: T("all") },
      mid: { value: 0, label: T("all") },
      rid: { value: 0, label: T("all") },
      category: { value: 0, label: T("all-categories") },
      search: "",
      delivery: { value: 0, label: T("all") },
      deliveryCity: { value: 0, label: T("all") },
      deliveredKeeperDate: { value: 0, label: T("all") },
      commission: { value: 0, label: T("all") },
      fiscal: { value: 0, label: T("all") },
      dateStartReport: getDatePeriod(93),
      tags: {value: 0,label: T('all')},
    };
    if (isDate) {
      _filter.date_start = this.state._filter.date_start;
    }
    if (isStatus) {
      _filter.status = this.state._filter.status;
    }

    this.setState({
      _filter,
    });
  },

  filterClearField({ globalName, filterName }) {
    const filter = this.get("_filter");
    if (globalName) {
      this.setState({
        _filter: {
          ...filter,
          [globalName]: {
            ...filter[globalName],
            [filterName]: { value: 0 },
          },
        },
      });
    } else {
      this.setState({
        _filter: {
          ...filter,
          [filterName]: CLEAR_FILTER()[filterName],
        },
      });
    }
  },

  printTTN(ttn, format) {
    return (
      this.print_ttn +
      "?ttn=" +
      ttn +
      "&format=" +
      (format || "standart") +
      "&token=" +
      encodeURIComponent(User.getToken())
    );
  },

  closeModal() {
    const state = {
      openModal: false,
      dataModal: {},
    };

    this.setState(state);
    AppStore.set(state);
    AppStore.closeModal();
  },

  onOpenSaleModal(data) {
    if (AppStore.get("openModal")) {
      return;
    }

    let state = { openModal: true };
    if (typeof data === "object") {
      state["dataModal"] = data;
    }

    this.setState(state);
    AppStore.set(state);
    User.postMessage("hide-barcode");
  },

  getFilter(field) {
    return this.state["_filter"][field];
  },

  getFilters() {
    return this.state["_filter"];
  },

  onFilters(filters) {
    this.setState({
      _filter: filters,
    });
  },

  onFilter(name, value, isSaleTab) {
    let filters = {};

    for (let i in this.state["_filter"]) {
      filters[i] = this.state["_filter"][i];
    }

    let date_start = filters["date_start"];

    filters[name] = value;

    this.setState({
      _filter: filters,
    });

    if (name === "date_start" && value < date_start) {
      Actions.load(false, isSaleTab);
      // Actions.loadRefund()
    }
  },

  onFilterRefund(name, value) {
    let filters = {};

    for (let i in this.state["_filter"]) {
      filters[i] = this.state["_filter"][i];
    }

    let date_start = filters["date_start"];

    filters[name] = value;

    this.setState({
      _filter: filters,
    });

    if (name === "date_start" && value < date_start) {
      Actions.loadRefund();
    }
  },

  getCalcStatisticsPlusAfterPercentage(dataNow, tab) {
    const getSales = this.getSalesList();
    const getFilter = this.get("_filter");

    const calcPrevDate = calcDiffPeriodDate(
      getFilter.date_start,
      getFilter.date_end
    );

    const withFilter = getSales.filter((item) => {
      return this.applyFilter(item, false, false, calcPrevDate);
    });
    const calcGetStatisticsNow = this.getCalcStatistics(dataNow, tab);
    const calcGetStatisticsAfter = this.getCalcStatistics(withFilter, tab);

    const calcPercentage = (newAmount, afterAmount) => {
      const after = parseFloat(isNaN(afterAmount) ? 0 : afterAmount);
      const before = parseFloat(isNaN(newAmount) ? 0 : newAmount);

      if (!after && before) {
        return 100;
      } else if (!before && after) {
        return -100;
      } else if (!before && !after) {
        return 0;
      }

      return 100 - (100 * after) / before;
    };
    return {
      amount: {
        value: calcGetStatisticsNow.amount,
        percentage: calcPercentage(
          calcGetStatisticsNow.amount,
          calcGetStatisticsAfter.amount
        ),
      },
      profit: {
        value: calcGetStatisticsNow.profit,
        percentage: calcPercentage(
          calcGetStatisticsNow.profit,
          calcGetStatisticsAfter.profit
        ),
      },
      discount: {
        value: calcGetStatisticsNow.discount,
        percentage: calcPercentage(
          calcGetStatisticsNow.discount,
          calcGetStatisticsAfter.discount
        ),
      },
      not_paid: {
        value: calcGetStatisticsNow.not_paid,
        percentage: calcPercentage(
          calcGetStatisticsNow.not_paid,
          calcGetStatisticsAfter.not_paid
        ),
      },
      quantity: {
        value: calcGetStatisticsNow.quantity,
        percentage: calcPercentage(
          calcGetStatisticsNow.quantity,
          calcGetStatisticsAfter.quantity
        ),
      },
      net_price: {
        value: calcGetStatisticsNow.net_price,
        percentage: calcPercentage(
          calcGetStatisticsNow.net_price,
          calcGetStatisticsAfter.net_price
        ),
      },
      count: {
        value: calcGetStatisticsNow.count,
        percentage: calcPercentage(
          calcGetStatisticsNow.count,
          calcGetStatisticsAfter.count
        ),
      },
      margin: {
        value: calcGetStatisticsNow.margin,
        percentage: calcPercentage(
          calcGetStatisticsNow.margin,
          calcGetStatisticsAfter.margin
        ),
      },
      commission: {
        value: calcGetStatisticsNow.commission,
        percentage: calcPercentage(
          calcGetStatisticsNow.commission,
          calcGetStatisticsAfter.commission
        ),
      },
      dateStartAfter: formattedDate(calcPrevDate.start),
      dateEndAfter: formattedDate(calcPrevDate.end),
      amountWithFilterAccount: calcGetStatisticsNow.amountWithFilterAccount,
    };
  },

  applyFilter(sale, withoutStatus, list, filterDate) {
    const filter = this.state._filter,
      date = new Date(sale["purchase_date"] * 1000);
    let result = true;

    const dayNow = new Date();
    const checkDayNow = dayNow.getDate();
    const checkDayNowFilter = this.get("dateInit").getDate();

    if (checkDayNow !== checkDayNowFilter) {
      Actions.filter("date_end", dayNow);
      this.setState({ dateInit: dayNow });
      return false;
    }
    if (filterDate && filterDate.start && filterDate.end) {
      filterDate.start.setHours(0, 0, 0, 0);
      filterDate.end.setHours(23, 59, 59, 999);
      if (date < filterDate.start || date > filterDate.end) {
        return false;
      }
    } else if(filter["date_start"] && filter["date_end"]){
      filter["date_start"].setHours(0, 0, 0, 0);
      filter["date_end"].setHours(23, 59, 59, 999);

      if (date < filter["date_start"] || date > filter["date_end"]) {
        return false;
      }
    } else {
      return false
    }

    if (withoutStatus !== "refund") {
      if (filter["status"] !== "all") {
        if (typeof filter["status"] == "object") {
          let r_s = false;
          for (let i in filter["status"]) {
            let status = filter["status"][i];
            if (status === `${sale["order_status"]}`) {
              r_s = r_s || true;
            }
          }
          if (!r_s) {
            return false;
          }
        } else {
          if (filter["status"] !== `${sale["order_status"]}`) {
            return false;
          }
        }
      }
    }
    if (filter["search"] !== "" && typeof filter["search"] !== "undefined") {
      let search_data = {
        barcode: "",
        sku: "",
        product_name: "",
        order_number:
          sale["order_number_text"] || sale["order_number"] || sale["id"],
        comment: sale["comment"],
        tracking_number: sale["tracking_number"],
        oid: sale["oid"],
        pids: "",
      };

      for (let i in sale["items"]) {
        let item = sale["items"][i];
        let pr_d = ProductsStore.getProduct(item["product_id"]);
        item["product_name"] = pr_d["name"];
        search_data["pids"] += ` ${item["product_id"]}`;
        search_data["barcode"] += " " + item["barcode"];
        search_data["sku"] += " " + pr_d["skus"];
        search_data["product_name"] +=
          " " + sale["oid"] + " " + item["product_name"];
      }

      result = filterSearch(
        filter["search"],
        [
          "barcode",
          "tracking_number",
          "product_name",
          "oid",
          "sku",
          "comment",
          "order_number",
          "pids",
        ],
        search_data
      );
    }

    if (filter["account"] && filter["account"]["value"] !== 0) {
      if (
        parseInt(filter["account"]["value"]) !== parseInt(sale["account_id"])
      ) {
        if (
          parseInt(filter["account"]["value"]) !==
          parseInt(sale["prapaid_account_id"])
        ) {
          result = result && false;
        }
      }
    }

    if (filter["deliveryCity"] && filter["deliveryCity"]["value"] !== 0) {
      if (sale.clients_delivery_id) {
        result =
          result &&
          ClientsStore.hasDeliveryIdForCity(
            sale.clients_delivery_id,
            filter["deliveryCity"]["value"]
          );
      } else {
        result = result && false;
      }
    }

    if (filter["delivery"] && filter["delivery"]["value"] !== 0) {
      if (
        checkAndParseInt(filter["delivery"]["value"]) !==
        checkAndParseInt(sale["delivery_info"])
      ) {
        result = result && false;
      }
    }

    if (filter["channel"] && filter["channel"]["value"] !== 0) {
      if (
        sale["channel_id"].indexOf(parseInt(filter["channel"]["value"])) === -1
      ) {
        result = result && false;
      }
    }

    if (filter["rid"] && filter["rid"]["value"] !== 0) {
      if (
        parseInt(filter["rid"]["value"]) !==
        parseInt(sale["responsible_user_id"])
      ) {
        result = result && false;
      }
    }

    if (filter["clients"] && filter["clients"]["value"] !== 0) {
      if (
        parseInt(filter["clients"]["value"]) !== parseInt(sale["client_id"])
      ) {
        result = result && false;
      }
    }

    if(filter['tags'] && filter['tags'].value) {
      if(parseInt(sale["client_id"])) {
        const client = ClientsStore.getClientById(sale["client_id"])
        result = result && client.tags.includes(parseInt(filter['tags'].value))
      } else {
        result = result && false;
      }
    }


    if (filter["mid"] && filter["mid"]["value"] !== 0) {
      let warehouse = false;
      for (let i in sale["items"]) {
        let item = sale["items"][i];
        if (parseInt(filter["mid"]["value"]) === parseInt(item["mid"])) {
          warehouse = true;
        }
      }

      result = result && warehouse;
    }

    if (filter["category"] && filter["category"]["value"] !== 0) {
      let category = false;
      for (let i in sale["items"]) {
        let item = sale["items"][i],
          product = ProductsStore.getProduct(item["product_id"]);

        let ccat = filter["category"]["value"];
        let childs_category = ProductsStore.get("categoryChild")[ccat];
        if (typeof childs_category === "undefined") {
          childs_category = [ccat];
        }

        if (childs_category.indexOf(product["category_id"]) > -1) {
          category = true;
        }
      }

      result = result && category;
    }

    if (
      filter["deliveredKeeperDate"] &&
      filter["deliveredKeeperDate"]["value"] !== 0
    ) {
      if (sale.order_status === "arrived") {
        const isDpk = this.getDeliveredKeeperDateByOrderId(sale.oid);
        result = result && isDpk === filter["deliveredKeeperDate"]["value"];
      } else {
        result = result && false;
      }
    }

    if(filter["commission"] && !!filter['commission'].value) {
      if(filter["commission"].value === 'with-commission') {
        result = result && (!!sale.fee || !!sale.items[0].tax);
      } else if(filter["commission"].value === 'without-commission') {
        result = result && (!sale.fee && !sale.items[0].tax);
      }
    }

    if(filter["fiscal"] && !!filter['fiscal'].value) {
      if(filter["fiscal"].value === 'with-fiscal') {
        result = result && sale.dps
      } else if(filter["fiscal"].value === 'without-fiscal') {
        result = result && !sale.dps
      }
    }

    return result;
  },

  getStartFrom(getDate) {
    let start_date = SalesStore.getFilter("dateStartReport");

    const now = new Date();

    let dateToUse = start_date;


    // Форматирование даты в виде ДД.ММ
    if(dateToUse) {
      const day = String(dateToUse.getDate()).padStart(2, "0");
      const month = String(dateToUse.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0 в JavaScript

      if(getDate) {
        return dateToUse
      }

      const year = dateToUse.getFullYear();
      const currentYear = now.getFullYear();

      return `${day}.${month}` + (year !== currentYear ? `.${year}` : "");
    }
  },

  getExport(_type) {
    // let uid = User.get("id");

    let prepare_filter = {};
    for (let i in this.state._filter) {
      let value = this.state._filter[i];
      if (typeof value?.value !== "undefined") {
        value = value["value"];
      }
      if(i === 'dateStartReport') {
        continue
      }

      if (i === "date_start" || i === "date_end") {
        const date = value
        if(date) {
          if (i === "date_end") {
            date.setHours(23, 59, 59, 999);
          } else if (i === "date_start") {
            date.setHours(0, 0, 0, 0);
          }

          value = parseInt(value.getTime() / 1000);
        }
      }

      if (value === 0 || value === "") continue;

      prepare_filter[i] = value;
    }

    prepare_filter["token"] = User.getToken();

    prepare_filter["format"] = _type;

    const addPrint = UserStore.getMoreSetting('addPrint')

    if(addPrint && addPrint.saleBrand) {
      prepare_filter['print_brand'] = 1
    }

    const date = new Date()
    const timeZoneMilli = date.getTimezoneOffset()
    const timeZoneHours = timeZoneMilli / 60

    prepare_filter["tz"] = -timeZoneHours

    let url = new URLSearchParams(prepare_filter).toString();
    let prefix = "/bprint/sales?";

    // if (_type == "xls") {prefix = '${ProductsStore._prefix_remote_csv}get_csv_sales?'}
    return prefix + url;
  },

  getUrlPrintSlip(oid, _type, doc) {
    if (typeof _type === "undefined") {
      _type = "pdf";
    }
    if (typeof doc === "undefined") {
      doc = "packing";
    }
    return (
      "/bprint/getslip?oid=" +
      oid +
      "&doc=" +
      doc +
      "&format=" +
      _type +
      "&lang=" +
      User.get("lang") +
      "&uid=" +
      User.get("id") +
      "&token=" +
      encodeURIComponent(User.getToken())
    );
  },

  getCheckboxPrintSlip(oid, type_file) {
    if (typeof type_file === "undefined") {
      type_file = "png";
    }

    if (type_file === "png") {
      type_file += "?qrcode_scale=55"
    }

    return (
      "https://api.checkbox.in.ua/api/v1/receipts/" + oid + "/" + type_file
    );
  },

  getVchasnoKasaPrintUrl(checkId, type) {
    return `https://kasa.vchasno.ua/c/${checkId}${type ? `.${type}` : ""}`;
  },

  getConvertPdfToImage(url) {
    if (url) {
      return `/bimages/get_image_check?url=${btoa(
        url
      )}&token=${encodeURIComponent(User.getToken())}`;
    }
  },

  onGetPrintFiscalCheck(oid) {
    yajax
      .get("https://api.checkbox.in.ua/api/v1/receipts/" + oid + "/png?qrcode_scale=75")
      .then(
        Actions.getPrintFiscalCheck.completed,
        Actions.getPrintFiscalCheck.failed
      );

    // const params = {
    //   method: "GET",
    //   timeout: 1000,
    //   url: "https://api.checkbox.in.ua/api/v1/receipts/"+oid+"/html",
    // };

    // return yajax(params).then((a) => console.log(a), (b) => console.log(b));
  },

  getSales() {
    const sales = this.getSalesList();
    return sales.filter(this.applyFilter);
  },

  getSalesList() {
    const sales = this.get("sales");
    if (sales === null) {
      Actions.load();
      return [];
    }

    return sales;
  },

  getStatusList() {
    let response = [];
    for (let s in this.statuses) {
      let status = this.statuses[s];
      response.push({
        id: status,
        name: "status-" + status,
      });
    }

    return response;
  },

  getSalesWithoutSaled(ids) {
    const sales = this.get("sales");
    return sales?.reduce((acc, cur) => {
      if (ids?.includes(`${cur.oid}`)) {
        if (
          cur.order_status !== "saled" &&
          cur.order_status !== "cod" &&
          cur.order_status !== "delivering" &&
          cur.order_status !== "reserve_sale"
        ) {
          acc.push(cur.oid);
        }
      }

      return acc;
    }, []);
  },

  getSalesWithTTN(ids) {
    const sales = this.get("sales");
    return sales?.reduce((acc, cur) => {
      if (ids?.includes(`${cur.oid}`)) {
        if(cur.tracking_number) {
          acc.push(cur);
        }
      }

      return acc;
    }, []);
  },

  getSalesWithoutSaledWithDeliveringRejection(ids) {
    const sales = this.get("sales");
    return sales?.reduce((acc, cur) => {
      if (ids?.includes(`${cur.oid}`)) {
        if (cur.order_status !== "saled" && cur.order_status !== "cod" && cur.order_status !== "refund" && cur.order_status !== "reserve_sale") {
          acc.push(cur.oid);
        }
      }

      return acc;
    }, []);
  },

  getSalesByReserv(ids) {
    const sales = this.get("sales");
    return sales?.reduce((acc, cur) => {
      if (ids?.includes(`${cur.oid}`)) {
        if (cur.order_status === "reserve_sale") {
          acc.push(cur.oid);
        }
      }

      return acc;
    }, []);
  },

  getSalesCODWithIds(ids) {
    const sales = this.get("sales");

    return sales?.reduce((acc, cur) => {
      if (ids?.includes(`${cur.oid}`)) {
        if (cur.order_status === "cod") {
          acc.push(cur);
        }
      }

      return acc;
    }, []);
  },

  getSalesByIdsWithoutCOD(ids) {
    const sales = this.get("sales");
    return (
      sales?.filter((item) => {
        if (item.order_status !== "cod") {
          return ids?.includes(`${item.oid}`);
        }
        return false;
      }) || []
    );
  },

  getSalesByDate(start, end) {
    const old_start = this.state._filter["date_start"],
      old_end = this.state._filter["date_end"],
      old_status = this.state._filter["status"];

    this.onFilter("status", this.statuses);

    this.state._filter["date_start"] = start;
    this.state._filter["date_end"] = end;

    const sales = this.getSales();

    this.state._filter["date_start"] = old_start;
    this.state._filter["date_end"] = old_end;
    this.state._filter["status"] = old_status;

    return sales;
  },

  getSalesByPid(pid) {
    const sales = this.getSalesList();

    let response = [];
    sales.map((sale) => {
      for (let i in sale["items"]) {
        let item = sale["items"][i];
        if (parseInt(item["product_id"]) === parseInt(pid)) {
          item["purchase_date"] = sale["purchase_date"];
          item["channel_id"] = sale["channel_id"];
          item["account_id"] = sale["account_id"];
          item["responsible_user_id"] = sale["responsible_user_id"];
          item["order_status"] = sale["order_status"];
          item["prapaid_account_id"] = sale["prapaid_account_id"];
          item["id"] = sale["oid"];
          response.push(item);
        }
      }

      return {};
    });

    return response;
  },

  getSalesByPidWithFilter(pid) {
    const list = this.getSalesByPid(pid);
    return list.filter(this.applyFilter);
  },

  getSalesByPidWithDateFilter(pid) {
    const list = this.getSalesByPid(pid);



    return list.filter(sale => {
      const date = new Date(sale["purchase_date"] * 1000);
      const filter = this.state._filter
      filter['date_start'].setHours(0, 0, 0, 0);
      filter['date_end'].setHours(23, 59, 59, 999);


      if (date < filter['date_start'] || date > filter['date_end']) {
        return false;
      }
      return true
    })
  },

  getSalesByClientId(client_id) {
    if (typeof this.state.client_sale[client_id] === "undefined") {
      Actions.getSalesByClientId(client_id);
    }
    return this.state.client_sale[client_id] || [];
  },

  onGetSalesByClientId(client_id, date_start, date_end) {
    if (this.state.isLoadingClientSales) return;
    this.setState({
      isLoadingClientSales: true,
    });
    if (!client_id) return;

    const data = {
      client_id,
    };

    if (date_start) {
      const date_start_set = date_start.setHours(0, 0, 0, 0);
      data.start = parseInt(`${date_start_set / 1000}`);
    }

    if (date_end) {
      const date_end_set = date_end.setHours(23, 59, 59, 999);
      data.end = parseInt(`${date_end_set / 1000}`);
    }

    return yajax
      .get(this._prefix + "sale", data)
      .then(
        (data) => Actions.getSalesByClientId.completed(data, client_id),
        Actions.getSalesByClientId.failed
      );
  },

  onGetSalesByClientIdCompleted(data, client_id) {
    let response = [];
    if (data["success"]) {
      response = this.proccessingSales(data);
    }

    let clients = this.state.client_sale;
    clients[client_id] = response;

    console.log("success load ", clients);
    this.setState({
      client_sale: clients,
      isLoadingClientSales: false,
    });
  },

  onGetSalesByClientIdFailed() {
    console.log("failed load sales by client id");
    this.setState({
      isLoadingClientSales: false,
    });
  },

  getSalesByOid(oid) {
    const sales = this.getSalesList();
    let response = {};
    for (let i in sales) {
      let sale = sales[i];
      if (parseInt(sale["oid"]) === parseInt(oid)) {
        response = sale;
      }
    }

    return response;
  },

  checkOnBoarding() {
    if (User.getOnBoaringStep() === 3 && this.get("sales")) {
      if (this.get("sales").length > 0) {
        User.setOnBoaringStep(4);
      }
    }
  },

  getCountDelivery() {
    // const sales = this.get("counting");

    // let counting = {
    //     'delivery': 0,
    //     'reserve_sale': 0,
    //     'delivering': 0,
    //     'refund': 0,
    //     'cod': 0
    // }

    // for (let i in sales) {
    //     const sale = sales[i];

    //     switch (sale['order_status']) {
    //         case 'delivery':
    //             counting['delivery']++;
    //             break;
    //         case 'reserve_sale':
    //             counting['reserve_sale']++;
    //             break;
    //         case 'delivering':
    //         case 'arrived':
    //         case 'on-delivering':
    //             counting['delivering']++;
    //             break;
    //         case 'refund':
    //             counting['refund']++;
    //             break;
    //         case 'cod':
    //             counting['cod']++;
    //             break;
    //         default:
    //             break;
    //     }
    // }

    return this.get("counting");
  },

  getChannelsList() {
    const channels = this.get("channels");
    if (channels === null) {
      Actions.loadChannels();
      return [];
    }

    return channels;
  },

  getChannelListWithoutIntegration(ignoreId) {
    const list = this.getChannelsList()

    return list.filter(item => !item?.integration_id || (ignoreId && parseFloat(item.id) === parseFloat(ignoreId)));
  },

  getChannelById(id) {
    if (!id) return false;

    const channels = this.get("channels");
    return channels.find((item) => item.id === id) || false;
  },

  getChannelCommission(id) {
    const channels = this.get("channels");
    const findChannel = channels.find((item) => item.id === id);

    return { fee: findChannel?.fee || 0, id };
  },

  getChannelsClass(cid) {
    const channels = this.getChannelsList();

    let name = "channal_color_0";
    for (let i in channels) {
      const channel = channels[i];
      if (parseInt(channel["id"]) === cid) {
        name = "channal_color_" + i;
        break;
      }
    }

    name += " channel_block";
    return name;
  },

  getChannelsIndexColor(cid) {
    const channels = this.getChannelsList();

    let indexColor = 0;
    for (let i in channels) {
      const channel = channels[i];
      if (parseInt(channel["id"]) === cid) {
        indexColor = i;
        break;
      }
    }

    return indexColor;
  },

  getChannelColor(cid) {
    const channels = this.getChannelsList();

    let channelsColor;

    for (let i in channels) {
      const channel = channels[i];
      if (parseInt(channel["id"]) === cid) {
        channelsColor = channel["color"] || null;
        break;
      }
    }
    return channelsColor;
  },

  foundChannelsByIntegrationId(iid) {
    const channels = this.getChannelsList();
    for (let i in channels) {
      const channel = channels[i];
      if (parseInt(channel["integration_id"]) === iid) {
        return channel["id"];
      }
    }
  },

  getChannelsName(cid, is_ff) {
    const def = "not known";

    if (!cid) return def;

    const channels = this.getChannelsList();

    for (let i in channels) {
      const channel = channels[i];
      if (parseInt(channel["id"]) === cid) {
        let name = channel["name"];

        if (is_ff) {
          name += " FF";
        }
        return name;
      }
    }

    return def;
  },

  setAccountInSale(oid,toAccountID, isPrepaid) {
    const sales = this.getSalesList();

    const changeAccountSales = sales.map((item) => {
      if (item.oid === oid) {
        if(!isPrepaid) {
          item.account_id = toAccountID;
        } else if(isPrepaid) {
          item.prapaid_account_id = toAccountID;
        }
      }
      return item;
    });

    this.setState({
      sales: changeAccountSales,
    });
  },

  onAddChannels(data) {
    console.log("start add channels", data);
    return yajax
      .post(this._prefix + "channels", JSON.stringify(data))
      .then(Actions.addChannels.completed, Actions.addChannels.failed);
  },

  onAddChannelsCompleted(data) {
    console.log("result add channel: ", data);
    Actions.loadChannels();
  },

  onAddChannelsFailed() {
    console.log("failed add channel");
  },

  onRefund(data) {
    console.log("start refund sale", data);
    return yajax
      .post(this._prefix + "refund", data)
      .then(
        (res) => Actions.refund.completed(res, data),
        Actions.refund.failed
      );
  },

  onRefundCompleted(res, data) {
    console.log("result refund sales: ", res);
    // setTimeout(Actions.load, 1500);
    console.log(data)
    if (data.oid && !data.oiid) {
      this.deleteSaleByOid(data.oid);
    } else {
      Actions.loadByOid(data.oid);
    }

    ProductActions.load(true);
    ExpensesActions.loadAccounts();
    ExpensesActions.load(true);
    Actions.loadReports();
  },

  onRefundFailed(res) {
    let error = JSON.parse(res.response);

    if (error.error === "HTTP 400: Bad Request (order already deleted)") {
      Actions.load(true);
    }
    console.log("failed refund");
  },

  onCancelReserv(data) {
    console.log("start cancel reserv", data);

    const params = {
      method: "DELETE",
      url: this._prefix + "reserv",
      data: data,
    };

    return yajax(params).then(
      Actions.cancelReserv.completed,
      Actions.cancelReserv.failed
    );
  },

  onCancelReservCompleted(data) {
    console.log("result cancel reserv: ", data);
    Actions.load();
    ExpensesActions.loadAccounts();
    ProductActions.load(true);
    Actions.loadReports();
  },

  onCancelReservFailed() {
    console.log("failed cancel reserv");
  },

  onFinishReserv(data) {
    console.log("start finish reserv", data);
    return yajax
      .post(this._prefix + "reserv", data)
      .then(Actions.finishReserv.completed, Actions.finishReserv.failed);
  },

  onFinishReservCompleted(data) {
    console.log("result finish reserv: ", data);
    setTimeout(() => Actions.load(), 1000);
    ProductActions.load(true);
    ExpensesActions.loadAccounts();
    ExpensesActions.load(true);
    Actions.loadReports();
  },

  onFinishReservFailed() {
    console.log("failed finish reserv");
  },

  onFinishParcel(oid, bulk, type) {
    console.log("start finish parcel", oid);
    let data = {
      oid,
    };

    if (bulk?.length) {
      data = { bulk: JSON.stringify(bulk) };
    }

    if (type) {
      data.type = type;
    }

    return yajax
      .post(this._prefix + "delivery/finish", data)
      .then((res) => Actions.finishParcel.completed(res, bulk), Actions.finishParcel.failed);
  },

  onFinishParcelCompleted(data, bulk) {
    console.log("result finish parcel: ", data);

    if(bulk) {
      Actions.loadByOid(bulk);
    } else {
      Actions.loadByOid(data.oid);
    }
    // setTimeout(Actions.load, 100);
    // ProductActions.load(true);
    ExpensesActions.loadAccounts();
    ExpensesActions.load(true);
    Actions.loadReports();
  },

  onFinishParcelFailed() {
    console.log("failed finish parcel");
  },

  onAddTrackingNumber(oid, delivery_id, tracking_number, massAdd) {
    console.log("start save tracking_number", oid, tracking_number);
    let request = {
      oid: oid,
      did: delivery_id,
      tn: tracking_number,
    };

    if (massAdd?.length) {
      request = { bulk: JSON.stringify(massAdd) };
    }

    const params = {
      method: "PUT",
      url: this._prefix + "tracking",
      data: request,
    };

    return yajax(params).then(
      (data) => Actions.addTrackingNumber.completed(data, oid, massAdd),
      Actions.addTrackingNumber.failed
    );
  },

  onAddTrackingNumberCompleted(data, oid, massOid) {
    if (massOid?.length) {
      Actions.loadByOid(massOid.map((item) => item.oid));
    } else if (oid) {
      Actions.loadByOid(oid);
    }
    console.log("result finish add tracking number: ", data);
  },

  onAddTrackingNumberFailed() {
    console.log("failed finish dd tracking number");
  },

  onCreateTrackingNumber(data) {
    console.log("start create tracking_number", data);
    return yajax
      .post(this._prefix + "tracking", {
        data: JSON.stringify(data),
      })
      .then(
        Actions.createTrackingNumber.completed,
        Actions.createTrackingNumber.failed
      );
  },

  onCreateTrackingNumberCompleted(data) {
    console.log("result finish create tracking number: ", data);
  },

  onCreateTrackingNumberFailed() {
    console.log("failed finish create tracking number");
  },

  onLoadChannels(data) {
    if (this.loadingChannels) return;
    console.log("start load channels...", data);

    this.loadingChannels = true;
    return yajax
      .get(this._prefix + "channels", JSON.stringify(data))
      .then(Actions.loadChannels.completed, Actions.loadChannels.failed);
  },

  onLoadChannelsCompleted(data) {
    this.loadingChannels = false;
    if (data["success"]) {
      console.log("complate load channels");
      this.setState({
        channels: data["data"],
      });
    }
  },
  onLoadChannelsFailed() {
    this.loadingChannels = false;
    console.log("failed add sale");
  },

  onAdd(data, isRefund) {
    console.log("start add sale", data);
    this.setState({
      client_sale: {},
    });

    return yajax
      .post(this._prefix + "sale", JSON.stringify(data))
      .then(
        (res) => Actions.add.completed(res, data.client_id, isRefund),
        Actions.add.failed
      );
  },

  onAddCompleted(data, client_id, isRefund) {
    Actions.loadByOid(data.oid);
    UserActions.getCurrentStateTarif();
    ExpensesActions.loadAccounts();
    Actions.deliveredKeeperDate();
    if(isRefund) {
      Actions.loadRefund()
    }

    setTimeout(() => ShipmentsActions.load(true), 1000);
    setTimeout(() => {
      Actions.loadReports();
    }, 3000);

    LightStore.clearSelectGroup();

    if (client_id) {
      setTimeout(() => ClientsStore.addCountSale(client_id), 1000);
    }
  },

  onAddFailed(e) {
    console.log("failed add sale", e);
    let error = parseJSON(e.response);
    if (
      error.error === "HTTP 400: Bad Request (Problems with quantity products)"
    ) {
      ProductActions.load(true);
    }
    if (error.error === "HTTP 400: Bad Request (order already deleted)") {
      Actions.load(true);
    }
  },

  onSetToDps(oid, prepaidComment, type, iid) {
    console.log("start send to DPS", oid);
    const result = {
      oid,
    };
    if (prepaidComment) {
      result.prepaid = prepaidComment;
    }
    if (type) result.type = type;
    if (iid) result.iid = iid;

    return yajax
      .post(this._prefix + "check", result)
      .then(
        (data) => Actions.setToDps.completed(data, oid),
        Actions.setToDps.failed
      );
  },

  onSetToDpsCompleted(data, oid) {
    if (oid) {
      Actions.loadByOid(oid);
    }
    console.log("result send to dps: ", data);
  },

  onSetToDpsFailed() {
    console.log("failed send to DPS");
  },

  getStatusById(id) {
    const list = this.getAllStatusesList();

    return list.find((item) => `${item.id}` === `${id}`);
  },

  getTabList(isOfflineShop) {
    const allTab = {
      label: "all",
      value: "all",
      url: "",
      state: { period: new Date() },
      is_global: 1,
    };
    const defaultTabs = [
      {
        label: "saled",
        value: "saled",
        url: "saled",
        state: { period: new Date() },
        is_global: 1,
        position: 1,
      },
      {
        label: "reserv",
        value: "reserve_sale",
        url: "reserv",
        state: { period: getDatePeriod(93) },
        is_global: 1,
        position: 5,
      },
      {
        label: "wait-delivery",
        value: "delivery",
        url: "delivery",
        state: { period: getDatePeriod(93) },
        is_global: 1,
        position: 2,
      },
      {
        label: "delivering",
        value: "delivering",
        url: "delivering",
        state: { period: getDatePeriod(93) },
        is_global: 1,
        position: 3,
      },
      {
        label: "need-make-cod",
        value: "cod",
        url: "cod",
        state: { period: getDatePeriod(93) },
        is_global: 1,
        position: 4,
      },
      {
        label: "need-make-refund",
        value: "refund",
        url: "refund-list",
        state: { period: getDatePeriod(93) },
        is_global: 1,
        position: 6,
      },
    ];

    const deliveryCount = this.getCountDelivery();

    const idsDeliveryCount = Object.keys(deliveryCount);
    let defaultList = [];
    const notDefaultList = [];

    idsDeliveryCount.forEach((tabID) => {
      if (deliveryCount[tabID]) {
        const findDefaultTab = defaultTabs.find((tab) => tab.value === tabID);
        if (findDefaultTab) {
          const count = deliveryCount[tabID];
          let additionalCount;
          if (tabID === "delivering") {
            const isDpk = this.getDeliveredKeeperDate();
            if (isDpk) {
              additionalCount = (
                <span
                  className={isDpk}
                  title={T(
                    isDpk === "has-text-danger"
                      ? "parcels-paid-storage"
                      : "soon-parcels-paid-storage"
                  )}
                >
                  <LocalIcon icon="warning-stroke" size="middle" />
                </span>
              );
            }
          }

          defaultList.push({
            ...findDefaultTab,
            count: tabID === 'saled' ? 0 : count,
            additionalCount,
          });
        } else {
          const statusName = OrdersStore.getStatusNameById(tabID);
          const label = statusName || `${T("status")} ${tabID}`;
          notDefaultList.push({
            label,
            value: tabID,
            url: tabID,
            count: deliveryCount[tabID],
            state: { period: getDatePeriod(90) },
          });
        }
      }
    });

    if (!User.getModuleEnable("reserv")) {
      defaultList = defaultList.filter((item) => item.value !== "reserve_sale");
    }
    if (User.isDeliveryEnable()) {
      defaultList = defaultList.filter(
        (item) =>
          item.value !== "delivery" ||
          item.value !== "delivering" ||
          item.value !== "cod" ||
          item.value !== "refund"
      );
    }

    notDefaultList.push({
      label: "status-refund-label",
      value: "returns",
      url: "returns",
      state: { period: new Date() },
      is_global: 1,
      count: this.getRefund().length,
    });

    return [
      allTab,
      ...defaultList.sort((a, b) => a?.position - b?.position),
      ...notDefaultList,
    ];
  },

  onLoadCauntingSaleByStatus() {
    yajax
      .post("/breports/sales-counting")
      .then(
        Actions.loadCauntingSaleByStatus.completed,
        Actions.loadCauntingSaleByStatus.failed
      );
  },
  onLoadCauntingSaleByStatusCompleted(data) {
    let counting = data["counting"];
    const permissionStatus = UserStore.getMoreSetting("statuses")?.map(String);
    if (permissionStatus && permissionStatus[0] !== "0") {
      counting = Object.fromEntries(
        Object.entries(counting).filter(([key]) =>
          permissionStatus.includes(key)
        )
      );
    }

    this.setState({
      counting,
    });
  },
  onLoadCauntingSaleByStatusFailed() {},

  onLoadByOid(oids) {
    if (oids) {
      if (this.state.isLoading) return;
      this.setState({
        isLoading: true,
      });

      const parseOIDS = parseJSON(oids);

      if (Array.isArray(parseOIDS) && parseOIDS.length > 50) {
        Actions.load(true);
      } else {
        yajax
          .get(this._prefix + "one-sale", {
            oids: Array.isArray(parseOIDS) ? parseOIDS.join(",") : parseOIDS,
          })
          .then(Actions.loadByOid.completed, Actions.loadByOid.failed);
      }
    }
  },

  onLoadByOidCompleted(data) {
    const result = this.proccessingSales({ data: data.data });
    const getSales = [...this.state.sales];

    result.forEach((sale) => {
      const index = getSales.findIndex(
        (saleIndex) =>
          parseInt(saleIndex.oid) === parseInt(sale.oid) ||
          parseInt(sale.order_number) === parseInt(saleIndex.order_number)
      );
      if (index !== -1) {
        getSales[index] = sale;
      } else {
        getSales.unshift(sale);
      }
    });

    this.setState({
      sales: [...getSales],
      isLoading: false,
    });

    this.checkOnBoarding();
    Actions.loadCauntingSaleByStatus();
  },
  onLoadByOidFailed() {
    this.setState({
      isLoading: false,
    });
  },

  deleteSaleByOid(oid) {
    const sales = [...this.state.sales];

    const parseOIDS = parseJSON(oid);

    const filterSales = sales.filter((item) => {
      if (Array.isArray(parseOIDS)) {
        const typeToNumber = parseOIDS.map(Number);
        return !typeToNumber.includes(parseInt(item.oid));
      }
      return parseInt(item.oid) !== parseInt(parseOIDS);
    });

    this.setState({
      sales: filterSales,
    });
  },

  onLoad(force, isSaleTab) {
    let filter = this.state._filter;

    if(filter["date_start"] && filter["date_end"]) {
      if (this.loadingSales) return this.loadingSales;
      console.log("start load sales...", force);

      filter["date_start"].setHours(0,0,0,0)
      filter["date_end"].setHours(23,59,59,999)
      const dateStart = gmtToUtcTimestamp(filter["date_start"])
      const dateEnd = gmtToUtcTimestamp(filter["date_end"])

      const data = {
        start: dateStart,
        end: dateEnd,
      };

      if (isSaleTab) {
        const calcPeriodDiff = calcDiffPeriodDate(
          filter["date_start"],
          filter["date_end"]
        );

        calcPeriodDiff.start.setHours(0, 0, 0, 0);

        data.start = gmtToUtcTimestamp(calcPeriodDiff.start);
        data.end = gmtToUtcTimestamp(filter["date_end"]);
      }

      if (force) {
        data.refresh = 1;
      }

      this.setState({
        isLoading: true,
      });

      clearTimeout(this.timeoutSales);

      const diffInMs =
        new Date(data.end * 1000).getTime() -
        new Date(data.start * 1000).getTime();
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

      if (diffInDays > 60) {
        this.loadingSales = yajax
          .get(this._report_prefix + "sale", data)
          .then(Actions.load.completed, Actions.load.failed);
      } else {
        this.loadingSales = yajax
          .get(this._prefix + "sale", data)
          .then(Actions.load.completed, Actions.load.failed);
      }

      return this.loadingSales;
    }
  },

  onLoadCompleted(data) {
    this.loadingSales = false;
    let response = [];
    if (data["success"]) {
      response = this.proccessingSales(data);
      clearTimeout(this.timeoutSales)
      this.setState({
        sales: response,
        isLoading: false,
      });
    }

    Actions.loadCauntingSaleByStatus();
    this.checkOnBoarding();
  },

  onLoadFailed() {
    this.loadingSales = false;

    if (this.failedLoad <= 3) {
      this.timeoutSales = setTimeout(Actions.load, 3000);
    } else if (this.failedLoad > 3 && this.failedLoad <= 7) {
      this.timeoutSales = setTimeout(Actions.load, 5000);
    } else if (this.failedLoad > 7) {
      this.timeoutSales = setTimeout(Actions.load, 1000 * 60);
    }

    this.failedLoad = this.failedLoad + 1;

    this.setState({
      isLoading: false,
      sales: [],
    });
    console.log("failed add sale");
  },

  proccessingSales(data) {
    let response = [];

    const permissionStatus = UserStore.getMoreSetting("statuses")?.map(String);

    for (let s in data["data"]) {
      let it = [],
        sale = data["data"][s];

      if (
        permissionStatus &&
        permissionStatus[0] !== "0" &&
        !permissionStatus?.includes(sale.z)
      ) {
        continue;
      }

      for (let i in sale["i"]) {
        let _it = {},
          item = sale["i"][i];
        // let product = ProductsStore.getProduct(item['product_id']);
        if (item["bp"]) {
          item["bp"] = parseFloat(`${item["bp"]}`);
        }
        for (let j in ITEM_FIELD) {
          let indx = ITEM_FIELD[j][0];
          let indx_short = ITEM_FIELD[j][1];
          _it[indx] = item[indx_short] || 0;
        }
        // _it['product_name'] = product['name'];
        it.push(_it);
      }

      if (sale.s) {
        sale.s = parseFloat(`${sale.s}`);
      }

      let order = {};
      for (let i in RENAME_FIELD) {
        let indx = RENAME_FIELD[i][0],
          indx_short = RENAME_FIELD[i][1];

        order[indx] = sale[indx_short] || 0;

        if (indx === "channel_id") {
          if (String(order[indx]).indexOf("[") > -1) {
            order[indx] = JSON.parse(order[indx]);
          } else {
            order[indx] = [parseInt(order[indx])];
          }
        }
      }

      order["items"] = it;
      let order_number_text = order["order_number"] || order["oid"];
      if (order["seller_order_id"]) {
        order_number_text += "-" + (order['seller_order_text'] || order["seller_order_id"]);
      }
      order["order_number_text"] = order_number_text;
      response.push(order);
    }

    return response;
  },

  onDeleteSale(oid, _process) {
    if (this.startDeleted) return;
    console.log("start delete sales...", oid);
    this.startDeleted = true;

    let request = { oid: oid };

    if (_process) {
      request["process"] = 1;
    }

    const params = {
      method: "DELETE",
      url: this._prefix + "sale",
      data: request,
    };

    return yajax(params).then(
      (res) => Actions.deleteSale.completed(res, oid),
      Actions.deleteSale.failed
    );
  },

  onDeleteSaleCompleted(data, oid) {
    this.startDeleted = false;
    if (data["success"]) {
      // Actions.load();
      if (oid) {
        this.deleteSaleByOid(oid);
      }
      ProductActions.load(true);
      ExpensesActions.loadAccounts();
      setTimeout(() => ShipmentsActions.load(true), 1000);
      Actions.loadReports();
    }
    console.log("result delete sales: ", data);
  },

  onDeleteSaleFailed(res) {
    this.startDeleted = false;
    if (res.response) {
      const parse = parseJSON(res.response);
      if (
        parse.error &&
        parse.error &&
        parse.error === "order already deleted"
      ) {
        Actions.load();
        ProductActions.load(true);
        ExpensesActions.loadAccounts();
        setTimeout(() => ShipmentsActions.load(true), 1000);
        Actions.loadReports();
      }
    }
    console.log("failed delete sale");
  },

  getSaleReportsByProduct(pid, mid) {
    const reports = this.getReports(),
      products = reports["products"];

    let response = {
      items_count: 0,
      orders_count: 0,
      sales_profit: 0,
      gross_income: 0,
    };

    if (products) {
      if (products[pid]) {
        let pr = products[pid];
        if (mid) {
          response = pr[mid] || response;
        } else {
          let k = Object.keys(pr);
          response = pr[k[0]] || response;
        }
      }
    }

    return response;
  },

  getReports() {
    if (this.state.sales_reports === null) {
      Actions.loadReports();
      this.setState({
        sales_reports: {}
      })
      return [];
    }

    return this.state.sales_reports;
  },

  onLoadReports(force) {
    if (this.state.isLoadedReport) return;

    this.setState({
      isLoadedReport: true,
    });

    const filter = this.state['_filter']

    filter['dateStartReport'].setHours(0,0,0,0)
    const dateStart = gmtToUtcTimestamp(filter['dateStartReport'])

    let data = {
      start_date: dateStart
    };

    if (force) {
      data["refresh"] = 1;
    }

    console.log("start load reports sales...");
    return yajax
      .get(this._report_prefix + "reports", data)
      .then(Actions.loadReports.completed, Actions.loadReports.failed);
  },

  onLoadReportsCompleted(data) {
    if (data["success"] === true) {
      this.failedLoad = 1
      clearTimeout(this.timeoutSales)
      this.setState({
        sales_reports: data["data"],
        isLoadedReport: false,
      });
    }
  },
  onLoadReportsFailed() {
    console.log("failed add sale reports");

    if (this.failedLoad <= 3) {
      this.timeoutSales = setTimeout(Actions.loadReports, 3000);
    } else if (this.failedLoad > 3 && this.failedLoad <= 7) {
      this.timeoutSales = setTimeout(Actions.loadReports, 5000);
    } else if (this.failedLoad > 7) {
      this.timeoutSales = setTimeout(Actions.loadReports, 1000 * 60);
    }

    this.failedLoad = this.failedLoad + 1;

    this.setState({
      isLoadedReport: false,
    });
  },

  isDefaultStatus(status) {
    let st = this.statuses + ["reserve_sale"];
    return st.indexOf(status) > -1;
  },

  // getStatusListCustom() {
  //     const status = OrdersStore.getStatusList(this.statuses.slice(1));
  //     return status;
  // },

  getAllStatusesList(disabledStatuses = [], addReserv) {
    let defaultStatuses = [];
    const permissionStatus = UserStore.getMoreSetting("statuses");

    const statusesList = [...this.statuses];

    const filterWithDisabledStatus = statusesList.filter(
      (item) => !disabledStatuses.includes(item)
    );

    for (let i in filterWithDisabledStatus) {
      const st = filterWithDisabledStatus[i];
      if (
        !!permissionStatus &&
        permissionStatus[0] !== 0 &&
        permissionStatus[0] !== "0" &&
        !permissionStatus?.includes(st)
      ) {
        continue;
      }
      defaultStatuses.push({ name: `status-${st}`, id: st, is_global: 1 });
    }

    if(addReserv) {
      defaultStatuses.push({ name: `reserve_sale`, id: 'reserve_sale' });
    }

    const statuses = defaultStatuses.concat(
      OrdersStore.getSalesCustomStatuses()
    );
    return statuses;
  },

  onChangeStatus(oid, status) {
    if (this.changeStatus) return;
    console.log("start change status sales...", oid);
    this.changeStatus = true;

    let request = { oid: oid, status: status };

    return yajax
      .post(this._prefix + "status", request)
      .then(
        (data) => Actions.changeStatus.completed(data, oid),
        Actions.changeStatus.failed
      );
  },

  onChangeStatusCompleted(data, oid) {
    this.changeStatus = false;
    Actions.loadByOid(oid);

    // Actions.load();
    console.log("result changes status sales: ", data);
  },

  onChangeStatusFailed() {
    this.changeStatus = false;
    console.log("failed change status sale");
  },
  onLoadPackListNP(data) {
    const sizes = data;
    sizes.PackForSale = 1;

    yajax
      .get(this._prefix + "tracking", {
        action: "getPackListNP",
        sizes: JSON.stringify({
          PackForSale: 1,
          sizes,
        }),
      })
      .then(Actions.loadPackListNP.completed, Actions.loadPackListNP.failed);
  },
  onLoadPackListNPCompleted(data) {},
  onLoadPackListNPFailed() {},

  createPackingListNPForSelect(data) {
    if (Array.isArray(data)) {
      return data.map((item) => ({
        id: item.Ref,
        name: `${
          User.getLang() === "ru" ? item.DescriptionRu : item.Description
        } ${parseFloat(item.Width)}x${parseFloat(item.Height)}х${parseFloat(
          item.Length
        )} (${T("product-info-price")} ${item.Cost} ${T("currency-2")})`,
      }));
    } else {
      return [];
    }
  },

  onDeliveredKeeperDate() {
    clearTimeout(this.loadTimeoutDpk);

    if (this.loadingDpk) return;

    this.loadingDpk = true;

    yajax
      .get(this._prefix + "tracking", { action: "getDeliveredKeeperDate" })
      .then(
        Actions.deliveredKeeperDate.completed,
        Actions.deliveredKeeperDate.failed
      );
  },
  onDeliveredKeeperDateCompleted(data) {
    clearTimeout(this.loadTimeoutDpk);
    this.loadingDpk = false;
    if (data.success) {
      if (data.data?.length) {
        const createDict = data.data.reduce((acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        }, {});
        this.setState({
          dictDeliveredKeeperDate: createDict,
        });
      }
    }

    this.loadTimeoutDpk = setTimeout(
      Actions.deliveredKeeperDate,
      1000 * 60 * 60 * 4
    );
  },
  onDeliveredKeeperDateFailed() {
    this.loadingDpk = false;
  },

  getDeliveredKeeperDateByOrderId(orderID) {
    const deliveredKeeperDateByOrderId =
      this.state.dictDeliveredKeeperDate[orderID];
    if (deliveredKeeperDateByOrderId) {
      const dpk = deliveredKeeperDateByOrderId.dpk;
      if (!dpk) {
        return false;
      }

      const dateDpk = new Date(dpk);
      const dateNow = new Date();

      const thirdDaysBeforeDpk = new Date(dateDpk);
      thirdDaysBeforeDpk.setDate(dateDpk.getDate() - 2);

      if (dateNow >= dateDpk) {
        return "paid-storage";
      } else if (dateNow >= thirdDaysBeforeDpk && dateNow < dateDpk) {
        return "paid-storage-coming-soon";
      }
    }

    return false;
  },

  onLoadRefund() {
    if (this.loadingRefund) return;

    this.loadingRefund = true;

    const filter = this.get("_filter");
    const data = {};

    if (filter["date_start"]) {
      const date_start = filter["date_start"].setHours(0, 0, 0, 0);
      data.start = parseInt(`${date_start / 1000}`);
    }

    if (filter["date_end"]) {
      const date_end = filter["date_end"].setHours(23, 59, 59, 999);
      data.end = parseInt(`${date_end / 1000}`);
    }

    yajax
      .get(this._prefix + "refund", data)
      .then(Actions.loadRefund.completed, Actions.loadRefund.failed);
  },
  onLoadRefundCompleted(data) {
    this.loadingRefund = false;

    if (data?.success) {
      const response = this.proccessingSales(data);
      this.setState({
        refundRows: response,
      });
    }
  },
  onLoadRefundFailed() {
    this.loadingRefund = false;
  },

  getRefundList() {
    const refundList = this.get("refundRows");
    if (refundList === null) {
      Actions.loadRefund();
      this.setState({ refundRows: [] });
      return [];
    }

    return refundList;
  },

  getRefundById(id) {
    const sales = this.getRefundList();

    return sales.find((item) => parseFloat(item.oid) === parseFloat(id));
  },

  getRefund() {
    const refund = this.getRefundList();
    return refund.filter((item) => this.applyFilter(item, "refund"));
  },

  getDeliveredKeeperDate() {
    const deliveredKeeperDates = Object.values(
      this.state.dictDeliveredKeeperDate
    );

    if (
      deliveredKeeperDates.some(
        ({ id }) => this.getDeliveredKeeperDateByOrderId(id) === "paid-storage"
      )
    ) {
      return "has-text-danger";
    }

    if (
      deliveredKeeperDates.some(
        ({ id }) =>
          this.getDeliveredKeeperDateByOrderId(id) ===
          "paid-storage-coming-soon"
      )
    ) {
      return "text-yellow";
    }

    return false;
  },

  getCalcStatistics(data, tab) {
    let response = {
      amount: 0,
      profit: 0,
      discount: 0,
      not_paid: 0,
      quantity: 0,
      net_price: 0,
      count: 0,
      commission: 0,
      amountWithFilterAccount: 0
    };

    for (let i in data) {
      const sale = data[i],
        is_reserv =
          sale["order_status"] === "reserve_sale" && tab !== "reserve_sale";

      if (is_reserv) {
        continue;
      }

      if (ExpensesStore.isDebt(sale["prapaid_account_id"])) {
        response["not_paid"] += parseFloat(sale["prepaid_amount"]);
      }

      if (ExpensesStore.isDebt(sale["account_id"])) {
        response["not_paid"] += parseFloat(
          sale["amount_sale"] - sale["prepaid_amount"]
        );
      } else if (ExpensesStore.isNotAllowToCalc(sale["account_id"])) {
        continue;
      }


      response.commission += parseFloat(sale.fee || 0);


      const filter = this.get('_filter')
      const filterAccountValue = filter.account.value
      if(filterAccountValue) {
          if(parseInt(sale.prapaid_account_id) === parseInt(filterAccountValue) && !ExpensesStore.isDebt(sale["prapaid_account_id"])) {
            response['amountWithFilterAccount'] += sale.prepaid_amount
          }

          if(parseInt(filterAccountValue) === parseInt(sale.account_id) && !ExpensesStore.isDebt(sale["account_id"])) {
            response['amountWithFilterAccount'] += (sale.amount_sale - sale.prepaid_amount)
          }
      }

      response["discount"] +=
        parseFloat(sale["amount"]) - parseFloat(sale["amount_sale"]);
      response["count"] += 1;
      response["amount"] += parseFloat(sale["amount_sale"]);
      for (let i in sale["items"]) {
        const item = sale["items"][i];
        if (item.discount) {
          response["discount"] +=
            parseFloat(item.base_price) * parseFloat(item.quantity) -
            parseFloat(item.amount);
        }

        response.commission += parseFloat(item.tax || 0);

        response["profit"] += parseFloat(item["profit"]);
        response["quantity"] += parseFloat(item["quantity"]);
        response["net_price"] += parseFloat(item["net_price"]);
      }
    }

    response.margin = calcMargin(response["net_price"], response["amount"]);

    return response;
  },
  getReportByDate(start, end) {
    const report = this.get("sales_reports");

    if (report) {
      const dateStart = formatDateString(start);
      const dateEnd = formatDateString(end);

      return Object.entries(report).reduce(
        (acc, [key, value]) => {
          if (
            key >= dateStart &&
            key <= dateEnd &&
            key !== "global" &&
            key !== "products"
          ) {
            acc.discount_amount += value.discount_amount;
            acc.gross_income += value.gross_income;
            acc.items_count += value.items_count;
            acc.orders_count += value.orders_count;
            acc.sales_profit += value.sales_profit;
          }
          return acc;
        },
        {
          discount_amount: 0,
          gross_income: 0,
          items_count: 0,
          orders_count: 0,
          sales_profit: 0,
        }
      );
    }

    return {};
  },

  onEditComment(data, isRefund) {
    yajax.post(this._prefix + 'edit-sale', data).then((res) => Actions.editComment.completed(res, isRefund), Actions.editComment.failed);
  },
  onEditCommentCompleted(data, isRefund) {
    if(isRefund) {
      Actions.loadRefund()
    } else if(data?.oid) {
      Actions.loadByOid(data.oid)
    }
  },
  onEditCommentFailed() {
  }

});

export default SalesStore;
