import ProductsStore from "../../products/products-store";

export const sumAccruals = (statistics) =>
  Object.values(statistics.accrual || {}).reduce(
    (acc, cur) =>
      acc +
      Object.values(cur).reduce(
        (accValues, curValue) => accValues + curValue.amount,
        0
      ),
    0
  ) + statistics.accrual_general.amount || 0;

const calculatePercentage = (amount, percent) =>
  (parseFloat(amount) * parseFloat(percent)) / 100;

const regenerateToObject = (data) => {
  if (!Array.isArray(data)) return {};

  return data.reduce((acc, cur) => {
    cur.data.forEach((item) => {
      acc[item.value] = {
        value: parseFloat(cur.value),
        type: cur?.type || "%",
      };
    });
    return acc;
  }, {});
};

const reduceCalcAmount = (array) => {
  if (!Array.isArray(array) && !array.length) return 0;
  return array.reduce((acc, cur) => acc + cur.amount, 0);
};

export const calcStatisticsEmployees = (userData, salesData) => {
  const regenerateData = userData.reduce((acc, cur) => {
    acc[cur.user_id] = {
      category: regenerateToObject(cur.more_setting?.accrual?.category),
      brand: regenerateToObject(cur.more_setting?.accrual?.brand),
      channel: regenerateToObject(cur.more_setting?.accrual?.channel),
      general: parseFloat(cur.more_setting?.accrual?.general) || 0,
      general_type: cur?.more_setting?.accrual?.general_type || '%',
      accrual_type: cur.more_setting?.accrual?.accrual_type || 'turnover',
      user_id: cur.user_id,
      name: cur.name,
      phone: cur.phone,
      email: cur.email,
      is_active: cur.is_active,
      sales_units: 0,
      sales_total: 0,
      sales_amount: 0,
      sales_profit: 0,
      numDays: 0,
      accrual_general: {
        amount: 0,
        units: 0,
        sales: 0,
        items_amount: 0,
        items_profit: 0,
      },
      accrual: {
        category: {},
        brand: {},
        channel: {}
      },
    };

    return acc;
  }, {});
  const salesPerData = {};
  salesData.forEach((sale) => {
    const responsibleUserId = sale.responsible_user_id;
    if (regenerateData[responsibleUserId]) {
      regenerateData[responsibleUserId].sales_total += 1;
      regenerateData[responsibleUserId].sales_amount += parseFloat(`${sale.amount_sale}`);
      if (!salesPerData[responsibleUserId]) {
        salesPerData[responsibleUserId] = {};
      }

      const salesPerDate = new Date(sale.purchase_date * 1000).toDateString();
      salesPerData[responsibleUserId][salesPerDate] = true;

      const count = {
        category: false,
        brand: false,
        general: false,
        channel: false,
      }

      sale.items.forEach((product) => {
        regenerateData[responsibleUserId].sales_units += product.quantity;
        regenerateData[responsibleUserId].sales_profit += product.profit;

        const getProductById = ProductsStore.getProductByMid(
          product.product_id,
          product.mid
        );

        const productPrice = regenerateData[responsibleUserId].accrual_type === 'profits' ? product.profit : product.amount;

        const userCategoryPercentageData =
          regenerateData[responsibleUserId].category;
        const userBrandPercentageData = regenerateData[responsibleUserId].brand;
        const userGeneralPercentageData =
          regenerateData[responsibleUserId].general;
        const userChannelPercentageData = regenerateData[responsibleUserId].channel;




        let productCategoryID = getProductById.category_id;
        const productBrandID = getProductById.brand_id;
        const saleChannelIds = sale.channel_id || []

        const findParentCategoryRecursive = (categoryID) => {
          let category = ProductsStore.getCategoryDataById(categoryID);

          if (!category) {
            productCategoryID = getProductById.category_id;
            return
          }

          if (userCategoryPercentageData[category.id]) {
            productCategoryID = category.id
            return
          }
          if (!category.parent_id) {
            productCategoryID = getProductById.category_id;
            return null;
          }
          return findParentCategoryRecursive(category.parent_id);
        }
        findParentCategoryRecursive(getProductById.category_id);

        const findChannelPercentage = saleChannelIds.reduce((acc, cur) => {
          if(userChannelPercentageData[cur]) {
            if(acc.value < userChannelPercentageData[cur].value) {
              acc.value = userChannelPercentageData[cur].value
              acc.id = cur
              acc.type = userChannelPercentageData[cur].type
            }
          }
          return acc
        }, {
          id: null,
          value:0,
          type: null
        })

        const type = {
          category: userCategoryPercentageData[productCategoryID]?.type,
          brand: userBrandPercentageData[productBrandID]?.type,
          channel: findChannelPercentage.type,
          general: regenerateData[responsibleUserId]?.general_type
        }
        const percentages = {
          category: userCategoryPercentageData[productCategoryID]?.value,
          brand: userBrandPercentageData[productBrandID]?.value,
          channel: findChannelPercentage.value,
          general: userGeneralPercentageData
        }

        const dataId = {
          category: productCategoryID,
          brand: getProductById.brand_id,
          general: false,
          channel: findChannelPercentage.id,
        }

        const maxPercentages = Object.entries(percentages)
          .filter(([, value]) => value !== undefined)
          .reduce((max, entry) => (entry[1] > max[1] ? entry : max), ["", -Infinity]);

        const [maxKey, maxValue] = maxPercentages;

        const data = {
          category: regenerateData[responsibleUserId].accrual.category,
          brand: regenerateData[responsibleUserId].accrual.brand,
          channel: regenerateData[responsibleUserId].accrual.channel,
          general: regenerateData[responsibleUserId].accrual_general
        }

        const dataEdit = data[maxKey]
        const typeEdit = type[maxKey];

        if(!dataEdit[dataId[maxKey]]) {
          dataEdit[dataId[maxKey]] = {
            amount: 0,
            units: 0,
            sales: 0,
            items_amount: 0,
            items_profit: 0,
          };
        }

        if(maxKey === 'general') {
          if (typeEdit === 'fix') {
            dataEdit.amount += maxValue;
          } else {
            if(dataEdit.amount) {
              dataEdit.amount += calculatePercentage(productPrice, maxValue);
            } else {
              dataEdit.amount = calculatePercentage(productPrice, maxValue);
            }
          }
          dataEdit.units += product.quantity;
          dataEdit.items_amount += product.amount;
          dataEdit.items_profit += product.profit;

          if(!count[maxKey]) {
            dataEdit.sales += 1
            count[maxKey] = true
          }
        } else {
          if (typeEdit === 'fix') {
            dataEdit[dataId[maxKey]].amount += maxValue;
          } else {
            if(dataEdit[dataId[maxKey]].amount) {
              dataEdit[dataId[maxKey]].amount += calculatePercentage(productPrice, maxValue);
            } else {
              dataEdit[dataId[maxKey]].amount = calculatePercentage(productPrice, maxValue);
            }
          }
          dataEdit[dataId[maxKey]].units += product.quantity;
          dataEdit[dataId[maxKey]].items_amount += product.amount;
          dataEdit[dataId[maxKey]].items_profit += product.profit;

          if(!count[maxKey]) {
            dataEdit[dataId[maxKey]].sales += 1
            count[maxKey] = true
          }
        }
      });
    }
  });

  Object.keys(salesPerData).forEach((item) => {
    regenerateData[item].numDays = Object.keys(salesPerData[item]).length;
  });

  const calcSumStatisticsUser = Object.values(regenerateData).map((user) => {
    user.sumAccrual =
      reduceCalcAmount(Object.values(user.accrual.brand)) +
      reduceCalcAmount(Object.values(user.accrual.category)) +
      reduceCalcAmount(Object.values(user.accrual.channel)) +
      user.accrual_general.amount;

    return user;
  });

  const activeUser = calcSumStatisticsUser.filter(item => !!item.is_active)
  const notActiveUser = calcSumStatisticsUser.filter(item => !item.is_active)

  activeUser.sort((a, b) => b.sumAccrual - a.sumAccrual)


  return [...activeUser, ...notActiveUser];
};
