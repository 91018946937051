import React, {useEffect, useState} from 'react'
import ChannelBlock from "../../../components/channel-block/channel-block";
import SalesStore from "../../sales/sales-store";
import UserStore from "../../../user/user-store";
import HistoryStore from "../history-store";
import Tag from "../../../components/tag";
import T from "../../../components/i18n";
import {Label} from "react-bulma-components/lib/components/form";
import {Button, List} from "react-bulma-components";
import StockMarketplaceProductRow from "./stock-marketplace-product-row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import HistoryActions from "../history-actions";
import {parseJSON} from "../../../tools/error-handler";
import formatDate from "../../../tools/format-date";
import usePaginationHook from "../../../tools/pagination-hook";

const StockMarketplacesRowMobile = ({data, num}) => {
	const [isOpen, setIsOpen] = useState(false)
	const [disabledLoad, setDisabledLoad] = useState(false)

	const channel_id = SalesStore.foundChannelsByIntegrationId(data?.integration_id)
	const getIntegration = UserStore.getIntegrationById(data?.integration_id);
	const statusInfo = HistoryStore.getStatusMarketplace(data.status)

	const handleRepeatDownload = (evt) => {
		evt.stopPropagation()
		setDisabledLoad(true)
		HistoryActions.repeatStockMarketplaces(data.id)
	}

	useEffect(() => {
		const repeatCompl = HistoryActions.repeatStockMarketplaces.completed.listen(() => {
			setDisabledLoad(false)
		})
		const repeatFail = HistoryActions.repeatStockMarketplaces.failed.listen(() => {
			setDisabledLoad(false)
		})

		return () => {
			repeatCompl()
			repeatFail()
		}
	}, []);

	const parseErrors = parseJSON(data.errors || [])

	const globalErrors = parseErrors.filter(item => !item.pid && !item.remote_id)

	let className = " is-one-";
	className += num % 2 === 0 ? "even" : "odd";

	const listProduct = data.stock

	const {start,end, page, setPage, total, PaginationBlock} = usePaginationHook({listLength: listProduct.length})

	return (
		<tr className={className}>
			<td style={{padding: 15, position: 'relative'}}>
				{!isOpen && <div
					align="right"
					style={{position: "absolute", bottom: 0, right: 5}}
				>
					<small><b>{T('update-label')}: </b> {formatDate(data.updated_at)}</small>
				</div>}
				<div className="display-flex-row-gap" onClick={() => setIsOpen(prev => !prev)}>
					<div>
						<ChannelBlock channel_id={channel_id}/>
						{getIntegration?.name}
						ID: {data.id}
						{!!data.stock.length && !isOpen && <Label>{T('products-request')}: {data.stock.length}</Label>}
					</div>

					<div>
						<Tag name={statusInfo.name} color={statusInfo.background}/>
					</div>
				</div>
				{isOpen && (<div className='margin-top-10'>
					<List>
						<List.Item>
							<b>{T('status-created')}: </b> {formatDate(data.created_at)}
						</List.Item>
						<List.Item>
							<b>{T('update-label')}: </b> {formatDate(data.updated_at)}
						</List.Item>
						{!!(data.stock.length || data.count_products) && <List.Item>
							<b>{T('products-request')}: </b> {data.stock.length || data.count_products}
						</List.Item>}
						{!!globalErrors && !!globalErrors.length && <List.Item>
							<div className='display-flex-column has-text-danger'>
								{globalErrors.map((item, index) => <div
									key={`global-errors-${index}`}>{T(item.error)}</div>)}
							</div>
						</List.Item>}
					</List>
					<PaginationBlock
						totalPage={total}
						setPage={setPage}
						currentPage={page}
						showPrevNext={false}
					/>
					{!!data.stock.length && <List>
						{data.stock.slice(start,end).map(item => <List.Item
							key={`product-${item.product_id}`}>
							<StockMarketplaceProductRow data={item} errors={data?.errors} />
						</List.Item>)}
					</List>}
					<PaginationBlock
						totalPage={total}
						setPage={setPage}
						currentPage={page}
						showPrevNext={false}
					/>
					<div className='display-flex-center'>
						{data.status === 2 && <Button className='button-link-icon' disabled={disabledLoad} title={T('retry-upload-label')} onClick={handleRepeatDownload}>
							<FontAwesomeIcon icon="undo" size="2x"/>
						</Button>}
					</div>
				</div>)}
			</td>
		</tr>
	);
};

export default StockMarketplacesRowMobile;