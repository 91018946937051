/* eslint no-use-before-define: 0 */

import { localStorage } from "../components/storage";

// -------------------------------------------
//TODO: should be refactored: store + actions
//SessionActions.start
//SessionActions.close
//SessionActions.configure
// -------------------------------------------

//////////////
// SETTINGS //
//////////////

let LOCAL_STORAGE;
let SESSION_STORAGE;
let STORAGE_KEY_PREFIX;

const TOKEN_PREFIX = process.env.AUTH_PREFIX ? "auth" : "session";
// eslint-disable-next-line
const CHECK_TOKEN_PERIOD = 1000;
let EXPIRE_AFTER = 0;


let changeTokenHandler;
let destroySessionHandler;

let currentToken = "";

//////////////
// HANDLERS //
//////////////

function getStorage(storage) {
    return storage || LOCAL_STORAGE;
}

function isSessionActive() {
    return !!currentToken;
}

function getStoredToken(storage) {
    return getStorage(storage).getItem(STORAGE_KEY_PREFIX + TOKEN_PREFIX + "-token") || "";
}

export function setStoredToken(token, storage) {
    if (token) {
        getStorage(storage).setItem(STORAGE_KEY_PREFIX + TOKEN_PREFIX + "-token", token);
    } else {
        getStorage(storage).removeItem(STORAGE_KEY_PREFIX + TOKEN_PREFIX + "-token");
    }
}

function _setIsMobileApp(app, storage) {
    if (app) {
        getStorage(storage).setItem(STORAGE_KEY_PREFIX + TOKEN_PREFIX + "-app", app);
    } else {
        getStorage(storage).removeItem(STORAGE_KEY_PREFIX + TOKEN_PREFIX + "-aoo");
    }
}

function _getIsMobileApp(storage) {
    return getStorage(storage).getItem(STORAGE_KEY_PREFIX + TOKEN_PREFIX + "-app") || false;
}

// eslint-disable-next-line
function checkStoredToken() {
    const storedToken = getStoredToken();

    if (storedToken === currentToken) {
        return;
    }

    if (!storedToken) {
        closeSession(false, "session closed on other tab");
    }

    currentToken = storedToken;
    changeTokenHandler(storedToken);
}

function getLastActivity() {
    return LOCAL_STORAGE.getItem(STORAGE_KEY_PREFIX + "session-last-activity") || 0;
}

function setLastActivity(time) {
    if (time > 0) {
        LOCAL_STORAGE.setItem(STORAGE_KEY_PREFIX + "session-last-activity", time);
    } else {
        LOCAL_STORAGE.removeItem(STORAGE_KEY_PREFIX + "session-last-activity");
    }
}

///////////////
//PUBLIC API //
///////////////

function sessionKeepAlive() {
    setLastActivity(Date.now());
}

function startSession(token, silent) {
    if (!token || token === currentToken) {
        return;
    }
    //we should renew our last activity
    sessionKeepAlive();

    currentToken = token;
    setStoredToken(token);
    if (!silent) {
        changeTokenHandler(token);
    }

    startExpirationChecker();
}

function closeSession(silent, reason) {
    setStoredToken(null);
    currentToken = "";
    if (!silent) {
        destroySessionHandler(reason);
    }
}

export function getToken() {
    return currentToken || "";
}

export function setIsMobileApp(app, storage) {
    return _setIsMobileApp(app, storage);
}

export function getIsMobileApp() {
    return _getIsMobileApp();
}

export function configure(config) {
    LOCAL_STORAGE = config.localStorage;
    // SESSION_STORAGE = config.sessionStorage;
    SESSION_STORAGE = config.localStorage;
    STORAGE_KEY_PREFIX = config.storagePrefix;
    EXPIRE_AFTER = config.expirePeriod * 1000;

    const isMobile = process.env.IS_MOBILE;
    const permanentSession = localStorage.getData("user.permanentSession", false);

    if (isMobile && permanentSession) {
        SESSION_STORAGE = config.localStorage;
    }

    destroySessionHandler = config.onSessionDestroy;
    changeTokenHandler = config.onTokenChange;


    if (!(isMobile && permanentSession)) {
        if (config.destroyOnUnload) {
            initDestroyOnUnload();
        }
    }

    startSession(getStoredToken());
    // setInterval(checkStoredToken, CHECK_TOKEN_PERIOD);
}

/////////////////////////////////
// SESSION EXPIRATION TRACKING //
/////////////////////////////////

let inactivityTimeout;


function startExpirationChecker() {
    const lastActivity = getLastActivity();
    let timeout = EXPIRE_AFTER;

    timeout += lastActivity ? lastActivity - Date.now() : 0;
    clearTimeout(inactivityTimeout);
    inactivityTimeout = setTimeout(expirationCheck, timeout);
}


function expirationCheck() {
    if (!isSessionActive()) {
        return;
    }

    const inactivityTime = Date.now() - getLastActivity();
    if (inactivityTime >= EXPIRE_AFTER) {
        closeSession(false, "session-expired");
    } else {
        startExpirationChecker();
    }
}

function getStoredTabs() {
    return parseInt(LOCAL_STORAGE.getItem(STORAGE_KEY_PREFIX + "session-tabs"), 10) || 0;
}

function setStoredTabs(tabsCount) {
    LOCAL_STORAGE.setItem(STORAGE_KEY_PREFIX + "session-tabs", tabsCount);
}

function changeTabCount(inc) {
    const tabsCount = Math.max(0, getStoredTabs() + inc);

    setStoredTabs(tabsCount);

    return tabsCount;
}

function handleWindowBeforeUnload() {
    const token = getStoredToken();
    const tabsCount = changeTabCount(-1);

    if (token && !tabsCount && process.env.DISABLE_AUTOLOGOUT !== "true") {
        // Store token to the sessionStorage to avoid logout when a single tab refreshing
        setStoredToken(null);
        setStoredToken(token, SESSION_STORAGE);
    }
}

function initTabCount() {
    const ready = document.visibilityState === "visible";

    if (ready) {
        window.addEventListener("beforeunload", handleWindowBeforeUnload);
        changeTabCount(1);
    }

    return ready;
}

function initDestroyOnUnload() {
    if (typeof document === "undefined") {
        return;
    }

    if (!SESSION_STORAGE) {
        console.warn("sessionStorage should be defined in config to enable destroying on unload");
        return;
    }

    const token = getStoredToken(SESSION_STORAGE);

    if (token) {
        setStoredToken(token);
        setStoredToken(null, SESSION_STORAGE);
    }

    if (!initTabCount()) {
        /*
         * issue: Chrome fires onload event if user types URL only
         * therefore we need wait a visible status for increment tab counter
         */
        document.addEventListener("visibilitychange", function waitForVisible() {
            if (initTabCount()) {
                document.removeEventListener("visibilitychange", waitForVisible);
            }
        });
    }
}

export const start = startSession;
export const close = closeSession;
export const ping = sessionKeepAlive;
