import React from "react";
import User from "../../../../user/user-store";
import { Button } from "react-bulma-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import T from "../../../../components/i18n";
import AppStore from "../../../../app-store";
import AddAccountModal from "../../add-account-modal";
import ToggleButtonGroup from "../../../../components/toggle-button-group";
import {
  useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";

const listToggleButton = [
  {
    id: "row",
    icon: "row",
  },
  {
    id: "grid",
    icon: "grid",
  },
];

const ActionAccountButtons = ({
  onToggleIsActiveAccount,
  isActiveAccount,
  isTableVariant,
  onChangeTableVariant,
}) => {
  const {isMobileSize} = useIsMobile()
  const handleAddAccount = () => {
    AppStore.openModal(<AddAccountModal />);
  };

  return (
    <div
      className="display-flex-row flex-wrap margin-08"
      style={{ justifyContent: "flex-end", margin: "10px 0" }}
    >
      {isActiveAccount && !isMobileSize && (
        <ToggleButtonGroup
          list={listToggleButton}
          isActive={isTableVariant}
          onChange={onChangeTableVariant}
        />
      )}
      {(User.getPermissionEnable("showbalance") || !isMobileSize) && (
        <Button
          color="warning"
          rounded
          size="small"
          onClick={onToggleIsActiveAccount}
        >
          {isActiveAccount ? T("hide-account") : T("show-account")}
        </Button>
      )}
      {User.getPermissionEnable("showbalance") && User.getPermissionEnable("edit-balance") && (
        <Button
          color="success"
          rounded
          size="small"
          className="display-flex-row"
          onClick={handleAddAccount}
        >
          <FontAwesomeIcon icon="plus-circle" />
          {T("add-new-account-btn")}
        </Button>
      )}
    </div>
  );
};

export default ActionAccountButtons;
