import React, { Component } from "react";
import T from "components/i18n";
import Money from "components/money";

import Columns from 'react-bulma-components/lib/components/columns';
import Box from 'react-bulma-components/lib/components/box';
import Heading from 'react-bulma-components/lib/components/heading';
import User from 'user/user-store';
import InfoWrapper from 'components/info-wrapper';

class ProductsTotal extends Component {

  renderGeneral(data) {
    const style = { textAlign: 'center' };

    return (
          <Columns className="statisctic">
            <Columns.Column style={style}>
              <Box>
                <Heading  heading>
                {T('total-sales')}
                </Heading>
                <Heading  size={4}>
                    {data['count']}
                </Heading>
              </Box>
            </Columns.Column>
            <Columns.Column style={style}>
              <Box>
                <Heading  heading>
                {T('net-amounts')}
                </Heading>
                <Heading  size={4}>
                    <Money amount={data['sum-net']} />
                </Heading>
              </Box>
            </Columns.Column>            
            <Columns.Column style={style}>
              <Box>
                <InfoWrapper info={T("sales-profit-tip")}><Heading  heading>
                {T('sales-amounts')}
                </Heading></InfoWrapper>
                <Heading  size={4}>
                    <Money amount={data['sum-revenue']} />
                </Heading>
              </Box>
            </Columns.Column>
            <Columns.Column style={style}>
              <Box>
                <InfoWrapper info={T("net-profit-tip")}><Heading heading>
                {T('dashboard-sales-profit')}
                </Heading></InfoWrapper>
                <Heading size={4}>
                  <Money amount={data['sum-gross-profit']} />
                </Heading>
              </Box>
            </Columns.Column>
            {data['expenses_profit'] > 0 ?
            <Columns.Column style={style}>
              <Box>
                <Heading heading>
                {T('sales-profit')} - {T('expenses')} 
                </Heading>
                <Heading size={4}>
                  <Money amount={data['sum-gross-profit']-data['expenses_profit']} />
                </Heading>
              </Box>
            </Columns.Column>
            : null}
            <Columns.Column style={style}>
              <Box>
                <Heading  heading>
                {T('margin')}
                </Heading>
                <Heading size={4} >
                    {data['margin'] || 0}%
                </Heading>
              </Box>
            </Columns.Column>
          </Columns>
        );
  }


  render() {
      if (!User.getPermissionEnable("show-cashflow")) return null;
      
      const {rows} = this.props;

        return this.renderGeneral(rows);

  } 
}

export default ProductsTotal;
