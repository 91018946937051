import React, {useEffect, useRef, useState} from 'react'
import ProductsStore from "../../modules/products/products-store";
import {Box, Button, Heading, List} from "react-bulma-components";
import WarehouseStore from "../../modules/warehouse/warehouse-store";
import T from '../i18n'
import Money from "../money";
import DOM from "react-dom-factories";
import {isMobile} from "react-device-detect";

const SelectWarehouseProduct = ({productID, onChange, onClose}) => {
	const product = ProductsStore.getProduct(productID)
	const [highlightedIndex, setHighlightedIndex] = useState(0);
	const [interactionMethod, setInteractionMethod] = useState("keyboard")
	const optionRefs = useRef([])

	const stateList = Object.keys(product.stock)

	const handleSubmit = (index) => {
		const mid = stateList[index || highlightedIndex]
			onChange([{value: `${productID}_${mid}`}])
			onClose && onClose()
	}

	useEffect(() => {
		const handleKeyDown = (e) => {
				setInteractionMethod("keyboard")
				e.stopImmediatePropagation()
				switch (e.key) {
					case "ArrowDown":
						e.preventDefault();
						setHighlightedIndex((prev) => {
							if (prev + 1 > stateList.length - 1) {
								return stateList.length - 1
							}
							return prev + 1
						});
						break;
					case "ArrowUp":
						e.preventDefault();
						setHighlightedIndex((prev) => {
							if (prev - 1 === -1) {
								return 0
							}
							return prev - 1
						});
						break;
					case "Enter":
						e.preventDefault();
						handleSubmit()
						break;
					case "Escape":
						onClose();
						break;
					default:
						break;
				}
		};
		document.addEventListener("keydown", handleKeyDown);
		return () => document.removeEventListener("keydown", handleKeyDown);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [highlightedIndex]);



	return (
		<Box>
			<Heading>{T('select-warehouse-label')}</Heading>
			<Heading subtitle>
				{product.name} <br />{" "}
				<p style={{ fontSize: "1rem" }}>
					ID: {productID}.{product.sku ? ` ${product.sku}. ` : ""}
				</p>
			</Heading>
			<List>
				{stateList.map((item, index) => {
					const getProduct = ProductsStore.getProductByMid(productID, item);
					const isDropshipping = WarehouseStore.isDropshipping(item) && getProduct.type_product !== 4
					return <List.Item
						key={item}
						className='cursor-pointer'
						style={(!isMobile && highlightedIndex === index) ? {background: '#deebfe'} : {}}
						onMouseEnter={() => {
						if (interactionMethod === "mouse") {
							setHighlightedIndex(index);
						}}}
						onMouseMove={() => setInteractionMethod("mouse")}
						onClick={() => handleSubmit(index)}
					>
						<div ref={(el) => (optionRefs.current[index] = el)}>
							<b>{WarehouseStore.getWarehouseName(item)}. {ProductsStore.getStockUnits(isDropshipping ? "∞" : getProduct['instock'], getProduct['units'])}. <Money amount={getProduct.sale_price || getProduct.price} wrapper={DOM.span}/></b>
						</div>
					</List.Item>
				})}
			</List>
			<div className='display-flex-row' style={{justifyContent: 'flex-end'}}>
				<Button onClick={onClose}>{T('close')}</Button>
			</div>
		</Box>
	);
};

export default SelectWarehouseProduct;