import React, { Component } from 'react';
import T from "components/i18n";
import Box from 'react-bulma-components/lib/components/box';
import Actions from './products-actions' 
import ProductsStore from '../products/products-store'
import WarehouseStore from '../warehouse/warehouse-store'
import SubmitButton from 'components/submit-button';
import Button from 'react-bulma-components/lib/components/button';
import Heading from 'react-bulma-components/lib/components/heading';
import LightStore from './light-store'
import {
    Field, 
    Control, 
    Label,
    Textarea, 
} from 'react-bulma-components/lib/components/form';
import ProductsActions from "./products-actions";
import {Columns} from "react-bulma-components";
import RadioButtonListControl
  from "../../components/radio-buttons-list/radio-button-list-control";
import {
  withIsMobileSize
} from "../../components/is-mobile-context/is-mobile-context";
import InputWithCounter from "../../components/input-with-counter";

class ChangeStockModal extends Component {
    constructor(props) {
        super(props);

        const {pid, mid} = this.props;

        if (pid && mid) {
          this.data = ProductsStore.getProductByMid(pid, mid);
        } else {
          this.data = {'quantity': 0}
        }

        this.state = {
          waiting: null,
          quantity: {
                error: "",
                value: this.data['quantity'] || 0
          },
          writeOff: {
              error: "",
              value: 0
          },
          comment: {
                error: "",
                value: ""
          },
          button: T('change'),
          typeWriteOff: 'writeOff'
        };

        this.now_stock = 0;

        this.onChange = this.onChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        const {onClose} = this.props;

        if(this.props.pid ) {
          ProductsActions.loadByPid([this.props.pid]);
        }

        this.listenAdd = Actions.changeStock.completed.listen((data) => {
          this.setState({
              'waiting': false
          });

          Actions.loadByPid([this.props.pid])

          if (typeof(onClose) == "function") {
              onClose();
          }
        });

        this.listenAddFaild = Actions.changeStock.failed.listen((res) => {
            this.setState({
              'waiting': false
            });
        });

        this.listenLoadById = ProductsActions.loadByPid.completed.listen((res) => {
          const getProduct = ProductsStore.getProductByMid(this.props.pid, this.props.mid)
          if(getProduct) {
            this.setState({
              quantity: {
                value: getProduct.quantity
              }
            })
          }
        })

    }
    componentWillUnmount() {
      if (typeof(this.listenAdd) == "function") {
          this.listenAdd();
      }

      if (typeof(this.listenAddFaild) == "function") {
          this.listenAddFaild();
      }
      if (typeof(this.listenLoadById) == "function") {
          this.listenLoadById();
      }

    }

    submit(file, mid, template) {        
        const {pid, group} = this.props;
        this.setState({
              'waiting': true
        });

        let request = {
          'quantity': this.state.quantity.value,
          'comment': this.state.comment.value,
          'mid': this.data['mid']
        }

        if (group) {
            let d = LightStore.getSelectGroupProductWithoutDropAndServicesProduct(), ready_group = [];

            for (let i in d) {
                let j = i.split(",")
                ready_group.push(j[0]+"_"+j[1]);
            }

            request['group'] = ready_group;

        } else {
          request['pid'] = pid;
          request['mid'] = this.data['mid'];
        }
        
        Actions.changeStock(request);
    }

    onChange(evt) {
        const {group} = this.props
        const value = evt.target.value,
              name = evt.target.name;

        if(name === 'quantity' && !group) {
          let writeOff = (this.now_stock - (isNaN(value) ? 0 : value))
          let typeWriteOff = this.state.typeWriteOff

          if(this.now_stock > value) {
            typeWriteOff = 'writeOff'
          } else if(this.now_stock < value){
            typeWriteOff = 'add'
            writeOff = Math.abs(writeOff)
          }

          this.setState({
            writeOff: {value: writeOff},
            quantity: {value},
            typeWriteOff
          })
        } else if(name === 'writeOff' && !group) {
          const valueQuantity = this.now_stock - (isNaN(value) ? 0 : value);
          this.setState({
            writeOff: {value},
            quantity: {value: valueQuantity}
          })
        } else if(name === 'add' && !group) {
          const valueQuantity = this.now_stock + +(isNaN(value) ? 0 : value);
          this.setState({
            writeOff: {value},
            quantity: {value: valueQuantity}
          })
        } else {
          this.setState({
            [name]: {value}
          })
        }
    }


    render () {
        const {pid, group, onClose} = this.props;
        this.now_stock = this.data['quantity'];

        let button = T('change');

        if (!group && this.state.writeOff.value) {
          if (this.state.typeWriteOff === 'writeOff') {
            button = T('change-prooducts-stock-removal', {count: this.state.writeOff.value})
          } else {
            button = T('change-prooducts-stock-add', {count: this.state.writeOff.value});
          }
        }
        const radioButtonList = [
          {
            title: 'decrease',
            value: 'writeOff',
          },
          {
            title: "add",
            value: 'add',
          }
        ]

        const handleChangeRadioValue = ({value}) => {
          let quantity = this.now_stock;
          if(value === 'add') {
            quantity = parseFloat(quantity) + parseFloat(this.state.writeOff.value);
          } else {
            quantity = parseFloat(quantity) - parseFloat(this.state.writeOff.value);
          }
          this.setState({
            typeWriteOff: value,
            quantity: {value: quantity}
          })
        }

        return (
              <Box>
                <Heading size={this.props.isMobileSize ? 5 : 3}>
                    {T('editinstock')} <br />
                </Heading>

                {group ? 
                  <Heading subtitle className="has-text-danger" size={this.props.isMobileSize ? 6 : 4}>
                    {T('count-edit-items', {count: LightStore.getCountWithoutDropAndServicesProduct()})}
                  </Heading>
                :
                <Heading subtitle size={this.props.isMobileSize ? 6 : 4}>
                    ID {pid}: {this.data['name']} <br />
                    {T('product-marketplace')}: {T(WarehouseStore.getWarehouseName(this.data['mid']))}
                </Heading>}
                <Columns>
                  <Columns.Column>
                    <Label>{T('product-quantity')}: </Label>
                    <InputWithCounter
                      size="large"
                      name="quantity"
                      sizeIcon='2x'
                      notDisabledMinus
                      onChange={this.onChange}
                      value={this.state.quantity.value}
                      color={(this.state.quantity.error) ? "danger" : "primary"}
                    />
                  </Columns.Column>
                  {!group && <Columns.Column>
                    <Field>
                      <Label>{T(this.state.typeWriteOff === 'add' ? 'write-off-instock-add' : 'write-off-instock')}: </Label>
                      <InputWithCounter
                        size="large"
                        name={this.state.typeWriteOff}
                        sizeIcon='2x'
                        notDisabledMinus
                        onChange={this.onChange}
                        color={(this.state.writeOff.error) ? "danger" : "primary"}
                        value={this.state.writeOff.value}
                      />
                    </Field>
                    <RadioButtonListControl
                      name='typeWriteOff'
                      list={radioButtonList}
                      onChange={handleChangeRadioValue}
                      valueRadio={this.state.typeWriteOff}
                      propsWrapper={{style: {marginTop: '-10px'}}}
                    />
                  </Columns.Column>}
                </Columns>
                 <Field>
                  <Control>
                    <Label>{T('comment')}</Label>
                    <Textarea  size="medium" 
                          name="comment" 
                          type="text" 
                          placeholder={T('comment-description-placeholder')}
                          onChange={this.onChange} 
                          color={(this.state.comment.error) ? "danger" : "primary"}
                          value={this.state.comment.value}
                          />
                  </Control>
                </Field>

                {group ? 
                <Heading size={4} color="danger">
                  {T('change-stock-btn-warning')}
                </Heading> : null}

                  <Button.Group>
                    <SubmitButton size="medium" 
                            text={button}
                            fullwidth={false} 
                            color="primary"
                            waiting={this.state.waiting} 
                            submit={this.submit}/>

                    <Button size="medium" rounded color="light"
                      onClick={onClose}
                      >{T('close')}</Button>
                  </Button.Group>
              </Box>
        );
    }

}

export default withIsMobileSize(ChangeStockModal);