import React from 'react'
import {parseJSON} from "../../../tools/error-handler";
import UserStore from "../../../user/user-store";
import {Box, Button, Heading, List} from "react-bulma-components";
import T from '../../../components/i18n'
import AppStore from "../../../app-store";
import ExpensesStore from "../../expenses/expenses-store";

const activeList = ({products}) => {
	const checkbox = UserStore.getIntegrationData(UserStore.CH_ID);
	const vchasno = UserStore.getIntegrationData(UserStore.VK_ID);

	const toArray = (data) => {
		if (Array.isArray(data)) {
			return data;
		}
		if (typeof data === "object" && data !== null) {
			return [data];
		}
		return [];
	};
	const checkboxArray = toArray(checkbox);
	const vchasnoArray = toArray(vchasno);

	const combinedArray = [...checkboxArray, ...vchasnoArray];

		if(!products?.length) return []

		const mids = products.map(item => item.mid)
		const uniqueMids = [...new Set(mids)];

		const realId = UserStore.get("realId");

		const refactoringName = combinedArray.map(item => ({
			...item,
			name: item?.api_key || ''
		}))

		return refactoringName.filter((item) => {
			if (!item.is_active) return false;
			const parseIdent = parseJSON(item.ident);
			if(parseIdent && !parseIdent?.rid) return true
			if(parseIdent && !parseIdent?.mid) return true
			let result = true
			if (parseIdent && parseIdent.rid && parseIdent.rid[0] === 0) {
				result = result && true
			} else {
				result = result && parseIdent.rid.includes(parseInt(realId))
			}
			if(parseIdent && parseIdent.mid && parseIdent.mid[0] === 0) {
				result = result && true
			} else {
				result = result  &&  uniqueMids.every(item => parseIdent.mid.includes(parseInt(item)))
			}
			return result
		});
}

const MakePaymentListModal = ({data, onClose}) => {

	return <Box>
		<Heading>{T("make-the-payment")}</Heading>
			<List>
				{Object.values(data || {}).map((item, index) => (
					<List.Item key={`${item.name}-${index}`}>
						{T("make-the-payment")} ({item.name}): <b>{item.makePaymentLabel}</b>
					</List.Item>
				))}
			</List>
		<div align='right'>
			<Button onClick={onClose}>{T('close')}</Button>
		</div>
	</Box>
}

const MakePaymentLabel = ({products, channelIds, accountId, deliveryId, terminalMakePayment}) => {

	const list = activeList({products})

	const channelIdsList = channelIds ? Array.isArray(channelIds) ? channelIds.map(item => item.value) : [channelIds.value] : []

	const data = {}
	if(list.length) {
		list.forEach((item) => {
			const saveData = {
				name: item.api_key,
			}

			const channelLabel = item.ident.channel_label
			const accountLabel = item.ident.account_label

			const account = ExpensesStore.getAccountById(accountId);

			const foundKey = channelIdsList.find(key => channelLabel && !!channelLabel[key]);
			if (foundKey) {
				saveData.makePaymentLabel = channelLabel[foundKey];
			} else if(accountLabel && accountLabel[accountId]) {
				saveData.makePaymentLabel = accountLabel[accountId];
			} else if(account.types === 0 || account.types === 4) {
				return
			} else if(account.types === 1 && deliveryId) {
				if(item.integration_id === 9) {
					switch (parseInt(deliveryId)) {
						case 3:
							saveData.makePaymentLabel = 'Післясплата';
							break;
						case 6:
							saveData.makePaymentLabel = 'Платіж RozetkaPay';
							break;
						default :
							saveData.makePaymentLabel = 'Платіж NovaPay';
					}
				} else {
					switch (parseInt(deliveryId)) {
						case 3:
							saveData.makePaymentLabel = 'Платіж Укрпошта';
							break;
						case 6:
							saveData.makePaymentLabel = 'Платіж RozetkaPay';
							break;
						default :
							saveData.makePaymentLabel = 'Платіж NovaPay';
					}
				}
			} else if(account.types === 1 && !deliveryId) {
				if(item.integration_id === 9) {
					saveData.makePaymentLabel = "Післясплата"
				} else {
					saveData.makePaymentLabel = "Платіж NovaPay"
				}
			} else if(account.types === 2) {
					saveData.makePaymentLabel = 'Картка'
			} else {
				if(item.integration_id === 4) {
					saveData.makePaymentLabel = 'З поточного рахунку'
				} else if(item.integration_id === 9) {
					saveData.makePaymentLabel = 'Безготівка'
				} else {
					saveData.makePaymentLabel = 'Картка'
				}
			}
			data[item.api_key] = saveData
		})
	}

	return !!Object.keys(data).length && (
		<div>
			{!terminalMakePayment && <>
				{list.length > 1 && <div style={{marginTop: '-10px'}}><Button
					className="button-link-without-style"
					style={{fontSize: '0.8rem'}}
					onClick={() => AppStore.openModal(<MakePaymentListModal
						data={data}/>)}>{T('make-the-payment')}</Button></div>}
				{list.length === 1 && Object.values(data || {}).map((item, index) => (
					<div style={{marginTop: '-10px', fontSize: '0.8rem'}} key={`${item.name}-${index}`}>
						<b>{T("make-the-payment")}:</b> {item.makePaymentLabel}
					</div>
				))}
			</>}
			{terminalMakePayment && <div style={{marginTop: '-10px', fontSize: '0.8rem'}}>
				<b>{T("make-the-payment")}:</b> {terminalMakePayment}
			</div>}
		</div>
	);
};

export default MakePaymentLabel;