import React, { useEffect, useState } from "react";
import Columns from "react-bulma-components/lib/components/columns";
import T from "../../../components/i18n";
import s from "./styles.module.scss";
import StatusButton from "../common/status-button";
import ClientsStore from "../../clients/clients-store";
import ChannelBlock from "../../../components/channel-block/channel-block";
import SalesStore from "../../sales/sales-store";
import OrderDetailInfo from "./order-detail-info";
import NumberBlock from "../../../components/number-block";
import List from "react-bulma-components/lib/components/list";
import OrderProductRowMobile from "./order-product-row-mobile";
import ActionButtons from "./action-buttons";
import { Link } from "react-router-dom";
import CommunicationClient from "../../../components/communication-client";
import ClientInfoRow from "../../../components/client-info-row/client-info-row";
import OrdersStore from "../orders-store";
import {parseJSON} from "../../../tools/error-handler";
import formatDate from "../../../tools/format-date";

const OrderRowMobile = ({ data, columnLength, triggerIsOpen, isRefund, num }) => {
  const [isOpen, setIsOpen] = useState(false);

  const info = parseJSON(data.info)

  const name = `${T("order")} #${info?.order_text || data.order_id}`;
  const itemsCount = ` ${data?.order_data?.length} ${T(
    data?.order_data?.length > 1 ? "products-label" : "product-label"
  )}`;
  const channel_id = SalesStore.foundChannelsByIntegrationId(
    data?.integration_id
  );
  const clientInfo = data.client_id
    ? ClientsStore.getClientById(data.client_id)
    : null;

  let clientName;

  if (clientInfo) {
    clientName = (
      <span className='display-flex-row flex-wrap'>
        {data.client_id ? (
            <ClientInfoRow clientID={data.client_id} withoutPhone/>
          )
          : <span>{data?.first_name} {data?.last_name}</span>}
        <div className='display-flex-row'>
          <span className="nowrap"> +{clientInfo.phone}</span>
          <CommunicationClient phone={data.phone} style={{width: 'fit-content'}}/>
        </div>
      </span>
    );
  }

  const productOrderInfo = data.order_data.reduce(
    (acc, cur) => {
      acc.quantity = ((parseFloat(acc.quantity) + parseFloat(cur.quantity) || 0)).toFixed(2)
      return acc;
    },
    {
      quantity: 0,
    }
  );

  useEffect(() => {
    setIsOpen(triggerIsOpen);
  }, [triggerIsOpen]);

  const isReserve = data.order_data.some(item => {
    const pid = item.local_product_id
    const oid = data.order_id
    return OrdersStore.getIsReserveOrdersByOidPid(oid, pid);
  })

  let className = " is-one-";
  className += num % 2 === 0 ? "even" : "odd";

  return (
    <tr className={className}>
      <td colSpan={columnLength} align="left">
        <div className={s.wrapper}>
          {!isOpen && (
            <div
              align="right"
              style={{ position: "absolute", bottom: 0, right: 10 }}
            >
              <small>{formatDate(data.date_created)}</small>
            </div>
          )}
          <div
            style={{ paddingBottom: "10px" }}
            align="left"
            onClick={() => setIsOpen((prevState) => !prevState)}
          >
            <div className="display-flex-row-gap">
              <div className={s.wrapper_column} style={{width: "60%"}}>
                <b className="product-name">
                  {name} <br />
                  <span className="silver nowrap">
                    {itemsCount} ({productOrderInfo.quantity} {T("items-e")})
                  </span>
                </b>
                {data?.sales_id && (
                  <Link
                    to={{
                      pathname: "sales",
                      state: {
                        tab: "all",
                        status: "all",
                        period: new Date(data?.date_created),
                        search: String(data["sales_id"]),
                      },
                    }}
                  >
                    {T("main_sale")}: #{data.sales_id}
                  </Link>
                )}
                {!isOpen && <ChannelBlock channel_id={channel_id} />}
                {clientName && !isOpen && clientName}
              </div>
              <div className={s.wrapper_column} style={{"alignItems": "center"}}>
                <StatusButton data={data} isRefund={isRefund}/>
                {!isOpen && (
                  <>
                    <span className="text-success font-size-120 bold">
                      {data.price} {data.currency}
                    </span>
                    {data?.info?.is_paid ? (
                      <div>
                        <span className="channel_block has-background-success">
                          {T("paid")}
                        </span>
                        <br />
                        {!!data?.order_data[0]?.payment_type && (
                          <b>{data?.order_data[0]?.payment_type}</b>
                        )}
                      </div>
                    ) : (
                      <div>
                        {!!data?.order_data[0] &&
                          !!data?.order_data[0]?.payment_type && (
                            <b>{data?.order_data[0]?.payment_type}</b>
                          )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          {isOpen && (
            <>
              <OrderDetailInfo
                data={data}
                channel_id={channel_id}
                client={clientInfo}
              />
              <List>
                {!!data?.order_data?.length &&
                  data.order_data.map((item, index) => (
                    <OrderProductRowMobile
                      key={`${item.product_id}+${index}`}
                      data={item}
                      integrationID={data.integration_id}
                      currency={data.currency}
                      status={data?.order_status}
                      oid={data.order_id}
                      order_id={data.id}
                      sku={item?.sku || null}
                    />
                  ))}
              </List>
              <Columns className="is-mobile product-mobile-block">
                <Columns.Column size="half">
                  <NumberBlock
                    top="quantity"
                    number={productOrderInfo.quantity}
                    bottom={T(["items-p"])}
                    className="small-number-box"
                  />
                </Columns.Column>
                <Columns.Column>
                  <NumberBlock
                    top="product-info-price"
                    number={data["price"]}
                    bottom={data["currency"]}
                    className="small-number-box"
                  />
                </Columns.Column>
              </Columns>
              {!isRefund && <ActionButtons data={data} isReserve={isReserve}/>}
            </>
          )}
        </div>
      </td>
    </tr>
  );
};

export default OrderRowMobile;
