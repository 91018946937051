import React from 'react'
import {Button} from "react-bulma-components";
import T from '../../../../components/i18n'
import AppStore from "../../../../app-store";
import DocumentationModal from "./documentation-modal";
import {
	useIsMobile
} from "../../../../components/is-mobile-context/is-mobile-context";
import s from './styles.module.scss'
import { LocalIcon } from 'whitelables/wl-name/icons'
import UserStore from "../../../../user/user-store";


const DocumentationButton = ({sectionID, buttonTitle}) => {
	const {isMobileSize} = useIsMobile()

	const disabledDocumentation = UserStore.getMoreSetting('helpfulButtons')

	if(!disabledDocumentation?.doc && disabledDocumentation?.doc !== undefined){
		return <div/>
	}

	return isMobileSize ? (

		<span className={s.background} onClick={() => AppStore.openModal(<DocumentationModal sectionID={sectionID}/>)}>
			<LocalIcon icon='question-warning' size='large'/>
		</span>
	) : (
		<Button color='warning' size='small' rounded onClick={() => AppStore.openModal(<DocumentationModal sectionID={sectionID}/>)}>
			{T(buttonTitle || 'how-work-section?')}
		</Button>
	);
};

export default DocumentationButton;