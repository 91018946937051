import React from "react";
import Box from "react-bulma-components/lib/components/box";
import Heading from "react-bulma-components/lib/components/heading";
import T from "../../../../components/i18n";
import Money from "../../../../components/money";
import User from "../../../../user/user-store";
import AccountSingleMobile from "./account-single-mobile";

const AccountListMobile = ({
  list,
  onDeleteAccount,
  onEditAccount,
  onOpenStatistics,
}) => {
  return (
    <Box className="margin-08">
      <Heading size={5}>{T("your-accounts")}</Heading>
      <Heading subtitle className="your-money">
        <Money signClassname={true} amount={User.getBalance()} />
      </Heading>
      {list.map((item) => (
        <AccountSingleMobile
          key={item.id}
          data={item}
          onDeleteAccount={onDeleteAccount}
          onEditAccount={onEditAccount}
          onOpenStatistics={onOpenStatistics}
        />
      ))}
    </Box>
  );
};

export default AccountListMobile;
