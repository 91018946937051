import React, {useEffect, useState} from 'react'
import s from "./styles.module.scss";
import SocialLogin from "../../../modules/registration/social-login";
import {Input, Label} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import {Link, useHistory, useLocation} from "react-router-dom";
import AppStore from "../../../app-store";
import Button from "react-bulma-components/lib/components/button";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import UserActions from "../../../user/user-actions";
import HelpError from "./help-error";

const LoginForm = ({changeForm}) => {
	const {isMobileSize} = useIsMobile()
	const history = useHistory();
	const location = useLocation()
	const [email, setEmail] = useState({
		value: "",
		error: "",
	});
	const [password, setPassword] = useState({
		value: "",
		error: "",
	});

	const handleSubmit = () => {
		console.log("start login...");
		UserActions.login(email.value, password.value);
	};

	const handleChangeInput = ({ target: { value, name } }) => {
		if (name === "email") {
			setEmail({ value });
		}
		if (name === "password") {
			setPassword({ value });
		}
	};

	useEffect(() => {
		const loginFailed = UserActions.login.failed.listen((data) => {
			setEmail({ error: data });
		});


		return () => {
			loginFailed();
		};
	}, [history]);

	const handleRegistration = () => {
		changeForm()
		const pathname = location.pathname
		const replace = pathname.replace('login', 'registration')
		if(replace === "/") {
			history.push('/registration')
		} else {
			history.push(replace)
		}
	}

	return (
		<div className={`${s.container_form} ${s.container_login}`}>
			<div className={s.form}>
				{!AppStore.isMobileApp() && <SocialLogin type='icon'/>}
				<Label>{T('login-btn-label')}</Label>
				<Input
					size="large"
					name="email"
					type="text"
					inputMode="email"
					placeholder="E-mail"
					color="primary"
					onChange={handleChangeInput}
					value={email.value}
					className={s.form_input}
				/>
				{!!email.error && <HelpError error={email.error} />}
				<Input
					size="large"
					name="password"
					type="password"
					placeholder={T("password-placeholder")}
					color="primary"
					onChange={handleChangeInput}
					value={password.value}
					className={s.form_input}
				/>
				<Link to={AppStore.getLink("recovery-pass")}>
					{T("recovery-password-btn")}
				</Link>
				<Button
					fullwidth
					rounded
					color="primary"
					style={{height: 'auto', marginTop: '20px'}}
					onClick={handleSubmit}
				>
					{T("login-btn-label")}
				</Button>
				{isMobileSize && <button className={s.btn} id="signIn"
								 onClick={handleRegistration}>{T('registration')}</button>}
			</div>
		</div>
	);
};

export default LoginForm;