import React, {useEffect, useRef, useState} from 'react'
import PhonePresentationItem from "./phone-presentation-item";
import s from './styles.module.scss'
import SlideItem from "./slide-item";
import warehouse1 from "./warehouse_1.png";
import warehouse2 from "./warehouse_2.png";
import warehouse3 from "./warehouse_3.png";
import warehouse4 from "./warehouse_4.png";
import warehouse5 from "./warehouse_5.png";
import {Button} from "react-bulma-components";
import T from '../../../../components/i18n';
import {useHistory, useLocation} from "react-router-dom";
import AppStore from "../../../../app-store";

const slides = [
	{
		img: '/img/hp-black.png',
		title: 'title-slide-app-start',
		type: 'start'
	},
	{
		img: warehouse1,
		title: 'title-slide-app-1',
		subtitle: 'subtitle-slide-app-1'
	},
	{
		img: warehouse2,
		title: 'title-slide-app-2',
		subtitle: 'subtitle-slide-app-2'
	},
	{
		img: warehouse3,
		title: 'title-slide-app-3',
		subtitle: 'subtitle-slide-app-3'
	},
	{
		img: warehouse4,
		title: 'title-slide-app-4',
		subtitle: 'subtitle-slide-app-4'
	},
	{
		img: warehouse5,
		title: 'title-slide-app-5',
		subtitle: 'subtitle-slide-app-5'
	},
]

const PhonePresentation = ({onOpenRegistration}) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [touchStartX, setTouchStartX] = useState(0);
	const location = useLocation()
	const history = useHistory()
	const actions = useRef(null);
	const [heightAction, setHeightAction] = useState(0)

	const nextSlide = () => {
		if(currentIndex !== slides.length - 1){
			setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
		}
	};

	const prevSlide = () => {
		if(currentIndex !== 0) {
			setCurrentIndex((prevIndex) =>
				prevIndex === 0 ? slides.length - 1 : prevIndex - 1
			);
		}
	};

	const handleTouchStart = (e) => {
		setTouchStartX(e.touches[0].clientX);
	};

	const handleTouchEnd = (e) => {
		const touchEndX = e.changedTouches[0].clientX;
		const diff = touchStartX - touchEndX;

		if (diff > 50) nextSlide();
		if (diff < -50) prevSlide();
	};


	const handleLogin = () => {
		const pathname = location.pathname
		const replace = pathname.replace('registration', 'login')
		if(replace === "/") {
			history.push('/login')
		} else {
			history.push(replace)
		}

		AppStore.setState({
			isAppPresent: false
		})
		onOpenRegistration()
	}

	const handleRegistration = () => {
		const pathname = location.pathname
		const replace = pathname.replace('login', 'registration')
		if(replace === "/") {
			history.push('/registration')
		} else {
			history.push(replace)
		}

		AppStore.setState({
			isAppPresent: false
		})
		onOpenRegistration()
	}

	useEffect(() => {
		if (!actions.current) return;

		const observer = new ResizeObserver((entries) => {
			for (let entry of entries) {
				setHeightAction(entry.contentRect.height + 40);
			}
		});

		observer.observe(actions.current);

		return () => observer.disconnect();
	}, []);


	return (
		<div className={s.wrapper}
				 onTouchStart={handleTouchStart}
				 onTouchEnd={handleTouchEnd}
		>
			<div style={{ transform: `translateX(-${currentIndex * window.innerWidth}px)`, maxHeight: `${window.innerHeight - heightAction}px`}} className={s.slides}>
				{slides.map((data) => (
					<PhonePresentationItem key={data.title} data={data}/>
				))}
			</div>
			<div className={s.slide_item_wrapper} ref={actions}>
				{!currentIndex && <div className={s.wrapper_start}>
					<Button onClick={nextSlide} fullwidth style={{fontSize: '1.5rem', borderRadius: 28, color: '#016be7'}} color='warning'>{T('lets-go-label')}!</Button>
					<Button onClick={handleLogin} className='button-link-without-style' style={{color: '#fff', fontSize: '1.3rem', padding: 10}}>{T('already-have-an-account')}!</Button>
				</div>}
				{currentIndex === slides.length - 1 && <Button fullwidth onClick={handleRegistration} style={{fontSize: '1.5rem', borderRadius: 28, color: '#016be7'}} color='warning'>{T('registration')}</Button>}
				{!!currentIndex && currentIndex !== slides.length - 1 && slides.map((data, index) => (
					<SlideItem key={`slide-dot-${index}`} active={index === currentIndex} onClick={() => setCurrentIndex(index)}/>
				))}
			</div>
		</div>
	);
};

export default PhonePresentation;