import React, {useEffect, useState} from "react";
import TextareaModal from "../../../../components/textarea-modal/textarea-modal";
import ErrorMessageControlHook from "../../../../components/error-message-block/error-message-control.hook";
import SalesActions from "../../sales-actions";
import {errorHandler} from "../../../../tools/error-handler";

const AddCommentModal = ({ onClose, comment, oid, isRefund }) => {
  const { setErrorMessage, errorMessage, handleAutoRemoveError } =
    ErrorMessageControlHook();
  const [waiting, setWaiting] = useState(false);
  const [commentState, setCommentState] = useState(comment || "");

  const handleSubmit = () => {
    if(commentState.length > 2000) {
      handleAutoRemoveError('error-long-comment');
    } else {
      setWaiting(true)
      const data = {
        comment: commentState,
        oid
      }
      SalesActions.editComment(data, isRefund)
    }
  };

  const handleChangeComment = ({ target: { value } }) => {
    setCommentState(value);
  };

  useEffect(() => {
    const editCompleted = SalesActions.editComment.completed.listen(() => {
      onClose()
    })
    const editFailed = SalesActions.editComment.failed.listen((res) => {
      const message = errorHandler(res)
      setWaiting(false)
      handleAutoRemoveError(message)
    })

    return () => {
      editCompleted()
      editFailed()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <TextareaModal
      value={commentState}
      onChange={handleChangeComment}
      onClose={onClose}
      onSubmit={handleSubmit}
      onError={setErrorMessage}
      errorMessage={errorMessage}
      waiting={waiting}
      submitTitle="btn-save"
      label="comment"
      heading={comment ? 'edit-comment' : 'add-comment'}
    />
  );
};

export default AddCommentModal;
