import React from "react";
import T from "../../../components/i18n";
import OpenInsideModal from "../../../components/modal/open-inside-modal";
import DiscountSettingsModal from "../discount-settings/discount-settings-modal";
import AddClientModal from "../modal/add-client-modal/add-client-modal";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";

const ActionButtons = () => {
  const {isMobileSize} = useIsMobile()
  return (
    <div className="ex-open-modal-wrapper display-flex-row-gap margin-08" style={{ flexWrap: "wrap", alignItems: 'center' }}>
      <DocumentationButton sectionID='clients'/>
      <div className="display-flex-row flex-wrap">
        <OpenInsideModal text='add-clients-btn' icon='plus-circle' size={isMobileSize ? "small" : "medium"}>
          <AddClientModal className='modal-start'/>
        </OpenInsideModal>
        <OpenInsideModal
          text={T("adjust-discounts-btn")}
          size={isMobileSize ? "small" : "medium"}
        >
          <DiscountSettingsModal />
        </OpenInsideModal>
      </div>
    </div>
  );
};

export default ActionButtons;
