import React from 'react'
import {
	Control,
	Field,
	Input, Label
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import {Icon} from "react-bulma-components";
import {
	useIsMobile
} from "../../../../../components/is-mobile-context/is-mobile-context";

const InputsGroupField = ({dataInput, inputValues,styleField, onChange, disabledMargin}) => {
	const {isMobileSize} = useIsMobile()
	return dataInput.map(({name, placeholder, label,icon,type,inputmode, ...rest}) => (
			<Field className={disabledMargin ? '' : "margin-top-20"} key={name} style={styleField ? styleField : {}}>
				{label && <Label>{T(label)}</Label>}
				<Control iconRight={!!icon}>
					<Input
						size="large"
						type={type ? type : "text"}
						name={name}
						value={inputValues[name] || ''}
						placeholder={T(placeholder)}
						onChange={onChange}
						inputMode={isMobileSize ? inputmode : null}
						{...rest}
					/>
					{icon && <Icon align='right'>
						<span>
							{typeof icon === 'string' ? T(icon) : icon}
						</span>
					</Icon>}
				</Control>
			</Field>
		))
};

export default InputsGroupField;