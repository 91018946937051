import React, {useEffect, useState} from 'react'
import {Box, Button, Heading} from "react-bulma-components";
import T from "../../../../components/i18n";
import {
	Checkbox, Field,
	Label
} from "react-bulma-components/lib/components/form";
import SubmitButton from "../../../../components/submit-button";
import ErrorMessageControlHook
	from "../../../../components/error-message-block/error-message-control.hook";
import UserActions from "../../../../user/user-actions";
import {errorHandler} from "../../../../tools/error-handler";
import AddClientsNotDefault from "./add-clients-not-default";
import {
	INPUT_DATA_ADD_CLIENTS
} from "../../../clients/modal/add-client-modal/add-client-modal";
import UserStore from "../../../../user/user-store";

const AdditionalSettingsAccount = ({onClose}) => {
	const getClientsDefaultValues = UserStore.getMoreSetting('addClientsDefault')
	const addClientsDefaultValues = Object.values(INPUT_DATA_ADD_CLIENTS).reduce((acc,cur) => {
		if(getClientsDefaultValues) {
			if(getClientsDefaultValues[cur.name]) {
				acc[cur.name] = {
					ch: getClientsDefaultValues[cur.name].ch,
				};
			} else {
				acc[cur.name] = {
					ch: false,
				};
			}
		} else {
			if(cur.notDefault) {
				acc[cur.name] = {
					ch: true,
				}
			} else {
				acc[cur.name] = {
					ch: false,
				};
			}
		}

		return acc
	}, {});

	const helpfulButtons = UserStore.getMoreSetting('helpfulButtons')
	const chatSet = UserStore.getMoreSetting('chatSet')
	const addPrint = UserStore.getMoreSetting('addPrint')

	const {handleAutoRemoveError,errorMessage,ErrorMessageBlock,setErrorMessage} = ErrorMessageControlHook()
	const [waiting, setWaiting] = useState(false);
	const [values, setValues] = useState({
		addClientsDefault: addClientsDefaultValues,
		helpfulButtons: Object.keys(helpfulButtons || {}).length ? helpfulButtons : {
			doc: true
		},
		chatSet: Object.keys(chatSet || {}).length ? chatSet : {
			sound: true,
			popup: true
		},
		addPrint: Object.keys(addPrint || {}).length ? addPrint : {
			saleBrand: false
		},
	})

	useEffect(() => {
		const updateSetting = UserActions.updateSettings.completed.listen(() => {
			onClose()
		})
		const updateSettingFail = UserActions.updateSettings.failed.listen(res => {
			const message = errorHandler(res)
			setWaiting(false)
			handleAutoRemoveError(message)
		})
		return () => {
			updateSettingFail()
			updateSetting()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	const handleChangeClientsDefault = ({target: {name, checked}}) => {
		setValues(prev => ({
			...prev,
			"addClientsDefault": {
				...prev['addClientsDefault'],
				[name]: {
					ch:checked
				}
			}
		}))
	}

	const handleChangeFieldCheckbox = ({target: {name, checked}}, nameField) => {
		setValues(prev => ({
			...prev,
			[nameField]: {
				...prev[nameField],
				[name]:checked
			}
		}))
	}

	const handleSubmit = () => {
			setWaiting(true)
			const clientData = Object.entries(values.addClientsDefault).reduce((acc, [key, value]) => {
				if(value.ch) {
					acc[key] = value
				}
				return acc
			}, {})

			const dataSubmitMoreSettings = {
				addClientsDefault: clientData,
				helpfulButtons: values.helpfulButtons,
				chatSet: values.chatSet,
				addPrint: values.addPrint,
			}

			UserStore.setMoreSettingManyList(dataSubmitMoreSettings)
	}

	const handleResetSetting = () => {
		setWaiting(true)
		UserStore.deleteMoreSetting(['addClientsDefault', "helpfulButtons", 'chatSet', 'addPrint'])
	}

	return (
		<>
			<ErrorMessageBlock message={errorMessage} onRemove={setErrorMessage}/>
			<Box>
				<Heading>{T('additional-settings-account')}</Heading>
				<Label size='large'>{T('clients')}</Label>
				<Label >{T('additional-info')}</Label>
				<AddClientsNotDefault values={values.addClientsDefault} onChange={handleChangeClientsDefault}/>
				<Label size='large' className='margin-top-10'>{T('settings')}</Label>
				<Label >{T('helpful-buttons')}</Label>
				<Field>
					<Checkbox onChange={(evt) => handleChangeFieldCheckbox(evt, "helpfulButtons")} name='doc' checked={values.helpfulButtons.doc}>{T('documentation')}</Checkbox>
				</Field>
				<Label size='large' className='margin-top-10'>{T('chats')}</Label>
				<Field>
					<Checkbox onChange={(evt) => handleChangeFieldCheckbox(evt, "chatSet")} name='sound' checked={values.chatSet.sound}>{T('message-sound')}</Checkbox>
				</Field>
				<Field>
					<Checkbox onChange={(evt) => handleChangeFieldCheckbox(evt, "chatSet")} name='popup' checked={values.chatSet.popup}>{T('popup')}</Checkbox>
				</Field>
				<Label size='large' className='margin-top-10'>{T('print')}</Label>
				<Label >{T('sales')}</Label>
				<Field>
					<Checkbox onChange={(evt) => handleChangeFieldCheckbox(evt, 'addPrint')} name='saleBrand' checked={values.addPrint.saleBrand}>{T('add-brand-column-print')}</Checkbox>
				</Field>

				<div className='display-flex-row margin-top-10 flex-wrap' style={{justifyContent: 'space-between'}}>
					<SubmitButton size='small' fullwidth={false} color='warning' text='reset-to-default-columns' submit={handleResetSetting} waiting={waiting}/>
					<div className='display-flex-row'>
						<Button onClick={onClose} size='large'>{T('close')}</Button>
						<SubmitButton text='btn-save' color='success' submit={handleSubmit} fullwidth={false} waiting={waiting}/>
					</div>
				</div>
			</Box>
		</>
	);
};

export default AdditionalSettingsAccount;