import React from 'react'
import {
	INPUT_DATA_ADD_CLIENTS
} from "../../../clients/modal/add-client-modal/add-client-modal";
import {
	Checkbox,
	Field
} from "react-bulma-components/lib/components/form";
import T from '../../../../components/i18n'

const AddClientsNotDefault = ({values, onChange}) => {
	return (
		<div style={{display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(110px, 1fr))"}}>
			{Object.values(INPUT_DATA_ADD_CLIENTS).map(item => !item.notSettings && (
				<Field key={item.name}>
					<Checkbox name={item.name} checked={values[item.name].ch} onChange={onChange}>
						{T(item.label)}
					</Checkbox>
				</Field>
			))}
		</div>
	);
};

export default AddClientsNotDefault;