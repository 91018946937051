import React from "react";
import OpenModalButton from "../../../components/modal/open-modal-button";
import T from "../../../components/i18n";
import {
  useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import DocumentationButton
  from "whitelables/wl-name/common/documentation-button/documentation-button";
const ActionsButton = () => {
  const {isMobileSize} = useIsMobile()
  return (
    <div
      className="display-flex-row-gap flex-wrap margin-bottom-10 top-button margin-08"
    >
      <DocumentationButton sectionID='shipments'/>
      <div className="display-flex-row flex-wrap">
        <OpenModalButton
          link="/shipments/add-shipment-base"
          text={T("create-product-delivery")}
          icon="plus-circle"
          size={isMobileSize ? 'small' : 'medium'}
        />
      </div>
    </div>
  );
};

export default ActionsButton;
