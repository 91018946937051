import React, { useState } from "react";
import CustomPrice from "./custom-price";
import { Box, Button, Heading } from "react-bulma-components";
import AddFieldList from "./add-field-list";
import {
  Control,
  Field,
  Label,
  Textarea,
} from "react-bulma-components/lib/components/form";
import T from "../../../../../components/i18n";
import InputsGroupField from "../../../../settings/integrations-nw/modal/common/inputs-group-field";
import DatePicker from "react-datepicker/es";
import UserStore from "../../../../../user/user-store";

const INPUT_DATA = [
  {
    name: "size_height",
    label: "height",
    icon: 'cm-short',
    inputmode: 'decimal'
  },
  {
    name: "size_width",
    label: "width",
    icon: 'cm-short',
    inputmode: 'decimal'
  },
  {
    name: "size_length",
    label: "length",
    icon: 'cm-short',
    inputmode: 'decimal'
  },
  {
    name: "weight",
    label: "weight",
    icon: 'kg-short',
    inputmode: 'decimal'
  },
  {
    name: "ukzt",
    label: "ukzt",
  },
  {
    name: "storage_place",
    label: "storage-place",
  },
];

const AdditionalFieldsInfo = ({
  source,
  onChangeCustomPrice,
  onChangeAdditional,
  onChangeInput,
  onChangeTextArea,
  type,
  state,
}) => {
  const [isOpen, setIsHide] = useState(false);

  const handleChangeDate = (date) => {
    const evt = {
      target: {
        name: "expirated_date",
        value: date,
      },
    };
    onChangeInput(evt);
  };

  const selectedDate = state["expirated_date"] && new Date(state["expirated_date"])


  return (
    <Box className="margin-top-10">
      <div className="display-flex-row-gap ">
        <Heading style={{margin: 0}}>{T('more-info')}</Heading>
        <Button
          color="warning"
          size="small"
          onClick={() => setIsHide(!isOpen)}
          rounded
        >
          {isOpen ? T('hide-more-filter') : T('unfold')}
        </Button>
      </div>
      {isOpen && (
        <div className='margin-top-10'>
          {type !== 'services' && <Field>
            <Label>{T("expired-date-to")}</Label>
            <DatePicker
              selected={
                state["expirated_date"] && isNaN(selectedDate.getTime()) ? '' : selectedDate
              }
              onChange={handleChangeDate}
              className="input"
              name="expirated_date"
              dateFormat="dd-MM-yyyy"
              locale={UserStore.getLang()}
              minDate={new Date()}
            />
          </Field>}

          {onChangeTextArea && <Field>
            <Control>
              <Label>{T("product-description")}</Label>
              <Textarea
                size="large"
                name="desc"
                type="text"
                placeholder={T("product-description-placeholder")}
                onChange={onChangeTextArea}
                color={state.desc.error ? "danger" : null}
                value={state.desc.value}
              />
            </Control>
          </Field>}
          {source !== "shipment" && (
            <CustomPrice
              onChange={onChangeCustomPrice}
              values={state.custom_price}
            />
          )}
          <AddFieldList
            list={state?.fields || []}
            values={state?.af || {}}
            onChange={onChangeAdditional}
          />
          {type !== 'services' && <div className='display-flex-row flex-wrap' style={{gap: "10px"}}>
            <InputsGroupField
              styleField={{flex: 1, minWidth: "48%", margin: 0}}
              dataInput={INPUT_DATA}
              inputValues={state}
              onChange={onChangeInput}
            />
          </div>}
        </div>
      )}
    </Box>
  );
};

export default AdditionalFieldsInfo;
