import React, { useState } from "react";
import Money from "../../../components/money";
import { LocalIcon } from "whitelables/wl-name/icons";
import EmployeesSubrowDesktop from "./employees-subrow-desktop";
import ProductsStore from "../../products/products-store";
import UserStore from "../../../user/user-store";
import T from '../../../components/i18n'
import SalesStore from "../../sales/sales-store";

const EmployeesRowDesktop = ({ data, num }) => {
  const [isOpenDetailInfo, setIsOpenDetailInfo] = useState(false);

  const handleToggleIsOpenDetailInfo = () => {
    if (data.sumAccrual > 0) {
      setIsOpenDetailInfo((prev) => !prev);
    }
  };

  let className = " is-one-";
  className += num % 2 === 0 ? "even" : "odd";

  return (
    <>
      <tr
        onClick={handleToggleIsOpenDetailInfo}
        className={
          `${data.sumAccrual > 0
            ? `cursor-pointer  ${isOpenDetailInfo && "selected-border"}`
            : ""} ${className}`
        }
        style={data.is_active ? {} : { opacity: "0.5" }}
      >
        <td style={{ width: "33px" }}>
          {data.sumAccrual > 0 && (
            <div
              className={`display-flex-center black icon-open ${
                isOpenDetailInfo ? "active" : ""
              }`}
            >
              <LocalIcon icon="arrow-left" size="small" />
            </div>
          )}
        </td>
        <td>
          {data.name} {data.phone && `(${data.phone})`}
          <br />
          <small>{data.email}</small>
        </td>
        <td>
          <Money amount={data.sales_amount} />
        </td>
        {UserStore.getPermissionEnable("incomeinformation") && <td>
          <Money amount={data.sales_profit}/>
        </td>}
        <td>{data?.sales_total}</td>
        <td>{data?.sales_units}</td>
        <td>{data?.numDays}</td>
        <td>
          <Money amount={data.sumAccrual} />
        </td>
      </tr>
      {isOpenDetailInfo && !!data.accrual_general.amount && (
        <EmployeesSubrowDesktop
          name={T("general")}
          data={data.accrual_general}
          accrual={data.general}
          accrualType={data.general_type}
          />
      )}
      {isOpenDetailInfo &&
        Object.keys(data?.accrual.category).map(
          (item) =>
            !!data?.accrual.category[item].amount && (
              <EmployeesSubrowDesktop
                key={`${data.user_id}_${item}}`}
                name={ProductsStore.getCategoryName(item)}
                data={data?.accrual.category[item]}
                accrual={data.category[item].value}
                accrualType={data.category[item].type}
              />
            )
        )}
      {isOpenDetailInfo &&
        Object.keys(data?.accrual.brand).map(
          (item) =>
            !!data?.accrual.brand[item].amount && (
              <EmployeesSubrowDesktop
                key={`${data.user_id}_${item}}`}
                name={ProductsStore.getBrandName(item)}
                data={data?.accrual.brand[item]}
                accrual={data.brand[item].value}
                accrualType={data.brand[item].type}
              />
            )
        )}
      {isOpenDetailInfo &&
        Object.keys(data?.accrual.channel).map(
          (item) =>
            !!data?.accrual.channel[item].amount && (
              <EmployeesSubrowDesktop
                key={`${data.user_id}_${item}}`}
                name={T(SalesStore.getChannelsName(parseInt(item)))}
                data={data?.accrual.channel[item]}
                accrual={data.channel[item].value}
                accrualType={data.channel[item].type}
              />
            )
        )}
    </>
  );
};

export default EmployeesRowDesktop;
