import React, {useEffect, useRef} from 'react'

const TextareaResize = (props) => {

	const textareaRef = useRef(null);

	const resizeTextarea = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = "auto";
			textarea.style.height = `${textarea.scrollHeight}px`;
		}
	};

	useEffect(resizeTextarea, []);


	return (
		<textarea {...props}
							className="textarea is-medium"
							rows={1}
							ref={textareaRef}
							style={{ width: "100%", resize: "none", overflow: "hidden" }}
							onInput={resizeTextarea}
		/>
	);
};

export default TextareaResize;