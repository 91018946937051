import React from "react";
import SalesStore from "../../sales/sales-store";
import Actions from "../orders-actions";
import AppStore from "../../../app-store";
import EditSalesModal from "../../sales/edit-sales-modal";
import ChangeStatusModal from "../change-status";
import {confirmDelete} from "../../../tools/confirm-delete";
import OrdersActions from "../orders-actions";
import AddSalesModal from "../../sales/add-sales-modal";

export const openSaleModal = ({evt, data}) => {
	evt.stopPropagation()
	let channel_id = SalesStore.foundChannelsByIntegrationId(data['integration_id']),
		order_data = JSON.parse(data['address_1']);

	let delivery_info = ''

	if(order_data.delivery_operator === 'ukrposhta'){
		delivery_info = 3
	} else if(order_data.delivery_operator === 'nova_poshta') {
		delivery_info = 1
	} else if(order_data.delivery_operator === 'delivery_auto') {
		delivery_info = "delivery_auto"
	} else if(order_data.delivery_operator === 'rozetka_delivery') {
		delivery_info = 6
	}


	AppStore.openModal(<AddSalesModal
		channel_id={[channel_id]}
		client_id={data.client_id}
		discount={Math.abs(data.discount || 0)}
		tracking_number={(order_data.ttn || '')}
		discount_type={!!data.discount && 'stable'}
		preorder={data}
		comment={data?.info?.comment || ''}
		delivery_info={delivery_info}
		clients_delivery_id={order_data?.clients_delivery || ''}
	/>)

}

export const updateOrder = (data, _type, response) => {
	let request = {
		'oid': data['order_id'],
		'iid': data['integration_id']
	}

	request['status'] = _type;

	if(response) {
		request['cancel_reason'] = response;
	}
	OrdersActions.updateStatusOrder(request);
}

export const bindingSubmit = (pid, rid, iid) => {
	let request = {
		'pid': pid,
		'rid': rid,
		'iid': iid
	}

	Actions.binding(request);
}

export const deleteOrder = ({evt, data}) => {
	evt.preventDefault()

	const request = {
		'oid': data['order_id'],
		'iid': data['integration_id']
	}

	confirmDelete(() => OrdersActions.delete(request),
		'confirm-delete',
		'are-you-sure-to-delete-this-order',
	)
}

export const editOrderModal = (salesID) => {
	AppStore.openModal(<EditSalesModal oid={salesID}/>)
}

export const changeStatus = ({evt, data}) => {
	evt.stopPropagation()
	AppStore.openModal(<ChangeStatusModal
		modal="change-status"
		submit={updateOrder}
		data={data}/>)
}

export const deleteReserveOrders = (oid, message) => {
	confirmDelete(() => OrdersActions.deleteReserve([{roid: oid }]), 'confirm-delete-reserve',message, 'confirm-order-shift', 'cancel-label')
}