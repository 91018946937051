import {createActions} from "../../tools/reflux-tools";

const WarehouseActions = createActions({

    load: { asyncResult: true },
    addWarehouse: { asyncResult: true },
    deleteWarehouse: { asyncResult: true },
  
});


export default WarehouseActions;