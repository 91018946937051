import React, {useEffect, useRef, useState} from 'react'
import s from "./styles.module.scss";
import SocialLogin from "../../../modules/registration/social-login";
import {Input, Label} from "react-bulma-components/lib/components/form";
import T from "../../../components/i18n";
import Button from "react-bulma-components/lib/components/button";
import trackEvent from "../../../tools/track-events";
import UserStore from "../../../user/user-store";
import {getTrafficSource} from "../../../modules/registration/trafic-source";
import yajax from "yajax";
import UserActions from "../../../user/user-actions";
import {useHistory, useLocation} from "react-router-dom";
import AppStore from "../../../app-store";
import {isIOS} from "react-device-detect";
import isValidEmail from "../../../tools/valid-email";
import {
	useIsMobile
} from "../../../components/is-mobile-context/is-mobile-context";
import HelpError from "./help-error";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import {currencies} from "../../../configs/currencies";
import InputWithLabelSelect
	from "../../../components/input-with-label-select/input-with-label-select";

const RegistrationForm = ({setError, changeForm}) => {
	const location = useLocation()
	const searchParams = new URLSearchParams(location.search);
	const {isMobileSize} = useIsMobile()
	const history = useHistory()

	const [values, setValues] = useState({
		email: {
			error: "",
			value: ""
		},
		phone: {
			error: "",
			value: ""
		},
		password: {
			error: "",
			value: ""
		},
		pcode: {
			error: '',
			value: searchParams.get('pcode') || ""
		},
		currency_id: {value: 0},
		isCheckBot: false,
		tarif: location?.tarif || false,
	})
	const [showMore, setShowMore] = useState(false)
	const [currencyID, setCurrencyID] = useState({value: 2})
	const ref = useRef(null);
	const [widthDropdown, setWidthDropdown] = useState(0)
	const [infoCountry, setInfoCountry] = useState({
		symbol: currencies['2'].symbol,
		country: currencies['2'].country,
	})

	useEffect(() => {
		const handleResize = () => {
			if(ref.current){
				const {width} = ref.current.numberInputRef.getBoundingClientRect()
				setWidthDropdown(width);
			}
		}
		handleResize()
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		}
	},[])


	const whatApp = () => {
		return AppStore.isMobileApp() ? isIOS ? "ios" : "android" : isMobileSize ? "mobile" : "desktop"
	}

	const handleAfterSubmit = (data) => {
			if (data.success) {
				const email = values.email.value || values.phone.value,
							pass = values.password.value;

				trackEvent('users', 'registration', 'manual', '1');
				UserStore.setFirstReg(true);
				UserActions.login(email, pass);
				window.localStorage.removeItem('partner_id');
			}
	}

	const handleSubmit = () => {
		console.log("start submit")
		if (!values.isCheckBot) {
			trackEvent('guest', 'registration_page', 'problem_check_box');
			console.log("not check box");
		}

		const email = values.email.value,
					phone = values.phone.value,
					pass = values.password.value;

		if (values.email.error !== ""){
			setError('wrong-email')
		}

		if(values.password.error !== "" || !values.password.value) {
			setError('wrong-password')
			return false;
		}

		if (values.phone.error !== "") {
			setError('wrong-phone')
			return false;
		}

		if(!values.email.value || !values.phone.value){
			setError('phone-or-email-required')
			return false;
		}


		const trafficData = getTrafficSource();


		const params = {
			method: "POST",
			url: "/userprofile/register",
			headers: {
				"Content-type": "application/x-www-form-urlencoded"
			},
			data: {
				"email": email,
				"password": pass,
				"app": whatApp(),
				"source": trafficData.source,
				"medium": trafficData.medium,
				"campaign": trafficData.campaign,
				"phone": phone,
				"pcode": values.pcode.value || '',
				"currency_id": currencyID.value,
				"lang": UserStore.getLang(),
			}
		};

		if(Intl.DateTimeFormat().resolvedOptions().timeZone) {
			params.data.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
		} else {
			params.data.timezone = currencies[currencyID.value].timezone
		}

		if(infoCountry.country){
			params.data.country = infoCountry.country
		} else {
			params.data.country = "UA"
		}

		const partner_id = window.localStorage.getItem('partner_id');
		if (partner_id) {
			params.data['partner_id'] = partner_id;
		}

		return yajax(params).then(handleAfterSubmit, (error) => {
			console.log("response error ", error);
			const response = JSON.parse(error['response']);
			if ('error' in response) {
				if (response['error']['code'] === "email-already-exists" || response['error']['code'] === "phone-already-exists") {
					setError(T("account-already-exists"))
				} else if(response['error']['code']) {
					setError(T(response['error']['code']))
				}
			}
		});
	}

	const handleChangeInput = ({target: {value,name}}) => {
		setValues(prev => ({
			...prev,
			[name]: {
				value: value,
				error: ""
			},
		}))
	}

	const handleCleanError = (name, value) => {
		setValues(prev => ({
			...prev,
			[name]: {
				'error': '',
				'value': value
			}
		}))
	}

	const handleBlur = ({target: {value, name}}) => {
		handleCleanError(name, value);

		let error;
		if (name === "email") {
			error = (!isValidEmail(value)) ? "wrong-email" : "";
		} else if (name === "phone") {
			error = value.length <= 4 ? "wrong-phone" : "";
		} else if (name === "password" ) {
			error = (values.password.value.length <= 3) ? "wrong-password" : "";
		}
		setValues(prev => ({
			...prev,
			[name]: {'error': error, 'value': value}
		}))
	}



	const currencyList = Object.entries(currencies).map(([key, value]) => {
		return {...value, name: value.code, full_name: value.name, id: key}
	})

	const handleChangePhone = (value, countryInfo) => {
		handleChangeInput({target: {value, name: 'phone'}});
		const findCountry = currencyList.find((value) => {
			return value.country === (countryInfo.countryCode).toUpperCase();
		})

		if(findCountry) {
			setInfoCountry({
				symbol: findCountry.symbol,
				country: findCountry.country
			})
			setCurrencyID({value: findCountry.id})
		} else {
			setInfoCountry({
				symbol: currencies['1'].symbol,
				country: countryInfo.countryCode.toUpperCase(),
			})
			setCurrencyID({value: "1"})
		}
	}


	const handleChangeSelect = ({value}) => {
		setCurrencyID({value})
		setInfoCountry(prev => ({
			...prev,
			symbol: currencies[value].symbol,
		}))
	}

	const handleLogin = () => {
		changeForm()
		const pathname = location.pathname
		const replace = pathname.replace('registration', 'login')
		if(replace === "/") {
			history.push('/login')
		} else {
			history.push(replace)
		}
	}

	return (
		<div className={`${s.container_form} ${s.container_register}`}>
			<div className={s.form}>
				{!AppStore.isMobileApp() && <SocialLogin type='icon'/>}
				<Label>{T('registration')}</Label>
				<Input
					size="large"
					name="email"
					type="text"
					inputMode="email"
					placeholder={T('email-placeholder')}
					color="primary"
					value={values.email.value}
					onChange={handleChangeInput}
					onBlur={handleBlur}
					className={s.form_input}
				/>
				<HelpError error={values.email.error}/>
				<PhoneInput onBlur={(evt) => {
					evt.target.name = 'phone'
					handleBlur(evt)
				}} containerStyle={{zIndex: 9}} onChange={handleChangePhone} dropdownStyle={{width: widthDropdown}} ref={ref} name='phone' enableSearch value={values.phone.value} country="ua" inputClass='react-input-phone' buttonClass="react-input-button" placeholder={T('phone-placeholder')}/>
				<HelpError error={values.phone.error}/>
				<Input
					size="large"
					name="password"
					type="password"
					placeholder={T("password-placeholder")}
					color="primary"
					value={values.password.value}
					onChange={handleChangeInput}
					onBlur={handleBlur}
					className={s.form_input}
				/>
				<HelpError error={values.password.error}/>
				{showMore && <>
					<InputWithLabelSelect  value={infoCountry.symbol}  disabledInput selectList={currencyList} label='base-currency' onChangeSelect={handleChangeSelect} valueSelect={currencyID} />
					<Input
						size="large"
						name="pcode"
						type="text"
						placeholder={T('promo-code')}
						value={values.pcode.value}
						onChange={handleChangeInput}
						className={s.form_input}
					/>
				</>}
				<Button className='button-link-without-style' onClick={() => setShowMore(prev => !prev)}>{T(showMore ? 'hide-label' : 'show-more-label')}</Button>
				<Button
					fullwidth
					rounded
					color="primary"
					style={{height: 'auto', marginTop: '20px'}}
					onClick={handleSubmit}
				>
					{T("registration")}
				</Button>
				{isMobileSize && <button className={s.btn} id="signIn"
								 onClick={handleLogin}>{T('login-btn-label')}</button>}
			</div>
		</div>
	);
};

export default RegistrationForm;