import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  forwardRef, useImperativeHandle
} from "react";
import s from "./styles.module.scss";
import { LocalIcon } from "whitelables/wl-name/icons";
import SelectItem from "./select-item";
import T from "../i18n";
import { filterSearch } from "../../tools/filters-helper";
import InfoWrapper from "../info-wrapper";
import {Button} from "react-bulma-components";
import SelectItemProduct from "./select-item-product";
import ScanSelectItem from "./scan-select-item";
import {useIsMobile} from "../is-mobile-context/is-mobile-context";
import PaginationBlock from "../pagination";
import AppStore from "../../app-store";

const Select = forwardRef(({
  name,
  placeholder,
  list = [],
  isCreate,
  label,
  empty,
  infoLabel,
  value,
  addItem,
  addItemModal,
  disabledAutoSelect,
  onChange,
  styleWrapper,
  maxHeightSelect,
  onSearch,
  disabledLabel,
  withPagination,
  type,
  selected,
  styleSelect,
  additionalTopContent,
  single, zIndex, withoutMid, source, onEnter, disabledKeyStore
}, ref) => {
  const refWrapper = useRef(null);
  const refInput = useRef(null);
  const refValue = useRef(null);
  const refMenu = useRef(null);

  const [isFocus, setIsFocus] = useState(false);
  const [selectListInfo, setSelectListInfo] = useState({width: 0, top: 0, height: maxHeightSelect || 220});
  const [search, setSearch] = useState("");
  const [selectValue, setSelectValue] = useState(empty && !value ? { value: 0 } : value ? value : {});
  const {isMobileSize} = useIsMobile()

  const [isOpen, setIsOpen] = useState(false);
  const [clickTimer, setClickTimer] = useState(null);

  const handleOnFocusInput = () => {
    setIsFocus(true);
  };

  useImperativeHandle(ref, () => ({
    getSearch: () => {
      return search;
    },
  }));

  useEffect(() => {
    if(value && JSON.stringify(value) !== JSON.stringify(selectValue)) {
      setSelectValue(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])


  const handleOnBlurInput = () => {
    if(type !== 'product') {
      setIsFocus(false);
      setTimeout(() => {
        setSearch("");
        isOpen && setIsOpen(false)
        updateSelectListInfo()
      }, 200);
    }
  };

  const handleOpen = (disabledClose) => {
    setIsOpen((prev) => {
      if(prev && disabledClose) {
        return prev
      }

      if(prev) {
        setSearch("");
      }
      return !prev
    });
    if(refInput.current && document.activeElement !== refInput.current) {
      refInput.current.focus()
    }

    updateSelectListInfo()
  };

  const handleChangeSearch = ({ target: { value }}) => {
    setSearch(value);
    if (!isOpen) {
      setIsOpen(true);
    }

    if(withPagination && withPagination.setPage) {
      withPagination.setPage(0);
    }

    updateSelectListInfo()
  };

  const handleChangeSelectValue = (value) => {
    setIsOpen(false);
    setSelectValue({ value: value.id, label: value.name });
    if (onChange) {
      onChange({ value: { value: value.id, label: value.name }, name });
    }
    setTimeout(() => updateSelectListInfo(),50)
  };

  const handleAddItem = () => {
    if (addItem) {
      addItem(search);
    }
  };
  const handleAddItemModal = () => {
    if (addItemModal) {
      addItemModal();
    }
  };

  const updateSelectListInfo = useCallback(() => {
    if (refWrapper.current && refMenu.current) {
      const rect = refWrapper.current.getBoundingClientRect();

      const windowHeight = window.innerHeight;
      const maxHeight = maxHeightSelect || 220

      let blockHeight = maxHeight;

      while (blockHeight < windowHeight) {
        blockHeight += 40;
        if(blockHeight <= maxHeight) {
          if (rect.bottom + blockHeight <= windowHeight) {
            setSelectListInfo({
              width: rect.width,
              top: rect.bottom + 5,
              height: blockHeight,
            });
            return
          } else if (rect.top - blockHeight >= 0) {
            setSelectListInfo({
              width: rect.width,
              top: rect.top - blockHeight - 5,
              height: blockHeight,
            });
            return
          }
        }
      }

      blockHeight = maxHeight;
      while (blockHeight > 0) {
        if (rect.bottom + blockHeight <= windowHeight) {
          setSelectListInfo({
            width: rect.width,
            top: rect.bottom - 5,
            height: blockHeight,
          });
          return

        } else if (rect.top - blockHeight >= 0) {
          setSelectListInfo({
            width: rect.width,
            top: rect.top - blockHeight + 5,
            height: blockHeight,
          });
          return

        }
        blockHeight -= 40;
      }

      setSelectListInfo({
        width: rect.width,
        top: rect.bottom + 5,
        height: maxHeight,
      });
    }
  }, [refWrapper, refMenu, maxHeightSelect]);

  const stateList = useMemo(() => {
    const listWithEmpty = Array.isArray(list) ? [...list] : [];

    if (empty) {
      listWithEmpty.unshift({ name: empty, id: 0 });
    }

    updateSelectListInfo()

    if(search && !onSearch) {
      return listWithEmpty.filter((item) => {
        const dataSearch = {
          id: item?.id || '',
          name: item?.name || '',
          brand: item?.brand || '',
          asin: item?.asin || '',
          asin2: item?.asin2 || '',
          skus: item?.skus || '',
          af: item.af ? Object.values(JSON.parse(item.af)).join(', ') : '',
        }
          return filterSearch(search, ["name", 'skus', 'id', 'asin', 'asin2', 'af', 'attr_dict'], dataSearch);
      });
    } else {
      return listWithEmpty;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, list, empty, updateSelectListInfo]);

  const dictList = useMemo(() => {
    const listWithEmpty = Array.isArray(list) ? [...list] : [];

    if (empty) {
      listWithEmpty.unshift({ name: empty, id: 0 });
    }
    return listWithEmpty.reduce((acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    }, {});
  }, [list, empty]);

  useEffect(() => {
    const handleResizeOrScroll = () => {
      updateSelectListInfo();
    };
    const findScrollableParent = (element) => {
      let parent = element.parentElement;

      while (parent) {
        const overflowY = window.getComputedStyle(parent).overflowY;
        if (overflowY === 'auto' || overflowY === 'scroll') {
          return parent;
        }
        parent = parent.parentElement;
      }
      return null;
    };

    let elementWithScroll = null

    if(refWrapper.current) {
      elementWithScroll = findScrollableParent(refWrapper.current)
    }

    window.addEventListener("resize", handleResizeOrScroll);
    window.addEventListener("scroll", handleResizeOrScroll);

    if(elementWithScroll) {
      elementWithScroll.addEventListener("scroll", handleResizeOrScroll)
    }

    return () => {
      window.removeEventListener("resize", handleResizeOrScroll);
      window.removeEventListener("scroll", handleResizeOrScroll);
      if(elementWithScroll) {
        elementWithScroll.removeEventListener("scroll", handleResizeOrScroll);
      }
    };
  }, [updateSelectListInfo, refWrapper]);

  useEffect(() => {
    if(refWrapper.current && refMenu.current) {
      updateSelectListInfo();
    }
    if(!isOpen) {
      setHighlightedIndex(0)
    }
  }, [refWrapper, refMenu, isOpen, updateSelectListInfo]);

  let labelComponent = label && <div className={s.label}>{T(label)}</div>;

  if (infoLabel) {
    labelComponent = (
      <div
        className="display-flex-row"
        style={{ justifyContent: "flex-start" }}
      >
        <InfoWrapper info={T(infoLabel)}>
          <div className={s.label}>{T(label)}</div>
        </InfoWrapper>
      </div>
    );
  }

  const handleTextSelection = () => {
    if (refValue.current) {
      const range = document.createRange();
      range.selectNodeContents(refValue.current);
      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleMouseDownOnValue = (e) => {
    e.preventDefault();
    const timer = setTimeout(() => {
      handleTextSelection();
    }, 500);
    setClickTimer(timer);
  };

  const handleMouseUpOnValue = () => {
    if (clickTimer) {
      clearTimeout(clickTimer);
      setClickTimer(null);
    }
  };

  useEffect(() => {
    if(!disabledAutoSelect && !value) {
      !!stateList?.length && setSelectValue({ value: stateList[0].id, label: stateList[0].name });
      onChange && !!stateList?.length && onChange({ value: { value: stateList[0].id, label: stateList[0].name }, name });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disabledAutoSelect]);

  useEffect(() => {
    if(refInput.current && document.activeElement !== refInput.current && !isMobileSize && type === 'product') {
      refInput.current.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  const handleProductSubmit = (value, ...rest) => {
    onChange(value, ...rest)
    if(!isMobileSize) {
      if(refInput.current && document.activeElement !== refInput.current) {
        refInput.current.focus()
      }
    }
    setTimeout(() => updateSelectListInfo(),50)
  }

  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [interactionMethod, setInteractionMethod] = useState("keyboard")
  const optionRefs = useRef([])
  useEffect(() => {
    const handleKeyDown = (e) => {
      let disabled = false
      if(disabledKeyStore) {
        if(Object.keys(AppStore.state.childModal || {}).length > 1) {
          disabled = true
        }
      } else {
        if(Object.keys(AppStore.state.childModal || {}).length) {
          disabled = true
        }
      }

      if(type === 'product' && !disabled) {
        if(!isOpen && e.key !== "Enter") return
        setInteractionMethod("keyboard")
        switch (e.key) {
          case "ArrowDown":
            e.preventDefault();
            setHighlightedIndex((prev) => {
              if (prev + 1 > stateList.length - 1) {
                return stateList.length - 1
              }
              return prev + 1
            });
            break;
          case "ArrowUp":
            e.preventDefault();
            setHighlightedIndex((prev) => {
              if (prev - 1 === -1) {
                return 0
              }
              return prev - 1
            });
            break;
          case "Enter":
            e.preventDefault();
            if(onEnter && isOpen) {
              onEnter(highlightedIndex, search)
              setIsOpen(false);
              setSearch('')
            }
            if(!isOpen) {
              if(refInput.current && document.activeElement === refInput.current) {
                setIsOpen(true);
              }
            }
            break;
          case "Escape":
            setSearch('')
            setIsOpen(false);
            break;
          default:
            break;
        }
      } else {
        return null
      }

    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactionMethod, isOpen, highlightedIndex, stateList, type, search]);

  useEffect(() => {
    if (optionRefs.current[highlightedIndex] && interactionMethod === 'keyboard') {
      optionRefs.current[highlightedIndex].scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [highlightedIndex, interactionMethod]);

  useEffect(() => {
    setHighlightedIndex(0)
    if(onSearch) {
      onSearch(search);
    }
  }, [search, onSearch])

  return (
      <div style={styleWrapper}>
        {label && labelComponent}
        <div
          onClick={() => handleOpen(type === 'product')}
          className={`${s.wrapper} ${isFocus ? s.active : ""}`}
          ref={refWrapper}
          style={styleSelect}
        >
          {!search && !disabledLabel &&(
            <div
              className={s.value}
              ref={refValue}
              onMouseDown={handleMouseDownOnValue}
              onMouseUp={handleMouseUpOnValue}
              title={selectValue?.value && T(selectValue.label)}
            >
              {T(dictList[selectValue?.value]?.name)}
            </div>
          )}
          <input
            placeholder={!!Object.keys(selectValue || {}).length && !disabledLabel ? '' : T(placeholder)}
            className={s.input}
            onChange={handleChangeSearch}
            value={search}
            onFocus={handleOnFocusInput}
            ref={refInput}
            onBlur={handleOnBlurInput}
          />
          <div className={`${s.action_select} ${s.open} ${isFocus ? s.active : ""}`}>
            {type === 'product' && <ScanSelectItem/>}
            <span  onClick={(evt) => {evt.stopPropagation(); handleOpen()}}>
              <LocalIcon icon="arrow-left" size="small" className={s.icon_open}/>
            </span>
          </div>
        </div>
        {isOpen && (
          <div
            ref={refMenu}
            className={s.select_list}
            style={{
              width: selectListInfo.width,
              top: selectListInfo.top,
              maxHeight: selectListInfo.height,
              paddingBottom: withPagination ? 0 : 7,
              paddingTop: additionalTopContent ? 0 : 7,
              zIndex: zIndex || 32,
            }}
          >
            {additionalTopContent && <div className={s.top_content}>
              {additionalTopContent}
            </div>}
            {stateList.map((item, index) => {
              if(type === 'product') {
                return <SelectItemProduct
                  key={item.id}
                  {...item}
                  onClick={handleProductSubmit}
                  selected={selected}
                  setIsOpenMenu={setIsOpen}
                  single={single}
                  withoutMid={withoutMid}
                  setSearch={setSearch}
                  activeIndex={highlightedIndex}
                  index={index}
                  ref={(el) => (optionRefs.current[index] = el)}
                  onMouseEnter={() => {
                    if (interactionMethod === "mouse") {
                      setHighlightedIndex(index);
                    }
                  }}
                  setInteractionMethod={setInteractionMethod}
                  interactionMethod={interactionMethod}
                />
              }

              return <SelectItem
                key={item.id}
                value={selectValue.value}
                {...item}
                onClick={handleChangeSelectValue}
              />
            })}
            {!stateList.length && !isCreate && (
              <div className="bold">{T("not-have-history")}</div>
            )}
            {isCreate && search && (
              <div className={`${s.create_input} ${!stateList.length ? s.active_element : ''}`} onClick={handleAddItem}>
                <b>{T("create")}: </b>
                {search}
              </div>
            )}
            {isCreate && !search && (
              <div className={s.create} onClick={handleAddItemModal}>
                + {T("create")}
              </div>
            )}
            {withPagination && (
              <div style={{position: 'sticky', bottom: 0}}
                  className={s.bottom_action}
              >
                <span className={s.selected_length}>
                  {selected && <b>{selected.length} {T('items-short')}</b>}
                </span>
                  <PaginationBlock
                    totalPage={withPagination.total}
                    setPage={withPagination.setPage}
                    currentPage={withPagination.page}
                    showPrevNext={false}
                    style={{margin: 0}}
                  />
                <Button rounded onClick={() => handleOpen()} size='small'>{T("close")}</Button>
            </div>)}
          </div>
        )}
      </div>
  );
});

export default Select;
